import React from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import ButtonGreen from '../../components/UI/Button/ButtonGreen';
import './ConsultationSuccess.scss';
import { AppointmentSuccess } from '../../assets/images';
import { useNavigate } from 'react-router-dom';
const ConsultationSuccess = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <div className='ConsultationSuccessWrapper'>
        <div className='ConsultationSuccessContent'>
          <AppointmentSuccess />
          <h3>Great! </h3>
          <p>
            Your request has been submitted. Please check your emails or portal
            on Therapy Match to check the provider confirms the appointment.
          </p>
          <ButtonGreen
            text={'Consultations'}
            onClick={() => navigate('/consultations')}
          />
        </div>
      </div>
      <Footer color={true} />
    </>
  );
};

export default ConsultationSuccess;
