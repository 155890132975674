import dayjs from 'dayjs';
export const disabledFuturesDates = current => {
  // Disable dates that are after today
  return current ? current.isAfter(dayjs().endOf('day')) : false;
};

export const disabledPastDates = current => {
  // Disable dates that are before today
  return current ? current.isBefore(dayjs().startOf('day')) : false;
};

export function formatDateTime(inputDateString) {
  const inputDate = new Date(inputDateString);
  const day = inputDate.getDate();
  const month = inputDate.getMonth() + 1;
  const year = inputDate.getFullYear();
  const formattedDate = `${day}/${month}/${year}`;
  const hours = inputDate.getHours();
  const minutes = inputDate.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;

  const formattedTime = `${formattedHours}:${minutes
    .toString()
    .padStart(2, '0')} ${ampm}`;
  const timeZoneAbbreviation = inputDate
    .toLocaleTimeString('en-US', { timeZoneName: 'short' })
    .split(' ')[2];

  const formattedDateTime = `${formattedDate} ${formattedTime} ${timeZoneAbbreviation}`;
  return formattedDateTime;
}
