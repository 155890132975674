import React, { useEffect, useState } from 'react';
import './HelpCenter.scss';
import { Col, Collapse, Row } from 'antd';
import { FaqsMinus, FaqsPlus } from '../../assets/vectors';
import { getRequest } from '../../api/apiCalls';
import SpinLoader from '../../components/UI/Loader';
export const HelpCenter = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [active, setActive] = useState('patient');

  useEffect(() => {
    const getAllData = async () => {
      setIsLoading(true);
      const onSuccess = res => {
        setFaqs(res?.list);
        setIsLoading(false);
      };

      const onError = res => {
        console.log(res, 'erorr res');
        setIsLoading(false);
      };
      await getRequest(
        null,
        `faqs/active_faq_listing`,
        true,
        true,
        onSuccess,
        onError
      );
    };
    getAllData();
  }, []);
  useEffect(() => {
    const filterFaqs = faqs?.filter(faq => faq.category === active);
    setFilteredData(filterFaqs);
  }, [active, faqs]);

  const items = filteredData?.map((item, index) => ({
    key: index.toString(),
    label: item.question,
    children: <p>{item.answer}</p>
  }));

  return (
    <>
      <div>
        {isLoading ? (
          <SpinLoader />
        ) : (
          <div className='helpCenterWrapper'>
            <Row gutter={16}>
              <Col lg={24} md={24} sm={24} xs={24} className='helpCenterWrap'>
                <h2>Frequently Asked Questions</h2>
                <div className='buttonContainer'>
                  <button
                    className={active === 'patient' ? 'active' : ''}
                    onClick={() => setActive('patient')}
                  >
                    Patient
                  </button>
                  <button
                    className={active === 'provider' ? 'active' : ''}
                    onClick={() => setActive('provider')}
                  >
                    Practitioner
                  </button>
                </div>
              </Col>
              <Col lg={24} md={24} sm={24} xs={24}>
                <Collapse
                  className='customFaqsCollapse'
                  defaultActiveKey={['0']}
                  ghost
                  items={items}
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <img src={FaqsMinus} alt='-' />
                    ) : (
                      <img src={FaqsPlus} alt='+' />
                    )
                  }
                />
              </Col>
            </Row>
          </div>
        )}
      </div>
    </>
  );
};
