import React from 'react';
import './banner.scss';

const Banner = () => {
  const baseURL = window.location.origin;
  return (
    <>
      <div className='banner'>
        <div className='banner__centent'>
          <div className='banner_text'>
            <p>
              Are you a provider or therapist that works with children aged 0-18
              years of age?
            </p>
          </div>
          <div className='banner_button'>
            <a href={`${baseURL}/therapist`}>
              List your practice on Therapy Match
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
