import { BackArrow } from '../../assets/images';

import { Form } from 'antd';
import AntdPassword from '../../components/UI/Password';
import Button from '../../components/UI/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { putRequest } from '../../api/apiCalls';
import { setForgetEmail } from '../../Redux/Reducers/authSlice';
import { AuthLayout } from '../../components/layout/AuthLayout';
import ButtonGreen from '../../components/UI/Button/ButtonGreen';

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const email = useSelector(state => state.authReducer.forgetEmail);
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const intialValue = {
    password: '',
    confirmPassword: ''
  };
  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onFinish = async values => {
    setIsLoading(true);
    const onSuccess = res => {
      navigate('/login');
      dispatch(setForgetEmail(''));
      setIsLoading(false);
    };
    const onError = () => {
      setIsLoading(false);
    };
    const resetPasswordReq = {
      email: email ? email : '',
      password: values.password
    };
    await putRequest(
      resetPasswordReq,
      'auth/reset_password',
      true,
      false,
      onSuccess,
      onError
    );
  };

  return (
    <AuthLayout>
      <div className='otp-screen-main'>
        <div className='otp-main-content'>
          <div className='otp-header'>
            <BackArrow
              onClick={() => {
                navigate('/login');
              }}
            />
            <h4>Reset password</h4>
          </div>

          <div className='Otp-input-container'>
            <Form
              name='basic'
              form={form}
              //   labelCol={{ span: 8 }}
              //   wrapperCol={{ span: 16 }}
              initialValues={intialValue}
              style={{ width: '100%' }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete='off'
              layout='vertical'
            >
              <Form.Item
                label='New Password'
                name={'password'}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    validator: async (_rule, value) => {
                      if (!value) {
                        return Promise.reject('Password is Required');
                      } else if (value.length < 6) {
                        return Promise.reject('Password must be 6 characters');
                      }
                    }
                  }
                ]}
              >
                <AntdPassword placeholder='Choose a password' />
              </Form.Item>
              <Form.Item
                label='Confirm Password'
                name={'confirmPassword'}
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Please Confirm your password'
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.resolve();
                      }
                      if (getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        'The new password that you entered does not match!'
                      );
                    }
                  })
                ]}
              >
                <AntdPassword placeholder='Confirm  password' />
              </Form.Item>
              <div className='btn-center-single'>
                <ButtonGreen
                  text='Reset Password'
                  type='submit'
                  disabled={isLoading}
                />
              </div>
            </Form>
          </div>
          <div className='btn-center'></div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default ResetPassword;
