import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { logout } from '../Redux/Reducers/authSlice';
import { useNavigate } from 'react-router-dom';

export const CustomLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.removeItem('token');
    dispatch(logout());
    navigate('/login');
  }, []);
  return <></>;
};
