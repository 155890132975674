import React, { useState } from 'react';
import { DP } from '../../assets/images';
import { RightOutlined } from '@ant-design/icons';
import { Arrow_Left, BrainImg, Location } from '../../assets/vectors';
import { Spin } from 'antd';
import ButtonGreen from '../../components/UI/Button/ButtonGreen';
import { getRequest } from '../../api/apiCalls';
import { useNavigate } from 'react-router-dom';

export const SingleClinicCard = ({ data }) => {
  const navigate = useNavigate();
  const [clinicTherapistsLoader, setClinicTherapistsLoader] = useState(false);
  const [showDoctors, setShowDoctors] = useState(false);

  const [clinicDoctors, setClinicDoctors] = useState([]);
  const getClinicApprovedProviders = async id => {
    setClinicTherapistsLoader(true);
    const onSuccess = res => {
      setClinicTherapistsLoader(false);
      setClinicDoctors(res?.data);
      setShowDoctors(true);
    };
    const onError = () => {
      setClinicTherapistsLoader(false);
      setShowDoctors(true);
    };
    await getRequest(
      null,
      `clinic/get-clinic-approved-therapists/${id}`,
      true,
      true,
      onSuccess,
      onError
    );
  };
  return (
    <div className='card_slot'>
      <div className='cardWrap'>
        <div className='left'>
          <div className='first__div'>
            <div className='imageContainer'>
              {data?.clinicDetails?.clinicLogo ? (
                <img src={data?.clinicDetails?.clinicLogo} alt='' />
              ) : (
                <DP />
              )}
            </div>
            <div className='name__stars'>
              <h4>{`${data?.clinicDetails?.clinicName}`}</h4>
              <button
                className='customNoBg'
                onClick={() => navigate(`/clinic-information/${data?._id}`)}
              >
                Read More <RightOutlined />
              </button>
              {/* <Rate
          style={{ fontSize: '17px' }}
          disabled
          value={data?.rating || 0}
        /> */}
            </div>
          </div>
          <div className='secound__div'>
            <img src={BrainImg} alt='' className='brainImg' />
            <div className='secound__div__content'>
              <p className='para_heading'>
                {data?.services?.practice
                  ?.map(category => category.name)
                  ?.join(', ')}
              </p>
              <p className='para_title'>
                {data?.services?.practice
                  ?.flatMap(category =>
                    category.practiceSpecialties.map(
                      subCategory => subCategory.name
                    )
                  )
                  ?.join(', ')}
              </p>
            </div>
          </div>
          <div className='third__div'>
            <img src={Location} alt='' className='location' />
            <div className='secound__div__content'>
              <p className='para_heading'>
                {data?.representativeDetails?.clinicAddress}
              </p>
            </div>
          </div>
        </div>
        <div className='right'>
          {showDoctors ? (
            <div className='doctorsViewWrapper'>
              <div className='singleDoctorCardDetailWrap'>
                {clinicDoctors?.length ? (
                  clinicDoctors?.map((doctor, index) => {
                    return (
                      <>
                        <div className='singleDoctorItem'>
                          <div className='profileWrap'>
                            {doctor?.personalInfo?.profileImg ? (
                              <img
                                src={doctor?.personalInfo?.profileImg}
                                alt='$$$'
                              />
                            ) : (
                              <DP />
                            )}
                            <div className='details'>
                              <h5>
                                {doctor?.personalInfo?.firstName +
                                  ' ' +
                                  doctor?.personalInfo?.lastName}
                              </h5>
                              <p>{doctor?.providerProfile?.practice?.name}</p>
                            </div>
                          </div>
                          <div
                            className='action'
                            onClick={() =>
                              navigate(`/doctor-information/${doctor?._id}`)
                            }
                          >
                            <img src={Arrow_Left} alt='' />
                          </div>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <p className='noProviderText'> No Provider Found</p>
                )}
              </div>
            </div>
          ) : (
            <div className='emptySchedules'>
              <Spin spinning={clinicTherapistsLoader}>
                {/* <span>
                  <img src={CalendarXmark} alt='$$$' />
                  <p> No bookable appointment available</p>
                </span> */}
                <ButtonGreen
                  text='See All Providers'
                  className='Make_an_appointment'
                  onClick={() => {
                    getClinicApprovedProviders(data?._id);
                  }}
                />
              </Spin>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
