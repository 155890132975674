import React, { useState } from 'react';
import MakeAppointment from './MakeAppointment';
import TimeSlots from './TimeSlots';
import PatientInfo from './PatientInfo';
import { Steps } from 'antd';
import Header from '../header/Header';
import { useSelector } from 'react-redux';
import { Left_Btn } from '../../assets/vectors';
import { useNavigate } from 'react-router-dom';
import AppointmentSummery from './AppointmentSummery';
import { useMediaQuery } from 'react-responsive';

const Stepper = () => {
  const navigate = useNavigate();
  const selected_slot = useSelector(state => state.appointment.selected_slot);
  const isMobile = useMediaQuery({ query: '(max-width: 580px)' });

  const [step, setStep] = useState(selected_slot ? 0 : 0);
  const titles = [
    {
      title: isMobile ? 'Type' : 'Appointment type'
    },
    {
      title: 'Schedule'
    },
    {
      title: 'Patient info'
    },
    {
      title: 'Summary'
    }
  ];

  const com = [
    <MakeAppointment activeStep={step} setActiveStep={setStep} />,
    <TimeSlots activeStep={step} setActiveStep={setStep} />,
    <PatientInfo activeStep={step} setActiveStep={setStep} />,
    <AppointmentSummery activeStep={step} setActiveStep={setStep} />
  ];

  return (
    <>
      <Header />
      <div className='head'>
        <h1> Reserve an appointment </h1>
      </div>
      <div
        className='doctorInfoHead'
        onClick={() => navigate('/search-therapist')}
      >
        <img src={Left_Btn} alt='Previous' />
        <p> Back</p>
      </div>
      <div className='parent__steps'>
        <Steps
          onChange={e => setStep(e)}
          current={step}
          labelPlacement='vertical'
          items={titles}
          className='parent__steps'
          responsive={false}
          size='large'
        />
        {com[step]}
      </div>{' '}
    </>
  );
};

export default Stepper;
