import React, { useEffect, useState } from 'react';
import './landingPage.scss';
import HeroContent from '../../components/heroSection/HeroContent';
import SearchCategory from '../../components/searchCategory/SearchCategory';
import Banner from '../../components/banner/Banner';
import Footer from '../../components/footer/Footer';
import HowItWorks from '../../components/howItWorks/HowItWorks';
import Header from '../../components/header/Header';
import { getRequest } from '../../api/apiCalls';
import {
  setIsVisited,
  setProfileData
} from '../../Redux/Reducers/profileSlice';
import { useDispatch, useSelector } from 'react-redux';
import SpinLoader from '../../components/UI/Loader';
import { HelpCenter } from '../helpCenter/HelpCenter';
import { Modal } from 'antd';
import { Logo } from '../../assets/vectors';
import ButtonGreen from '../../components/UI/Button/ButtonGreen';
import { useNavigate } from 'react-router-dom';
import { DummyReviews } from '../../components/review/DummyReviews';
import OurMissions from '../../components/ourMission/OurMission';

const LandingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(state => state.authReducer.token);
  const isVisited = useSelector(state => state.profile.isVisited);
  const [categories, setCategories] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [providerModal, setProviderModal] = useState(false);
  const [topReviews, setTopReviews] = useState([]);
  const showFooter = true;
  const getAllData = async () => {
    const onSuccess = res => {
      dispatch(setProfileData(res?.detail));
    };
    const onError = res => {
      console.log(res, 'erorr res');
      dispatch(setProfileData(null));
    };
    await getRequest(null, 'auth/me', true, true, onSuccess, onError);
  };
  const getCategoriesHandler = async () => {
    setisLoading(true);
    const onSuccess = res => {
      setCategories(res?.data);
      setisLoading(false);
    };
    const onError = () => {
      setisLoading(false);
    };
    await getRequest(
      null,
      'category/get-category',
      true,
      false,
      onSuccess,
      onError
    );
  };
  const getTopReviews = async () => {
    setisLoading(true);
    const onSuccess = res => {
      setTopReviews(res?.data);

      setisLoading(false);
    };
    const onError = res => {
      console.log(res, 'erorr res');
      setisLoading(false);
    };
    await getRequest(
      null,
      `customer/get-top-reviews`,
      true,
      true,
      onSuccess,
      onError
    );
  };
  useEffect(() => {
    setTimeout(() => {
      if (!token) {
        setProviderModal(!isVisited);
      }
    }, 4000);
    if (token) {
      getAllData();
    }
    getCategoriesHandler();
    getTopReviews();
  }, []);

  return (
    <>
      <div className='landing__page'>
        <Header />
        {isLoading ? (
          <SpinLoader />
        ) : (
          <>
            <HeroContent setisLoading={setisLoading} />

            <SearchCategory
              setisLoading={setisLoading}
              categories={categories}
            />
            <HowItWorks />
            <Banner />
            {/* <OurMissions /> */}
            <HelpCenter />
            {/* <Review /> */}
            <DummyReviews />
            {/* <ReviewTwo setisLoading={setisLoading} topReviews={topReviews} /> */}
          </>
        )}

        {showFooter && <Footer color={true} />}
        <Modal
          centered
          className='customProviderModal'
          open={providerModal}
          onCancel={() => {
            setProviderModal(false);
            dispatch(setIsVisited(true));
          }}
        >
          <img src={Logo} alt='$$$' />
          <h3>
            Looking for a therapist with immediate availability for your child?
          </h3>
          <h4>Take your pick!</h4>
          <ButtonGreen
            className={'customProviderModalButton'}
            onClick={() => {
              dispatch(setIsVisited(true));
              navigate('/signup');
            }}
            text={'Sign up'}
          />
        </Modal>
      </div>
    </>
  );
};

export default LandingPage;
