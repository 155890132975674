import React, { useEffect, useState } from 'react';
import './makeAppointment.scss';
import {
  HospitalStepper,
  HouseStepper,
  MovieStepper
} from '../../assets/vectors';
import Location from '../../assets/images';
import { Form, Radio } from 'antd';
import Footer from '../footer/Footer';
import { updateAppointmentField } from '../../Redux/Reducers/apponitmentSlice';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../UI/Input';
import { useParams } from 'react-router-dom';
import { getRequest } from '../../api/apiCalls';
import SpinLoader from '../UI/Loader';
import AntdInputAutocomplete from '../../helpers/LocationInput';
import ButtonGreen from '../UI/Button/ButtonGreen';

const MakeAppointment = ({ activeStep, setActiveStep }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { id } = useParams();
  const appointment = useSelector(state => state.appointment);
  const currentURL = window.location.href;
  const urlParams = new URLSearchParams(new URL(currentURL).search);
  const type = urlParams.get('type');
  const customSlotParam = urlParams.get('custom');

  const [value, setValue] = useState(
    type || appointment?.appointment_type || 1
  );
  const [isLoading, setisLoading] = useState(false);
  const [data, setData] = useState([]);
  const onChange = e => {
    dispatch(
      updateAppointmentField({
        field: 'selected_slot',
        value: null
      })
    );
    setValue(e.target.value);
  };
  const onFinish = values => {
    setValue(values?.appointment_type);
    let conf = dispatch(
      updateAppointmentField({
        field: 'appointment_type',
        value: values?.appointment_type
      })
    );
    if (values?.appointment_type === 'home') {
      dispatch(
        updateAppointmentField({
          field: 'user_address',
          value: values?.user_address
        })
      );
    }
    if (conf) setActiveStep(activeStep + 1);
  };
  const getFilteredDataHandler = async () => {
    setisLoading(true);
    const onSuccess = res => {
      setData(res?.data);
      setisLoading(false);
    };
    const onError = () => {
      setisLoading(false);
    };
    await getRequest(
      null,
      `therapist/single-therapist/${id}`,
      true,
      true,
      onSuccess,
      onError
    );
  };
  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    });
    getFilteredDataHandler();
  }, []);
  useEffect(() => {
    if (
      type === 'clinic' ||
      type === 'video' ||
      type === 'home' ||
      appointment
    ) {
      form.setFieldValue(
        'appointment_type',
        type || appointment?.appointment_type
      );
      if (appointment?.appointment_type === 'home')
        form.setFieldValue('user_address', type || appointment?.user_address);
    }
  }, [type]);
  return (
    <>
      {isLoading ? (
        <SpinLoader height='50vh' />
      ) : (
        <div className='make__appointment'>
          <Form
            name='basic'
            layout='vertical'
            style={{ width: '100%' }}
            form={form}
            onFinish={onFinish}
            autoComplete='off'
          >
            <Form.Item
              name={'appointment_type'}
              rules={[
                {
                  required: true,
                  message: 'Please select appointment type'
                }
              ]}
            >
              <Radio.Group
                className='three__fields'
                value={value}
                onChange={onChange}
                disabled={value && !customSlotParam ? true : false}
              >
                <div className='box_one'>
                  <Radio value={'video'} className='customAppointmentRadio'>
                    <div className='box_one_left'>
                      <img src={MovieStepper} alt='' />
                      <p> Video consultation </p>
                    </div>
                  </Radio>
                </div>
                <div className='box_one'>
                  <Radio value={'home'} className='customAppointmentRadio'>
                    <div className='box_one_left'>
                      <img src={HouseStepper} alt='' />
                      <p>
                        Face-to-Face <span> (At home) </span>
                      </p>
                    </div>
                  </Radio>
                </div>{' '}
                <div className='box_one'>
                  <Radio value={'clinic'} className='customAppointmentRadio'>
                    <div className='box_one_left'>
                      <img src={HospitalStepper} alt='' />
                      <p>
                        Face-to-Face <span> (At clinic) </span>
                      </p>
                    </div>
                  </Radio>
                </div>
              </Radio.Group>
            </Form.Item>
            {value === 'home' && (
              <>
                <div className='input_groups customAppointmentAddressInput'>
                  <AntdInputAutocomplete
                    name={'user_address'}
                    placeholder='Home address to visit'
                    form={form}
                    required={true}
                    customClass='appointmentAddressField'
                  />
                </div>
              </>
            )}
            <div
              style={{
                textAlign: 'center',
                marginTop: '1rem',
                marginBottom: '4rem'
              }}
            >
              {value === 'clinic' && (
                <>
                  <div className='clinic'>
                    <p className='clinic_head'>Clinic address to visit</p>
                    <p className='clinic_Address'>
                      {data?.personalInfo?.bussinessAddress}
                    </p>
                  </div>
                </>
              )}
              <ButtonGreen text='Next' type='submit' disabled={isLoading} />

              {/* <button className='stepper_btn' type='submit'>
                Next
              </button> */}
            </div>
          </Form>
        </div>
      )}

      <Footer />
    </>
  );
};

export default MakeAppointment;
