import AntdInput from '../../components/UI/Input/';
import Button from '../../components/UI/Button/Button';
import { Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import { BackArrow } from '../../assets/images';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { setForgetEmail } from '../../Redux/Reducers/authSlice';
import { postRequest } from '../../api/apiCalls';
import { AuthLayout } from '../../components/layout/AuthLayout';
import ButtonGreen from '../../components/UI/Button/ButtonGreen';

const ForgetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };
  const onFinish = async values => {
    setIsLoading(true);
    const onSuccess = res => {
      dispatch(setForgetEmail(values.email));
      navigate('/forget-password-otp');
      setIsLoading(false);
    };
    const onError = () => {
      setIsLoading(false);
    };
    const dataToSend = {
      email: values?.email,
      role: 'customer'
    };
    await postRequest(
      dataToSend,
      'auth/forget-password',
      true,
      false,
      onSuccess,
      onError
    );
  };
  return (
    <AuthLayout>
      <div className='otp-screen-main'>
        <div className='otp-main-content'>
          <div className='otp-header'>
            <BackArrow
              onClick={() => {
                navigate(-1);
              }}
            />
            <h4>Forgot Password?</h4>
          </div>
          <div className='Otp-input-container'>
            <Form
              name='basic'
              style={{ width: '100%' }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete='off'
              layout='vertical'
            >
              <Form.Item
                label='Email Address'
                name={'email'}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Email Address is Required'
                  },
                  {
                    pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                    message: 'Please enter a valid email!'
                  }
                ]}
              >
                <AntdInput placeholder='Email Address' />
              </Form.Item>
              <div className='btn-center-single'>
                <ButtonGreen text='Next' type='submit' disabled={isLoading} />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default ForgetPassword;
