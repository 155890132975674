import dayjs from 'dayjs';

export const sortTherapists = (therapists, currentLocation) => {
  const now = dayjs();

  return [...therapists]?.sort((a, b) => {
    // Check for upcoming schedules
    const aHasUpcomingSchedule =
      a?.schedules &&
      Object.values(a?.schedules).some(schedule =>
        dayjs(schedule?.start).isAfter(now)
      );
    const bHasUpcomingSchedule =
      b?.schedules &&
      Object.values(b?.schedules).some(schedule =>
        dayjs(schedule?.start).isAfter(now)
      );

    if (aHasUpcomingSchedule && !bHasUpcomingSchedule) return -1;
    if (!aHasUpcomingSchedule && bHasUpcomingSchedule) return 1;

    // If both have the same availability status, sort by distance
    if (aHasUpcomingSchedule && bHasUpcomingSchedule) {
      const aDistance = a.distance || Infinity; // Default to Infinity if distance is not available
      const bDistance = b.distance || Infinity; // Default to Infinity if distance is not available

      return aDistance - bDistance; // Sort by distance (ascending)
    }

    return 0;
  });
};
