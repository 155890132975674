import { Carousel, Modal } from 'antd';
import moment from 'moment/moment';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { updateAppointmentField } from '../../Redux/Reducers/apponitmentSlice';
import {
  ClinicCalender,
  DP,
  HomeCalender,
  VideoCalender
} from '../../assets/images';
import './DoctorsCalender.scss';
import ButtonGreen from '../UI/Button/ButtonGreen';
import dayjs from 'dayjs';

export const DoctorsCalender = ({ data, id, doctorData, showItems }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const activeDate = searchParams.get('activeDate');
  const carouselRef = useRef();
  const token = useSelector(state => state.authReducer.token);
  const [appointmentModal, setAppointmentModal] = useState(false);
  const [activeSelectedSlot, setActiveSelectedSlot] = useState(null);
  let today;
  if (activeDate) {
    today = new Date(activeDate);
  } else {
    today = new Date();
  }
  const days = [];
  for (let i = 0; i < 42; i++) {
    const date = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + i
    );
    const formatedDate = moment(date).format('YYYY-MM-DD');
    days.push(formatedDate);
  }
  const frequencyArray = {
    ONE_OFF: 'One-off',
    WEEKLY: 'Weekly',
    FORTNIGHTLY: 'Fortnightly',
    MONTHLY: 'Monthly'
  };

  const handleCardSlotClick = slot => {
    dispatch(
      updateAppointmentField({
        field: 'selected_slot',
        value: slot
      })
    );
    dispatch(
      updateAppointmentField({
        field: 'appointment_type',
        value: slot?.type
      })
    );
    dispatch(
      updateAppointmentField({
        field: 'selected_therapist',
        value: id
      })
    );
    if (token) {
      navigate(`/make-appointment/${id}`);
    } else {
      navigate('/login');
    }
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNextClick = () => {
    const newIndex = currentIndex + 7;
    carouselRef.current.goTo(newIndex, true);
  };
  const handlePrevClick = () => {
    const newIndex = currentIndex - 7;
    carouselRef.current.goTo(newIndex, true);
  };

  const handleAfterChange = index => {
    setCurrentIndex(index);
  };
  return (
    <>
      <div className='doctorCalenderWrapper'>
        <LeftOutlined
          className='customCaroselIcons'
          onClick={handlePrevClick}
        />
        <Carousel
          slidesToShow={7}
          dots={false}
          ref={carouselRef}
          className='carousel_calender'
          infinite={false}
          afterChange={handleAfterChange}
        >
          {days?.map((sche, index) => {
            const foundElement = data?.find(item => item.date === sche);
            const foundSlots = foundElement?.schedules?.filter(
              elem =>
                elem?.status === 'available' &&
                !moment(elem.start).isBefore(moment())
            );
            const date = foundElement
              ? new Date(foundElement?.date)
              : new Date(sche);
            return (
              <>
                <div className='right__one'>
                  <div className='dates__day'>
                    <p className='day'>
                      {date?.toLocaleDateString('en-US', {
                        weekday: 'short'
                      })}
                    </p>
                    <p>
                      {date?.toLocaleDateString('en-US', {
                        month: 'short'
                      })}{' '}
                      {date?.getDate()}
                    </p>
                  </div>
                  <div className='time__slots' style={{ gap: '10px' }}>
                    {[...Array(showItems)].map((_, ind) => {
                      const item = foundSlots?.[ind];
                      if (item && !item.bookingId) {
                        return (
                          <div className='timeSlotViewWrapper'>
                            <p
                              onClick={() => {
                                setActiveSelectedSlot(item);
                                setAppointmentModal(true);
                              }}
                              className={
                                item?.type === 'clinic'
                                  ? 'timeView timeViewClinic'
                                  : item?.type === 'home'
                                  ? 'timeView timeViewHome'
                                  : 'timeView timeViewVideo'
                              }
                              key={ind}
                            >
                              {item?.type === 'clinic' ? (
                                <ClinicCalender className='calenderIcon' />
                              ) : item?.type === 'home' ? (
                                <HomeCalender className='calenderIcon' />
                              ) : (
                                <VideoCalender className='calenderIcon' />
                              )}

                              <p style={{ fontSize: '10px' }}>
                                {moment(item?.start).format('h:mm A')}
                              </p>
                            </p>
                          </div>
                        );
                      } else {
                        return (
                          <div className='timeSlotViewWrapper nobg'>
                            <p key={ind}>-</p>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </>
            );
          })}
        </Carousel>
        <RightOutlined
          className='customCaroselIcons'
          onClick={handleNextClick}
        />
      </div>
      <Modal
        centered
        open={appointmentModal}
        onCancel={() => {
          setAppointmentModal(false);
          setActiveSelectedSlot(null);
        }}
        closeIcon={true}
      >
        <div className='singleSlotModal'>
          <h1> Available slot </h1>
          <div className='detailWrap'>
            <div className='doctorDetailsWrap'>
              <div className='doctorDetail'>
                {doctorData?.personalInfo?.profileImg ? (
                  <img src={doctorData?.personalInfo?.profileImg} alt='' />
                ) : (
                  <DP />
                )}
                <div className='detailWrapInner'>
                  <p className='name'>
                    {' '}
                    {doctorData?.personalInfo?.firstName +
                      ' ' +
                      doctorData?.personalInfo?.lastName}{' '}
                  </p>
                  <p className='appointmentType'>
                    {activeSelectedSlot?.type === 'home'
                      ? 'Mobile'
                      : activeSelectedSlot?.type}{' '}
                    appointment
                  </p>
                </div>
              </div>
              <div className='feeDetail'>
                ${activeSelectedSlot?.fee || ' --'} <span>fee</span>
              </div>
            </div>
          </div>
          <div className='slotDetailWrapper'>
            <div className='detailWrap'>
              <div className='slotDetail'>
                <h5>
                  {dayjs(activeSelectedSlot?.start)?.format('MMM DD, YYYY')}
                </h5>
                <p>Available on</p>
              </div>
            </div>
            <div className='detailWrap'>
              <div className='slotDetail'>
                <h5> {dayjs(activeSelectedSlot?.start)?.format('h:mm A')}</h5>
                <h5 style={{ lineHeight: '9px' }}>-</h5>
                <h5> {dayjs(activeSelectedSlot?.end)?.format('h:mm A')}</h5>
                <p>
                  {(() => {
                    const start = dayjs(activeSelectedSlot?.start);
                    const end = dayjs(activeSelectedSlot?.end);
                    const diffMinutes = end.diff(start, 'minutes');
                    const hours = Math.floor(diffMinutes / 60);
                    const minutes = diffMinutes % 60;

                    if (hours > 0) {
                      return `${hours} hour${hours > 1 ? 's' : ''}${
                        minutes && minutes > 0
                          ? ` and ${minutes} minute${minutes > 1 ? 's' : ''}`
                          : ''
                      } slot time`;
                    } else {
                      return `${minutes} minute${
                        minutes > 1 ? 's' : ''
                      } slot time`;
                    }
                  })()}
                </p>
              </div>
            </div>
          </div>
          <div className='detailWrap'>
            <div className='frequencyWrap'>
              <p className='frequecyHead'>Frequency</p>
              <p className='frequecyType'>
                {frequencyArray[activeSelectedSlot?.scheduleFrequency]}
              </p>
            </div>
          </div>
          <div className='detailWrap'>
            <div className='appointmentNoteWrap'>
              <p className='appointmentNoteHead'>Appointment Note</p>
              <p className='appointmentNoteType'>
                {activeSelectedSlot?.scheduleNote}
              </p>
            </div>
          </div>

          <div className='makeAppointmentWrap'>
            <ButtonGreen
              text={'Reserve an appointment'}
              onClick={() => {
                handleCardSlotClick(activeSelectedSlot);
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
