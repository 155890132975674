import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {},
  token: localStorage.getItem('token') || '',
  FirstTime: true,
  forgetEmail: '',
  isLoading: false,
  email: '',
  isProfileFilled: false
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setForgetEmail: (state, action) => {
      state.forgetEmail = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setIsProfileFilled: (state, action) => {
      state.isProfileFilled = action.payload;
    },

    logout: state => {
      state.token = '';
    },
    updateAuthField: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    }
  }
});

export const {
  setUser,
  logout,
  setToken,
  setForgetEmail,
  setIsLoading,
  setEmail,
  updateAuthField,
  setIsProfileFilled
} = userSlice.actions;
export const authReducer = userSlice.reducer;
