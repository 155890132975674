import React from 'react';
import './howItWorks.scss';
function HowItWorks() {
  return (
    <>
      <div className='how-it-works'>
        <h2>How it works</h2>

        <div className='video-container'>
          <video
            src={
              'https://therapymatchoriginal.s3.ap-southeast-2.amazonaws.com/Videos/TherapyMatchIntro+-+Made+with+Clipchamp.mp4'
            }
            className='video'
            poster='' //thumbnail here
            muted
            loop
            playsInline
            controls
          />
          {/* <PauseCircleOutlined className='icon-overlay' /> */}
        </div>
      </div>
    </>
  );
}

export default HowItWorks;
