export const availabilityOptionsData = [
  { label: 'Today', value: 'today' },
  { label: 'Tomorrow', value: 'tomorrow' },
  { label: 'Next 7 Days', value: '7days' }
];

export const frequencyOptionsData = [
  { label: 'One-off Availability', value: 'ONE_OFF' },
  { label: 'Weekly Availability', value: 'WEEKLY' },
  {
    label: 'Fortnightly Availability',
    value: 'FORTNIGHTLY'
  },
  {
    label: 'Monthly Availability',
    value: 'MONTHLY'
  }
];
export const ageGroupOptionsData = [
  { label: 'Infants', value: '0' },
  { label: '0 to 2 years', value: '0 to 2 years' },
  { label: '2 to 7 years', value: '2 to 7 years' },
  { label: '7 to 12 years', value: '7 to 12 years' },
  { label: '12 to 18 years', value: '12 to 18 years' }
];
export const serviceDeliveryData = [
  {
    label: 'Face-to-face clinic',
    value: 'Face-to-face clinic'
  },
  {
    label: 'Face-to-face home visits',
    value: 'Face-to-face home visits'
  },
  {
    label: 'Telehealth',
    value: 'Telehealth'
  }
];
export const radiusOptionsData = [
  { label: '10 km', value: '10000' },
  { label: '30 km', value: '30000' },
  { label: '50 km', value: '50000' }
];
