import {
  ConfigProvider,
  Form,
  Modal,
  Select,
  Spin,
  TimePicker,
  message
} from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { postRequest } from '../../api/apiCalls';
import { useNavigate } from 'react-router-dom';
import { CloseOutlined, HomeOutlined } from '@ant-design/icons';
import { Hospital, VideoCultantIcon } from '../../assets/images';
import ButtonGreen from '../../components/UI/Button/ButtonGreen';
export const SearchWaitlistForm = ({
  setOpenModal,
  openModal,
  waitlistActiveThearapist,
  activeType = ''
}) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setisLoading] = useState(false);
  const [formItems, setFormItems] = useState([]);
  const deleteItemByIndex = index => {
    const updatedArray = formItems.filter(item => item?.id !== index);
    setFormItems(updatedArray);
  };
  const onFinishWaitlist = async values => {
    if (
      dayjs(values?.startTime).format('HH:mm') ===
      dayjs(values?.endTime).format('HH:mm')
    ) {
      message.error('Start and End time can not be same.');
      return;
    }

    const dataToSend = {
      therapistId: waitlistActiveThearapist,
      dayOfWeek: values?.day,
      start: dayjs.utc(values?.startTime).format('HH:mm'),
      end: dayjs.utc(values?.endTime).format('HH:mm'),
      type: activeType ? activeType : values?.type,
      startString: dayjs(values?.startTime).format('hh:mm a').toString(),
      endString: dayjs(values?.endTime).format('hh:mm a').toString()
    };
    const isFound = formItems?.find(obj => {
      return (
        obj.type === dataToSend.type &&
        obj.start <= dataToSend.start &&
        obj.end >= dataToSend.end &&
        obj.dayOfWeek === dataToSend.dayOfWeek
      );
    });
    if (isFound) {
      message.info('This waitlist slot is already added.');
      return;
    }
    setFormItems(prevArray => {
      const lastItem = prevArray[prevArray.length - 1];
      const newId = lastItem?.id ? lastItem?.id + 1 : 1;
      dataToSend.id = newId;
      return [...prevArray, dataToSend];
    });
    const fieldsToClear = ['day', 'startTime', 'endTime'];
    const emptyValues = {};
    fieldsToClear.forEach(field => {
      emptyValues[field] = null;
    });
    form.setFieldsValue(emptyValues);
  };
  const onFinish = async () => {
    if (!formItems?.length) {
      message.error('Please add Waitlist');
      return;
    } else {
      setisLoading(true);
      const onSuccess = res => {
        setisLoading(false);
        navigate('/');
      };
      const onError = () => {
        setisLoading(false);
      };
      await postRequest(
        formItems,
        `customer/create-waitlist`,
        true,
        true,
        onSuccess,
        onError
      );
    }
  };

  return (
    <Modal
      centered
      open={openModal}
      onCancel={() => {
        form.resetFields();
        setFormItems([]);
        setOpenModal(false);
      }}
      width={1000}
      closeIcon={true}
    >
      <div className='modal'>
        <h1> Provide a suitable time </h1>
        <p>
          Enter your preferred time slots. If your chosen provider updates their
          calendar availability, you will be automatically alerted via email to
          book.
        </p>
        <div style={{ margin: '20px 0' }}>
          {' '}
          {formItems?.length ? (
            <h3 style={{ width: '90%', margin: '0 auto', textAlign: 'left' }}>
              Waitlist
            </h3>
          ) : null}
          <div className='formItemsWrap'>
            {formItems &&
              formItems.map(formItem => (
                <p className='waitlistElements'>
                  {formItem?.type === 'clinic' ? (
                    <Hospital
                      style={{ marginBottom: '-1px', marginRight: '5px' }}
                    />
                  ) : formItem?.type === 'home' ? (
                    <HomeOutlined
                      style={{
                        color: 'white',
                        marginRight: '5px',
                        fontSize: '13px',
                        marginLeft: '0'
                      }}
                    />
                  ) : (
                    <VideoCultantIcon
                      style={{
                        height: 'auto',
                        width: '17px',
                        marginBottom: '-3px',
                        marginRight: '5px'
                      }}
                      className='whiteSvgIcon'
                    />
                  )}

                  {dayjs
                    .utc(formItem?.start, 'HH:mm')
                    .local()
                    .format('hh:mm A') +
                    ' - ' +
                    dayjs
                      .utc(formItem?.end, 'HH:mm')
                      .local()
                      .format('hh:mm A') +
                    ' on ' +
                    formItem?.dayOfWeek}

                  <span onClick={() => deleteItemByIndex(formItem?.id)}>
                    <CloseOutlined />
                  </span>
                </p>
              ))}
          </div>
        </div>

        <Form form={form} onFinish={onFinishWaitlist}>
          <div className='input_fields'>
            {!activeType ? (
              <div className='dates'>
                <h3> Type </h3>
                <Form.Item
                  name={'type'}
                  rules={[
                    {
                      required: true,
                      message: 'Please select type'
                    }
                  ]}
                >
                  <Select
                    placeholder='Select Type'
                    options={[
                      {
                        label: 'Clinic',
                        value: 'clinic'
                      },
                      {
                        label: 'Home',
                        value: 'home'
                      },
                      {
                        label: 'Video',
                        value: 'video'
                      }
                    ]}
                  />
                </Form.Item>
              </div>
            ) : null}
            <div className='dates'>
              <h3> Day </h3>
              <Form.Item
                name={'day'}
                rules={[
                  {
                    required: true,
                    message: 'Please select day'
                  }
                ]}
              >
                <Select
                  placeholder='Select Day'
                  options={[
                    {
                      label: 'Monday',
                      value: 'Monday'
                    },
                    {
                      label: 'Tuesday',
                      value: 'Tuesday'
                    },
                    {
                      label: 'Wednesday',
                      value: 'Wednesday'
                    },
                    {
                      label: 'Thursday',
                      value: 'Thursday'
                    },
                    {
                      label: 'Friday',
                      value: 'Friday'
                    },
                    {
                      label: 'Saturday',
                      value: 'Saturday'
                    },
                    {
                      label: 'Sunday',
                      value: 'Sunday'
                    }
                  ]}
                />
              </Form.Item>
            </div>
            {/* <div className='dates'>
              <h3> Date </h3>
              <Form.Item
                name={'startDate'}
                rules={[
                  {
                    required: true,
                    message: 'Please select date'
                  }
                ]}
              >
                <DatePicker
                  disabledDate={disabledPastDates}
                  format='DD/MM/YYYY - dddd'
                  changeOnBlur={true}
                />
              </Form.Item>
            </div> */}
            <div className='timeSlotsWaitlist'>
              <div className='time'>
                <h3> Start Time </h3>
                <Form.Item
                  name={'startTime'}
                  dependencies={['endTime']}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter start time'
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const startTime = getFieldValue('startTime');
                        const endTime = getFieldValue('endTime');
                        if (!endTime) {
                          return Promise.resolve();
                        } else if (
                          startTime &&
                          endTime &&
                          startTime.isBefore(endTime) &&
                          !startTime.isSame(endTime)
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          'Start time must be less than end time'
                        );
                      }
                    })
                  ]}
                >
                  <TimePicker
                    changeOnBlur={true}
                    minuteStep={30}
                    format='hh:mm a'
                  />
                </Form.Item>
              </div>
              <div className='time'>
                <h3> End Time </h3>
                <Form.Item
                  name={'endTime'}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter end time'
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const startTime = getFieldValue('startTime');
                        const endTime = getFieldValue('endTime');
                        if (
                          startTime &&
                          endTime &&
                          endTime.isAfter(startTime) &&
                          !startTime.isSame(endTime)
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          'End time must be greater than start time'
                        );
                      }
                    })
                  ]}
                >
                  <TimePicker
                    changeOnBlur={true}
                    minuteStep={30}
                    format='hh:mm a'
                  />
                </Form.Item>
              </div>
            </div>
            <div className='customAddWaitlistButton'>
              <button>Save</button>
            </div>
          </div>

          <div>
            <ButtonGreen
              onClick={e => {
                e.preventDefault();

                onFinish();
              }}
              disabled={isLoading}
              style={{ pointerEvents: isLoading ? 'none' : 'inherit' }}
              text={
                isLoading ? (
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: '#809848'
                      }
                    }}
                  >
                    <Spin />
                  </ConfigProvider>
                ) : (
                  'Submit'
                )
              }
            ></ButtonGreen>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
