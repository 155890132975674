export const termsConditionsArray = [
  {
    heading:"Who we are and how to contact us",
    content:[
        {
            text:"www.therapymatch.com.au is a website (“Website”) operated by Therapy Match trading as Therapy Match ABN 81 504 557 256 (we, us and our)."
        },
        {
            text:"To contact us, please email hello@therapymatch.com.au"
        },
        {
            text:"The Website is a booking platform for managing medical, allied health and healthcare appointments between Patients and practitioners (“Appointments”).  The Website makes it easier for Patients Users and medical practitioners to find, communicate, and arrange bookings of medical and/or health Appointments in a fast and secure manner ('Services')."
        },
        {
            text:"A reference to a Patient User includes the Patient and/or an Authorised Person that has authority to act on behalf of the Patient, as applicable on the Website."
        },
        {
            text:"We do not provide any medical or allied health services and we do not have any involvement in the Appointment once the Booking has been made.  "
        },
        {
            text:"We (or our licensors) retain ownership of all intellectual property rights in and to the Website including messages and content we make available to you. Nothing grants you any ownership over the Website or any content made available to you. "
        },
    ]
  },
  {
    heading:"By using our Website you accept these Terms",
    content:[
        {
            text:"By using our Website and Services, you confirm that you accept these Terms of use and that you agree to comply with them. If you do not agree to these Terms, you must not use our Website. You agree you are at least 15 years old to make any Bookings on our Website. "
        },
  
    ]
  },
  {
    heading:"We may make changes to these Terms",
    content:[
        {
            text:"	We amend these Terms from time to time. Every time you wish to use our Website, please check these Terms to ensure you understand the Terms that apply at that time.",
          
        },
        {
            text:"These Terms were most recently updated on 1 September 2023."
        },

        {
            text:"We may update and change our Website from time to time to reflect changes to our Website, our users' needs, changes in law and our business priorities."
        },
        {
            text:`	This Website is provided on an 'as is' and 'as available' basis, and we make no representations or warranties, express or implied, regarding the operation or availability of the Website or Services.`
        },
    ]
  },
  {
    heading:"Registration process",
    content:[
        {
            text:"In order to access the Services and request a Booking, you are required to register for an account through the Website ('Account'). Once you have registered for an Account you will be authorised to request Bookings through the Website. "
        },
        {
            text:`As part of the registration process, or as part of your continued use of the Services, you may be required to provide personal information about yourself (such as identification or contact details), including, email address, mailing address, telephone number and password.`
            
        },
        {
            text:`You warrant that any information you give us in the course of completing the registration process will always be accurate, correct and up to date.`
        },
        {
            text:`Once you have completed the registration process, you will be registered as either a Patient or Practitioner of the Website) and agree to be bound by the Terms.`
        }
    ]
  },
  {
    heading:"Your use of the Website ",
    content:[
        {
            text:`You agree to comply with the following:`,
            innerContent:[
                {
                    text:'you will not share your profile with any other person;'
                },
                {
                    text:'you will use the Services only for purposes that are permitted by:',
               
                subInnerContent:[
{
    text:"the Terms; and"
},
{
    text:"any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdictions;   "
},
              ],
            },
              {
                text:"you have sole responsibility for protecting the confidentiality of your password and/or email address. Use of your password by any other person may result in the immediate cancellation of the Services;"
              },
              {
                text:"any use of your information by any other person, or third parties, is strictly prohibited. You agree to immediately notify us of any unauthorised use of your password or email address or any breach of security of which you have become aware;"
              },
              {
                text:`not to record or publish on this Website the medical or health advice provided to you or by a Practitioner arising from any Booking;
                `
              },
              {
                text:`you will not record or store any sensitive personal information as defined by the Privacy Act 1988 on the Website; `
              },
              {
                text:`you will not impersonate another Patient User or Practitioner or use the profile or password of another Patient User or Practitioner (save for administration staff) at any time;`
              },
              {
                text:`you will not make or confirm a Booking, and fail to take all reasonable steps to either attend that Booking or provide reasonable notice of any cancellation;`
              },
              {
                text:`you agree not to harass, impersonate, stalk, threaten another Patient User or Practitioner of the Website (where interaction with other Patient Users or Practitioners is made available to you);`
              },
              {
                tetx:`you will not use the Services or the Website in connection with any commercial endeavours except those that are specifically endorsed or approved by us;
                `
              },
              {
                text:`you will not use the Services or Website for any illegal and/or unauthorised use;
                `
              },
              {
                text:`you agree that commercial advertisements, affiliate links and other forms of solicitation may be removed from Practitioner profiles without notice and may result in termination of the Services; and`
              },
              {
                text:`you acknowledge and agree that any automated use of the Website or its Services is prohibited.`
              }
            ]
        }
    ]
  },
  {
    heading:`Our Obligations `,
    content : [
     {  
         text:"In providing the Services, we will: ",
         innerContent:[
            {
                text:"manage reviews posted for Practitioners and Patient Users;   "
            },
            {
                text:`facilitate the communication between the Practitioners and Patient Users; and
                `
            },
            {
                text:`not hold any credit card details.
                `
            },
         ]
        }
    ]
  },
  {
    heading:"Cancellation of a Booking ",
    content:[
        {
            text:`The Patient User may cancel a Booking made on the Website, subject to the following terms and conditions:
            `,
            innerContent:[
                {
                    text:`Cancellation must be made prior to the scheduled Appointment in accordance with the Practitioner’s cancellation policy;`
                },
                {
                    text:`The Patient User must cancel the Appointment directly with the Practitioner by using the contact details provided when making the Booking;`
                },
                {
                    text:`If the cancellation is made within the Practitioner’s specified notice period, no cancellation fee will be charged; and `
                },
                {
                    text:`If the cancellation is made outside the specified notice period or if the Patient User fails to attend the Appointment without prior notice, the Practitioner may charge a cancellation fee. `
                }
            ],

        },
        {
            text:`The Practitioner reserves the right to cancel or reschedule an Appointment due to unforeseen circumstances. In such cases, the Patient User will be notified as soon as possible, and alternative arrangements will be made.`
        }
        

    ]
  },
  {
heading:`We may suspend or withdraw our Website`,
content:[
    {
        text:`The Website is made available free of charge.`
    },
    {
        text:`The Website is made available free of charge.`
    },
    {
        text:`We do not guarantee that the Services, our Website, or any content on it, will always be available, uninterrupted or be error-free. We may suspend or withdraw or restrict the availability of all or any part of our Website for business and operational reasons. We will try to give you reasonable notice of any suspension or withdrawal. `
    }
]
  },
  {
    heading:`Intellectual Property`,
    content:[
        {
            text:`Either we or our licensors own all of the information and intellectual property in and on the Website and you have no right to copy or use any of that information or intellectual property other than to use the Website unless we expressly give that right to you.`
        }
    ]
  },
  {
    heading:`Privacy & Security `,
    content:[
        {
            text:`We take your privacy seriously and any information provided through your use of the Website and/or Services are subject to our Privacy Policy, which is available on our Website.  `
        },{
            text:`We will take all reasonable measures to ensure that data transmission adheres to recognized industry standards. However, please acknowledge that the internet is not entirely secure, and we cannot assume responsibility for any misuse, loss, unauthorized access, or disclosure of information beyond our control. By providing us with information via the internet, you accept and understand this inherent risk.`
        }
    ]
  },
  {
    heading:`General Disclaimer`,
    content:[
        {
            text:`Nothing in the Terms limits or excludes any guarantees, warranties, representations or conditions implied or imposed by law, including the Australian Consumer Law (or any liability under them) which by law may not be limited or excluded.`
        },
        {
            text:`Subject to this clause, and to the extent permitted by law all terms, guarantees, warranties, representations or conditions which are not expressly stated in the Terms are excluded.`
        },
        {
            text:`Use of the Website and the Services is at your own risk. Everything on the Website, including the Bookings, and the Services are provided to you "as is" and "as available" without warranty or condition of any kind. None of our affiliates, directors, officers, employees, agents, contributors and licensors make any express or implied representation or warranty about the Services, Bookings or the Website, including the availability or operability of the Website. `
        },
        {
            text:`You acknowledge that the Website and the Services are only intended to facilitate the Booking and does not offer any services to you other than the Services and we hold no liability to you as a result of any conduct of the Patient Users or Practitioners. The Website does not provide medical advice, nor do we hold the Website out to provide medical advice.`
        },
        {
            text:`All information on the Website is not comprehensive and is for general informational purposes only. Any information on the Website does not take into account your specific needs, objectives or circumstances, and it is not advice.  While we use reasonable attempts to ensure the accuracy and completeness of the information, we make no representation or warranty in relation to it, to the maximum extent permitted by law. `
        },
        {
            text:`
            We make no representation or warranty that an Appointment time is actually available or that a Practitioner will confirm a Booking request. It is the Practitioner’s responsibility to contact the Patient if the Practitioner experiences any issues with a Booking request.`
        },
        {
            text:`The Website is not designed for, and must not be relied upon in relation to, any critical, emergency or acute care medical circumstances. Please always call 000 in an emergency. `
        }


    ]
  },
  {
    heading:`Limitation of Liability`,
    content:[
        {
            text:`Our total liability arising out of or in connection with the Services or these Terms, however arising, including under contract, tort (including negligence), in equity, under statute or otherwise, will not exceed $100.`
        },
        {
            text:`We provide and operate the Website only, and are not responsible or liable for the conduct or activities of other third parties, including any third-party service provider or Practitioner; and`
        },
        {
            text:`You agree that under no circumstance will we be liable for:`,
            innerContent:[
                {
                    text:`Subject to clause 11.5, any illness, injury, or death resulting from use of the Website; or`
                },
                {
                    text:`any other any direct or indirect, incidental or consequential damages, loss or corruption of data, or any other similar or analogous loss resulting from your access to, use of, or inability to use the Website or any content, whether based on warranty, contract, tort, negligence, in equity or any other course of action, and whether or not we knew or should have known of the possibility of such loss.`
                }
            ]
        },
        {
            text:`Neither party is liable to the other in contract, tort (including negligence or breach of statutory duty), misrepresentation or otherwise for any loss of profit or revenue (whether direct or indirect), loss of opportunity or loss of goodwill, and/or any indirect, special or consequential loss of any kind (“Liability”). Any Liability of a party under or arising from these Terms (and any indemnity) shall be reduced proportionately to the extent that any act or omission of the other party or its employees, agents or representatives contributed to such Liability. `
        },
        {
            text:`Nothing in these Terms excludes or limits Liability for: (A) death or personal injury caused by our negligence; (B) fraud or fraudulent misrepresentation; (C) any breach of the obligations implied by Competition and Consumer Act 2010 (Cth) or any other applicable legislation; or (D) any other matter for which it would be illegal to exclude or attempt to exclude Liability.`
        }

    ]
  },
  {
    heading:`Termination of Contract`,
    content:[
        {
            text:`We may at any time, terminate the Terms and remove you from the Website with you if:
            `,
            innerContent:[
                {
                    text:`you have breached any provision of the Terms or intend to breach any provision;`
                },
                {
                    text:`we are required to do so by law; or`
                },
                {
                    text:`The provision of the Services to you by us is, in our opinion, no longer commercially viable.`
                }
            ]
        },
        {
            text:`The provisions of the Agreement which expressly or by implication are intended to survive its termination or expiry will survive and continue to bind each of the parties.`
        }
    ]
  },
  {
    heading:`Indemnity`,
    content:[
        {
            text:`Both parties agree to indemnify the other party, against any Liability suffered or incurred arising from or in connection with any breach of these Terms or any applicable laws. This indemnity is a continuing obligation, independent from the other obligations under these Terms, and continues after these Terms end.  It is not necessary to suffer or incur any Liability before enforcing a right of indemnity under these Terms.`
        }
    ]
  },
  {
    heading:`Jurisdiction and Dispute Resolution`,
    content:[
        {
            text:`If a dispute arises out of or relates to the Terms, either party may not commence any tribunal or Court proceedings in relation to the dispute, unless both parties have attempted to resolve the dispute through mediation. `
        },
        {
            text:`The Services offered by us are intended to be viewed by residents of Australia. The Terms are governed by the laws of Victoria, Australia. In the event of any dispute arising out of or in relation to the Website or Services, you agree that the exclusive venue for resolving any dispute shall be in the courts of Victoria, Australia.`
        }
    ]
  },
  {
    heading:`General`,
    content:[
        {
            text:`No party will be liable to another if it fails to meet its obligations due to matters beyond its reasonable control, including but not limited to an act of god, strike or pandemic.`
        },
        {
            text:`The Agreement forms the entire agreement between the parties in relation to the Services. It replaces any earlier agreements, representations or discussions.`
        },
        {
            text:`If any part of these Terms is found to be void or unenforceable by a Court of competent jurisdiction, that part shall be severed and the rest of the Terms shall remain in force.`
        },
        {
            text:`Unless the Agreement expressly provides otherwise, no party may assign, transfer or deal with their rights or obligations under the Agreement without the prior written consent of the other party. Such consent must not be unreasonably withheld.`
        },
        {
            text:`
            The failure or omission of a party at any time to enforce or require compliance with any provision of this Agreement or exercise any right, election or discretion under this Agreement shall not operate as a waiver of that right, election or discretion.`
        },
        {
            text:`Interpretation:`
            
        },
        {
            text:`The following terms are defined to mean:`,
            innerContent:[
                {
                    text:`Authorised Person means any person who has authority to act on behalf of the Patient, including a parent or a legal guardian of the Patient, or a representative of the Patient who is authorised to include information on the Website on the Patient’s behalf.`
                },
                {
                    text:`Practitioner means either a client such as a medical practice, healthcare practice, allied health practice or an individual therapist that provides medical and healthcare services and has access to and use of the Website for the purpose of making and managing Appointment. `
                },
                {
                    text:`Patient means a patient or consumer of health services of a Practitioner `
                }
            ]
        },

    ]
  }

]

// content = [
//   {
//     text: 'hello',
//     innerContent: [
//       {
//         text: 'world',
//         subInnerContent: [
//           {
//             text: 'boom'
//           }
//         ]
//       }
//     ]
//   }
// ];