import {
  Art,
  CardsClinic,
  CardsHome,
  CardsVideo,
  Counseller,
  Dentist,
  Dietitian,
  Glasses,
  Movie,
  Music,
  Occuptional,
  Physiotherapist,
  Play_Therepist,
  Plus,
  Psychologist,
  Sleep,
  Speach
} from '../../assets/vectors';

export const categoryName = [
  {
    title: 'Speech pathologist',
    img: Speach,
    url: '/search-therapist'
  },
  {
    title: 'Psychologist',
    img: Psychologist,
    url: '/search-therapist'
  },
  {
    title: 'Dietitian',
    img: Dietitian,
    url: '/search-therapist'
  },
  {
    title: 'Occupational Providers',
    img: Occuptional,
    url: '/search-therapist'
  },
  {
    title: 'Play therapist',
    img: Play_Therepist,
    url: '/search-therapist'
  },
  {
    title: 'Art therapist',
    img: Art,
    url: '/search-therapist'
  },
  {
    title: 'Physiotherapist',
    img: Physiotherapist,
    url: '/search-therapist'
  },
  {
    title: 'Counsellor',
    img: Counseller,
    url: '/search-therapist'
  },
  {
    title: 'Music therapist',
    img: Music,
    url: '/search-therapist'
  },
  {
    title: 'therapist dentist',
    img: Dentist,
    url: '/search-therapist'
  },
  {
    title: 'Optometrist',
    img: Glasses,
    url: '/search-therapist'
  },
  {
    title: 'Sleep consultant',
    img: Sleep,
    url: '/search-therapist'
  }
];

export const searchCards = [
  {
    img: CardsClinic,
    title: 'Face to Face',
    title2: 'Clinic Appointment',
    desc: 'Visit a therapist at their clinic.',
    searchValue: 'Face-to-face clinic',
    buttonHomeBased: 'Reserve an Appointment',
    buttonConsultColor: {
      backgroundColor: 'rgba(128, 152, 72, 0.1)',
      color: 'rgba(128, 152, 72, 1)',
      display: 'none'
    },
    buttonHomeBasedColor: {
      backgroundColor: 'rgba(128, 152, 72, 0.1)',
      color: 'rgba(128, 152, 72, 1)',
      padding: '0 10px',
      fontSize: '13px'
    },
    imgColor: {
      backgroundColor: 'rgba(128, 152, 72, 0.15)'
    }
  },
  {
    img: CardsHome,
    title: 'Face to Face',
    title2: 'Home Visit Appointment',
    searchValue: 'Face-to-face home visits',

    desc: 'Find a therapist who can provide support at your home or education facility.',
    buttonHomeBased: 'Reserve an Appointment',
    buttonConsultColor: {
      backgroundColor: 'rgba(128, 152, 72, 0.1)',
      color: 'rgba(128, 152, 72, 1)',
      display: 'none'
    },
    buttonHomeBasedColor: {
      backgroundColor: 'rgba(128, 152, 72, 0.1)',
      color: 'rgba(128, 152, 72, 1)',
      padding: '0 10px',
      fontSize: '13px'
    },
    imgColor: {
      backgroundColor: 'rgba(128, 152, 72, 0.15)'
    }
  },

  {
    img: CardsVideo,
    title: 'Telehealth',
    title2: 'Appointment',
    searchValue: 'Telehealth',

    desc: 'Find a therapist who can provide services to you online.',
    buttonHomeBased: 'Reserve an Appointment',
    buttonConsultColor: {
      backgroundColor: 'rgba(128, 152, 72, 0.1)',
      color: 'rgba(128, 152, 72, 1)',
      display: 'none'
    },
    buttonHomeBasedColor: {
      backgroundColor: 'rgba(128, 152, 72, 0.1)',
      color: 'rgba(128, 152, 72, 1)',
      padding: '0 10px',
      fontSize: '13px'
    },
    imgColor: {
      backgroundColor: 'rgba(128, 152, 72, 0.15)'
    }
  }
];

// export const searchCards = [
//   {
//     img: Plus,
//     title: 'Face to Face Clinic Appointment',
//     desc: 'Find a provider who is the right fit for you',
//     buttonConsult: 'Book an Appointment',
//     buttonConsultColor: {
//       backgroundColor: '#998E95',
//       color: '#ffff',
//       padding: '0 10px',
//       fontSize: '13px'
//     },
//     buttonHomeBasedColor: {
//       display: 'none'
//     },
//     imgColor: {
//       backgroundColor: 'rgb(230, 247, 211)'
//     }
//   },
//   {
//     img: Plus,
//     title: 'Face to Face  Mobile Appointment',
//     desc: `Find a therapist who can provide support at your home or education facility.`,
//     buttonConsult: 'Book an Appointment',
//     buttonConsultColor: {
//       backgroundColor: '#E2F6CA',
//       padding: '0 10px',
//       fontSize: '13px'
//     },
//     imgColor: {
//       backgroundColor: '#FFF2EC'
//     },
//     buttonHomeBasedColor: {
//       display: 'none'
//     }
//     // style: {
//     //   paddingRight: '60px'
//     // }
//   },
//   {
//     img: Movie,
//     title: 'Telehealth Appointment',
//     desc: 'Find a therapist who can provide services to you online.',
//     buttonHomeBased: 'Book an Appointment',
//     buttonConsultColor: {
//       backgroundColor: '#998E95',
//       color: '#ffff',
//       display: 'none'
//     },
