import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getRequest, postRequest } from '../../api/apiCalls';
import Header from '../../components/header/Header';
import { searchCards } from '../../components/searchCategory/constant';
import { ConsultationsTable } from '../../components/consultations/ConsultationsTable';
import { Select } from 'antd';
import { FilterList } from '../../assets/vectors';
import SpinLoader from '../../components/UI/Loader';
import './consultations.scss';
import {
  setFilterValues,
  setSearchedTherapists
} from '../../Redux/Reducers/searchedTherapistsSlice';
import { sortTherapists } from '../../helpers/common';
export const Consultation = () => {
  const dispatch = useDispatch();
  const profileData = useSelector(state => state.profile.profileData);
  const navigate = useNavigate();
  const [consultations, setconsultations] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [dateFilter, setDateFilter] = useState('all');
  const [typeFilter, setTypeFilter] = useState('all');
  const [nameFilter, setNameFilter] = useState('');
  const [filteredAppointments, setFilteredAppointments] = useState([]);

  const onFinish = async (categorySelected, isTypeSearch, type) => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    });
    dispatch(
      setFilterValues({
        location: '',
        lat: '',
        lng: '',
        name: '',
        category: {},
        subCategory: {}
      })
    );
    setisLoading(true);
    const dataToSend = {
      categories: categorySelected?.value || '',
      subCategories: [],
      lat: '',
      lon: '',
      therapistName: '',
      serviceDelivery: isTypeSearch ? type?.value : ''
    };

    const onSuccess = res => {
      const sortedTherapists = sortTherapists(res?.data);
      dispatch(setSearchedTherapists(sortedTherapists || []));
      dispatch(
        setFilterValues({
          location: '',
          lat: '',
          lng: '',
          name: '',
          category: categorySelected || {},
          subCategory: {}
        })
      );
      if (isTypeSearch) {
        navigate(`/search-therapist/?filterType=${type?.value}`);
      } else {
        navigate('/search-therapist/?isHomeSearched=true');
      }

      setisLoading(false);
    };
    const onError = err => {
      setisLoading(false);
    };
    await postRequest(
      dataToSend,
      `customer/get-search`,
      true,
      true,
      onSuccess,
      onError
    );
  };
  useEffect(() => {
    const getAllData = async () => {
      setisLoading(true);
      const onSuccess = res => {
        const validData = res?.customer?.filter(elem => elem?.scheduleInfo);
        setconsultations(validData);
        setFilteredAppointments(validData);
        setisLoading(false);
      };
      const onError = res => {
        console.log(res, 'erorr res');
        setisLoading(false);
      };
      await getRequest(
        null,
        `customer/get-appointments/${profileData?.userId?._id}`,
        true,
        true,
        onSuccess,
        onError
      );
    };
    getAllData();
  }, []);
  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    let filteredArray = [];
    if (dateFilter === 'month') {
      filteredArray = consultations?.filter(item => {
        const itemDate = new Date(item?.scheduleInfo?.start);
        return (
          itemDate?.getFullYear() === currentYear &&
          itemDate?.getMonth() === currentMonth
        );
      });
    } else if (dateFilter === 'week') {
      filteredArray = consultations?.filter(item => {
        const itemDate = new Date(item?.scheduleInfo?.start);
        const startOfWeek = new Date(currentDate);
        startOfWeek.setHours(0, 0, 0, 0);
        startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());

        const endOfWeek = new Date(currentDate);
        endOfWeek.setHours(23, 59, 59, 999);
        endOfWeek.setDate(startOfWeek.getDate() + 6);

        return itemDate >= startOfWeek && itemDate <= endOfWeek;
      });
    } else if (dateFilter === 'day') {
      filteredArray = consultations?.filter(item => {
        const itemDate = new Date(item?.scheduleInfo?.start);
        const startOfDay = new Date(currentDate);
        startOfDay.setHours(0, 0, 0, 0);
        const endOfDay = new Date(currentDate);
        endOfDay.setHours(23, 59, 59, 999);
        return itemDate >= startOfDay && itemDate <= endOfDay;
      });
    } else {
      filteredArray = consultations;
    }
    if (typeFilter !== 'all') {
      filteredArray = filteredArray?.filter(item => {
        return item?.scheduleInfo?.type === typeFilter;
      });
    }
    if (nameFilter) {
      filteredArray = filteredArray?.filter(item => {
        const therapistName =
          item?.therapistId?.personalInfo?.firstName +
          ' ' +
          item?.therapistId?.personalInfo?.lastName;
        return therapistName
          ?.toLowerCase()
          ?.includes(nameFilter?.toLowerCase());
      });
    }
    setFilteredAppointments(filteredArray);
  }, [dateFilter, nameFilter, typeFilter]);

  return (
    <div>
      <Header />
      {isLoading ? (
        <SpinLoader />
      ) : (
        <div className='consultationPageWrapper'>
          <div className='head'>
            <h2> Find the right therapist for you and your family.</h2>
            {/* <p> 1 Million+ patients found providers through Therapy Match. </p> */}
          </div>
          <div className='cards'>
            {searchCards.map(el => (
              <div className='card' style={{ minHeight: '200px' }}>
                <div className='left' style={el.imgColor}>
                  <img src={el?.img} alt='' />
                </div>
                <div className='right'>
                  <h2> {el.title} </h2>
                  {el.title2 ? (
                    <h2 className='subHead'> {el.title2} </h2>
                  ) : null}

                  <p style={el.style}> {el.desc} </p>
                  <div className='buttons_category'>
                    <button
                      className='attend-clinic-btn'
                      style={el.buttonConsultColor}
                      onClick={
                        () =>
                          onFinish(null, true, {
                            label: el?.searchValue,
                            value: el?.searchValue
                          })
                        // navigate(
                        //   `/search-therapist/?filterType=${el?.searchValue}`
                        // )
                      }
                    >
                      {el.buttonConsult}
                    </button>
                    <button
                      style={el.buttonHomeBasedColor}
                      onClick={
                        () =>
                          onFinish(null, true, {
                            label: el?.searchValue,
                            value: el?.searchValue
                          })
                        // navigate(
                        //   `/search-therapist/?filterType=${el?.searchValue}`
                        // )
                      }
                    >
                      {el.buttonHomeBased}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className='consultationsMainWrapper'>
            <h2 className='consultationsHeading'> Consultations </h2>
            <div className='consultationsFiltersWrap'>
              <img src={FilterList} alt='' className='filter_img' />
              <Select
                placeholder='All'
                onChange={val => {
                  setTypeFilter(val);
                }}
                options={[
                  { label: 'All', value: 'all' },
                  { label: 'Clinic', value: 'clinic' },
                  { label: 'Video', value: 'video' },
                  { label: 'Home', value: 'home' }
                ]}
              />

              <Select
                placeholder='Select Time'
                onChange={val => {
                  setDateFilter(val);
                }}
                options={[
                  { label: 'All', value: 'all' },
                  { label: 'Current Month', value: 'month' },
                  { label: 'Current Week', value: 'week' },
                  { label: 'Today', value: 'day' }
                ]}
              />

              <div className='input-search'>
                <input
                  type='text'
                  placeholder='Search Provider'
                  onChange={e => setNameFilter(e.target.value)}
                />
              </div>
            </div>
            <div className='consultationsTableWrap'>
              <ConsultationsTable data={filteredAppointments} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
