import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import ResetPassword from '../pages/auth/ResetPassword';
import ForgetPasswordOTP from '../pages/auth/ForgetPasswordOTP';
import { useSelector } from 'react-redux';
import LandingPage from '../pages/landingPage/LandingPage';
import Stepper from '../components/makeAppointment/Stepper';
import Contact from '../pages/contact/Contact';
import Notification from '../components/notification/Notification';
import MyAccount from '../components/myAccount/MyAccount';
import RateExperience from '../components/rateExperience/RateExperience';
import { Login } from '../pages/auth/Login';
import { SignUp } from '../pages/auth/SignUp';
import { Disclaimer } from '../pages/auth/Disclaimer';
import SearchTherapist from '../pages/searchTherapist/SearchTherapist';
import OtpOverification from '../pages/auth/OtpVerification';
import ForgetPassword from '../pages/auth/ForgetPassword';
import { useEffect } from 'react';
import { JoinService } from '../pages/landingPage/JoinService';
import { AllCategories } from '../pages/landingPage/AllCategories';
import { SingleDoctorProfile } from '../pages/profiles/SingleDoctorProfile';
import { SingleClinicProfile } from '../pages/profiles/SingleClinicProfile';
import { Consultation } from '../pages/consultations/Consultation';
import { SingleConsultation } from '../pages/consultations/SingleConsultation';
import { HelpCenter } from '../pages/helpCenter/HelpCenter';
import { PrivacyPolicy } from '../pages/privacyPolicy/PrivacyPolicy';
import TermsConditions from '../pages/terms/TermsConditions';
import ConsultationSuccess from '../pages/consultations/ConsultationSuccess';
import { CustomLogout } from '../helpers/CustomLogout';
import OurMission from '../pages/ourMission/OurMission';
export const AppRoutes = () => {
  const email = useSelector(state => state.authReducer.email);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    });
  }, [pathname]);
  const MainRoutes = () => {
    return (
      <>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route path='*' element={<LandingPage />} />
            <Route path='/make-appointment/:id' element={<Stepper />} />

            <Route
              path='/appointment-detail/:bookingId'
              element={<SingleConsultation />}
            />
            <Route path='/notification' element={<Notification />} />
            <Route path='/account' element={<MyAccount />} />
            <Route
              path='/rate-your-experience/:bookingId'
              element={<RateExperience />}
            />
            <Route path='/consultations' element={<Consultation />} />
            <Route
              path='/consultations-success'
              element={<ConsultationSuccess />}
            />
            <Route path='*' element={<Navigate to='/' />} />
          </Route>
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/terms-and-conditions' element={<TermsConditions />} />
          <Route path='/' element={<LandingPage />} />
          <Route path='/contact-us' element={<Contact />} />
          <Route path='/join-service' element={<JoinService />} />
          <Route path='/help-center' element={<HelpCenter />} />
          <Route path='/our-mission' element={<OurMission />} />

          <Route path='/all-categories' element={<AllCategories />} />
          <Route path='/logout-session-timeout' element={<CustomLogout />} />
          <Route
            path='/doctor-information/:id'
            element={<SingleDoctorProfile />}
          />
          <Route
            path='/clinic-information/:id'
            element={<SingleClinicProfile />}
          />
          {/* <Route path='/about-us' element={<About />} /> */}
          <Route path='/search-therapist' element={<SearchTherapist />} />
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<SignUp />} />
          <Route path='/confirm-signup' element={<Disclaimer />} />
          {email && (
            <Route path='/signup/verify' element={<OtpOverification />} />
          )}
          <Route path='/forget-password' element={<ForgetPassword />} />
          <Route path='/forget-password-otp' element={<ForgetPasswordOTP />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route path='*' element={<Navigate to='/' />} />
        </Routes>
      </>
    );
  };

  return MainRoutes();
};
