import { ConfigProvider, Spin } from 'antd';
import './SpinLoader.scss';

const SpinLoader = ({ height = '100vh' }) => {
  return (
    <div
      className='loader-antd'
      style={{
        height: height
      }}
    >
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#809848'
          }
        }}
      >
        <Spin />
      </ConfigProvider>
    </div>
  );
};

export default SpinLoader;
