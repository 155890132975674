import React, { useState } from 'react';
import './contact.scss';
import { Form, Input, Modal } from 'antd';
import Footer from '../../components/footer/Footer';
import { CircleCheck } from '../../assets/vectors';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import Header from '../../components/header/Header';
import TextArea from '../../components/UI/TextArea';
import { postRequest } from '../../api/apiCalls';
import ButtonGreen from '../../components/UI/Button/ButtonGreen';

const Contact = () => {
  const [form] = Form.useForm();
  const [messageBtn, setMessageBtn] = useState(false);
  const [remainingTime, setRemainingTime] = useState(7);

  const showFooter = true;

  const handleOk = () => {
    setRemainingTime(7);
  };

  const onFinish = async values => {
    const dataToSend = {
      subject: values?.subject || '',
      description: values?.message || '',
      email: values?.email || ''
    };
    const onSuccess = res => {
      setMessageBtn(true);
      form.resetFields();
    };
    const onError = err => {
      setMessageBtn(false);
      form.resetFields();
    };
    await postRequest(
      dataToSend,
      `contact/contact_us`,
      true,
      true,
      onSuccess,
      onError
    );
  };
  return (
    <>
      <Header />
      <div className='space'></div>
      <div className='contact__wrapper'>
        <div className='contact'>
          <h1> Contact us </h1>
          <Form
            name='basic'
            layout='vertical'
            form={form}
            onFinish={onFinish}
            autoComplete='off'
          >
            <Form.Item
              name='email'
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please enter email'
                },
                {
                  pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                  message: 'Please enter a valid email!'
                }
              ]}
            >
              <Input placeholder='Email address' type='email' />
            </Form.Item>
            <Form.Item
              name='subject'
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please enter subject'
                }
              ]}
            >
              <Input placeholder='Subject' type='text' />
            </Form.Item>
            <Form.Item
              name='message'
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please enter message'
                }
              ]}
            >
              <TextArea placeholder='Message' type='text' />
            </Form.Item>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ButtonGreen text={'Send message'} type='submit' />
            </div>
          </Form>
        </div>
      </div>
      {showFooter && <Footer />}
      {messageBtn && (
        <Modal
          centered
          open={messageBtn}
          onOk={handleOk}
          afterOpenClose={() => setRemainingTime(7)}
          className='contact_modal'
          onCancel={() => {
            setMessageBtn(false);
          }}
        >
          <div className='modal_submitted'>
            <div className='timer'>
              <CountdownCircleTimer
                isPlaying
                duration={remainingTime}
                colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                colorsTime={[remainingTime, 5, 2, 0]}
                size={50}
                strokeWidth={2}
                onComplete={() => {
                  setRemainingTime(7);
                  setMessageBtn(false);
                }}
              >
                {({ remainingTime }) => remainingTime}
              </CountdownCircleTimer>
            </div>
            <img src={CircleCheck} alt='' />
            <h1> Message sent </h1>
            <p>
              Your message has been sent to our experts. You will be contacted
              soon regarding your query. Thank you.
            </p>
            <div>
              <button
                className='modal_btn'
                onClick={() => setMessageBtn(false)}
              >
                Okay
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Contact;
