import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';
import { getRequest } from '../../api/apiCalls';
import Header from '../../components/header/Header';
import SpinLoader from '../../components/UI/Loader';
import { Col, Divider, Row, Tooltip } from 'antd';
import {
  Achievement,
  AgeGroupIcon,
  BrainImg,
  Chevron_Down_Orange,
  DoctorWaitTimeIcon,
  EmailIcon,
  Left_Btn,
  Location,
  PhoneIcon
} from '../../assets/vectors';
import { DP } from '../../assets/images';
import { RightOutlined, UserOutlined } from '@ant-design/icons';
import Footer from '../../components/footer/Footer';
import { generateClinicDetails } from './constants';
import { useSelector } from 'react-redux';

export const SingleClinicProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 540px)' });
  const isTab = useMediaQuery({ query: '(max-width: 1024px)' });
  const token = useSelector(state => state.authReducer.token);

  const [isLoading, setIsLoading] = useState(false);
  const [therapistsToShow, setTherapistsToShow] = useState(4);
  const [singleClinic, setSingleClinic] = useState(null);
  const [clinicTherapists, setClinicTherapists] = useState(null);
  const clinicDetails = generateClinicDetails(
    singleClinic,
    token ? true : false
  );

  const getSingleClinic = async () => {
    setIsLoading(true);
    const onSuccess = res => {
      setSingleClinic(res.data);
      setIsLoading(false);
    };
    const onError = () => {
      setIsLoading(false);
    };
    await getRequest(
      null,
      `clinic/get-single-clinic/${id}`,
      true,
      true,
      onSuccess,
      onError
    );
  };

  const getClinicTherapists = async () => {
    setIsLoading(true);
    const onSuccess = res => {
      setIsLoading(false);
      setClinicTherapists(res?.data);
    };
    const onError = () => {
      setIsLoading(false);
    };
    await getRequest(
      null,
      `clinic/get-clinic-approved-therapists/${id}`,
      true,
      true,
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    });
    getSingleClinic();
    getClinicTherapists();
  }, []);
  return (
    <>
      <Header />
      {isLoading ? (
        <SpinLoader />
      ) : (
        <>
          <div className='doctorDetailsWrapper'>
            <Row>
              <Col span={24} className='detailsWrapper'>
                <div className='doctorBackButton' onClick={() => navigate(-1)}>
                  <img src={Left_Btn} alt='Previous' />
                  <p> Back </p>
                </div>
                <div className='headDetails'>
                  <div className='profileImg'>
                    {singleClinic?.clinicDetails?.clinicLogo ? (
                      <img
                        src={singleClinic?.clinicDetails?.clinicLogo}
                        alt=''
                      />
                    ) : (
                      <DP />
                    )}
                  </div>
                  <div className='doctorName'>
                    <h3>{singleClinic?.clinicDetails?.clinicName}</h3>
                    <h4>{singleClinic?.services?.practice?.name}</h4>
                    {/* <div className='headTags'>
                      <a
                        className='singleTag'
                        href={
                          token
                            ? `tel:${singleClinic?.representativeDetails?.representativePhoneNumber}`
                            : '#'
                        }
                      >
                        <img src={PhoneIcon} alt='' />
                        {token ? (
                          singleClinic?.representativeDetails
                            ?.representativePhoneNumber
                        ) : (
                          <Tooltip
                            arrow={false}
                            title='Please login to view Phone number'
                          >
                            XXXX XXX XXX
                          </Tooltip>
                        )}
                   
                      </a>

                      <a
                        className='singleTag'
                        href={
                          token
                            ? `mailto:${singleClinic?.representativeDetails?.representativeEmail}`
                            : '#'
                        }
                      >
                        <img src={EmailIcon} alt='' />
                        {token ? (
                          singleClinic?.representativeDetails
                            ?.representativeEmail
                        ) : (
                          <Tooltip
                            arrow={false}
                            title='Please login to view Email'
                          >
                            ******@******.com
                          </Tooltip>
                        )}
                      </a>
                    </div>{' '} */}
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className='doctorDetailsWrapper'>
            <h2 className='doctorDetailHeading'>Clinic Details</h2>
            <Row gutter={16} style={{ marginTop: '20px' }}>
              {clinicDetails?.filter(Boolean)?.map((profileInfo, ind) => {
                return (
                  <Col
                    style={{ marginBottom: '20px ' }}
                    span={isMobile ? 24 : isTab ? 12 : 8}
                  >
                    <div className='memberDetailCard'>
                      <div className='singleDetailWrapper'>
                        <h4 className='customSingleViewHeading'>
                          <img src={profileInfo?.icon} alt='$$' />
                          <span> {profileInfo?.label}</span>
                        </h4>
                        <p className='customSingleViewParagraph'>
                          {profileInfo?.value}{' '}
                        </p>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
          <div className='doctorDetailsWrapper'>
            <h2 className='doctorDetailHeading'>Providers</h2>
            {clinicTherapists?.length ? (
              <Row className='clinicMembersWrapper' gutter={16}>
                {clinicTherapists
                  ?.slice(0, therapistsToShow)
                  ?.map((data, ind) => {
                    return (
                      <>
                        <Col
                          span={12}
                          lg={12}
                          md={24}
                          sm={24}
                          xs={24}
                          className='singleCard'
                          key={ind}
                        >
                          <div className='singleCardDetails'>
                            <div className='detailsHead'>
                              <div className='image'>
                                {data?.personalInfo?.profileImg ? (
                                  <img
                                    src={data?.personalInfo?.profileImg}
                                    alt=''
                                  />
                                ) : (
                                  <DP />
                                )}
                              </div>
                              <div className='name'>
                                <h4>
                                  {' '}
                                  {data?.personalInfo?.firstName +
                                    ' ' +
                                    data?.personalInfo?.lastName}
                                </h4>
                                <button
                                  className='customNoBg'
                                  onClick={() =>
                                    navigate(`/doctor-information/${data?._id}`)
                                  }
                                >
                                  Read More <RightOutlined />
                                </button>
                              </div>
                            </div>
                            <div className='providerDetailsWrap'>
                              <div className='singleMemberProfileContent'>
                                <div className='singleMemberProfileDetail'>
                                  <img
                                    src={BrainImg}
                                    alt=''
                                    className='brainImg'
                                  />
                                  <div className='content'>
                                    <div className='contentHead'>
                                      {data?.providerProfile?.practice
                                        ?.map(category => category.name)
                                        ?.join(', ')}
                                    </div>
                                    <div className='contentDetail'>
                                      {data?.providerProfile?.practice
                                        ?.flatMap(category =>
                                          category.practiceSpecialties.map(
                                            subCategory => subCategory.name
                                          )
                                        )
                                        ?.join(', ')}
                                    </div>
                                  </div>
                                </div>
                                <div className='singleMemberProfileDetail'>
                                  <img
                                    src={Location}
                                    alt=''
                                    className='brainImg'
                                  />
                                  <div className='content'>
                                    <div className='contentHead'>
                                      {data?.personalInfo?.bussinessAddress}
                                    </div>
                                  </div>
                                </div>
                                {token ? (
                                  <>
                                    <div className='singleMemberProfileDetail'>
                                      <img
                                        src={AgeGroupIcon}
                                        alt=''
                                        className='brainImg'
                                      />
                                      <div className='content'>
                                        <div className='contentHead'>
                                          {' '}
                                          {data?.providerProfile?.ageGroup
                                            ?.map(group =>
                                              group === '0' ? 'Infants' : group
                                            )
                                            .join(', ') || '-'}
                                        </div>
                                      </div>
                                    </div>
                                    <div className='singleMemberProfileDetail'>
                                      <UserOutlined />
                                      <div className='content'>
                                        <div className='contentHead'>
                                          {/* {data?.personalInfo?.bio} */}
                                          {data?.personalInfo?.bio &&
                                            (data.personalInfo.bio.split(' ')
                                              .length > 20
                                              ? `${data.personalInfo.bio
                                                  .split(' ')
                                                  .slice(0, 20)
                                                  .join(' ')}...`
                                              : data.personalInfo.bio)}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </>
                    );
                  })}
              </Row>
            ) : (
              <p className='noProviderText'>No Provider found</p>
            )}
            {therapistsToShow < clinicTherapists?.length && (
              <button
                className='notification_see_more'
                onClick={() => setTherapistsToShow(prev => prev + 2)}
              >
                See more <img src={Chevron_Down_Orange} alt='' />
              </button>
            )}
          </div>
        </>
      )}
      <Footer color={true} />
    </>
  );
};
