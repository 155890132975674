import { Form, Spin, message } from 'antd';
import './SignUp.scss';
import Select from '../../components/UI/Select';
import AntdPassword from '../../components/UI/Password';
import { AuthLayout } from '../../components/layout/AuthLayout';
import AntdInput from '../../components/UI/Input';
import AntdDatePicker from '../../components/UI/DatePicker';
import { useState } from 'react';
import { disabledFuturesDates } from '../../helpers/dates';
import ButtonGreen from '../../components/UI/Button/ButtonGreen';
import Checkbox from '../../components/UI/Checkbox';
import { setEmail, updateAuthField } from '../../Redux/Reducers/authSlice';
import dayjs from 'dayjs';
import { getRequest, postRequest } from '../../api/apiCalls';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const baseUrl = window.location.origin;
  const [isLoading, setisLoading] = useState(false);
  const [isChecked, setisChecked] = useState(false);
  const validateEmail = async values => {
    setisLoading(true);
    if (values?.terms) {
      await getRequest(
        '',
        `mails/verify-email/${values?.email}`,
        true,
        true,
        res => {
          if (res?.data?.Role && !res?.data?.Reason?.includes('DNS')) {
            signUpHandler(values);
          } else if (
            res?.data?.Disposable ||
            res?.data?.Result === 'Risky' ||
            res?.data?.Result === 'Invalid'
          ) {
            message.error(
              'The provided email address is invalid. Please verify and enter a valid email address.'
            );
          } else {
            signUpHandler(values);
          }
          setisLoading(false);
        },
        err => {
          console.log(err);
          setisLoading(false);
        }
      );
    } else {
      setisLoading(false);
      message.error('Please fill all required fields');
    }
  };
  const signUpHandler = async values => {
    if (!values?.terms) {
      message.error('Please accept terms.');
    }
    setisLoading(true);

    const onSuccess = res => {
      setisLoading(false);
      dispatch(setEmail(values.email));
      dispatch(updateAuthField({ field: 'isProfileFilled', value: false }));
      navigate('/signup/verify');
    };
    const onError = () => {
      setisLoading(false);
    };
    const dataToSend = {
      fullName: values?.fullName,
      dob: dayjs(values?.dob),
      password: values?.password,
      email: values?.email,
      relationship: values?.relationship,
      relationName: values?.relationName,
      gender: values?.gender,
      phoneNumber: values?.phoneNumber
    };
    await postRequest(
      dataToSend,
      'customer/signup',
      true,
      true,
      onSuccess,
      onError
    );
  };
  return (
    <>
      <AuthLayout>
        <Spin spinning={isLoading}>
          <div className='signup-main-container'>
            <div className='login-main-content'>
              <h3>Sign up</h3>

              <Form
                name='basic'
                style={{ width: '100%' }}
                onFinish={validateEmail}
                autoComplete='off'
                layout='vertical'
              >
                <Form.Item
                  name='fullName'
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'patient/child name is required'
                    }
                  ]}
                  label='Patient/child name'
                >
                  <AntdInput placeholder='Enter patient/child name' />
                </Form.Item>

                <Form.Item
                  name={'dob'}
                  rules={[
                    {
                      required: true,
                      message: 'Patient DOB is required'
                    }
                  ]}
                  label="Patient's date of birth"
                >
                  <AntdDatePicker
                    disabledDate={disabledFuturesDates}
                    required
                    placeholder="Enter patient's date of birth"
                  />
                </Form.Item>

                <Form.Item
                  name='gender'
                  rules={[
                    {
                      required: true,
                      message: 'patient/child gender is required'
                    }
                  ]}
                  label='Patient/child gender'
                >
                  <Select
                    placeholder='Select patient/child gender'
                    options={[
                      { label: 'Male', value: 'male' },
                      { label: 'Female', value: 'female' },
                      { label: 'Other', value: 'other' }
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  name='email'
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'Reachable email address is required'
                    },
                    {
                      pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                      message: 'Please enter a valid email!'
                    }
                  ]}
                  label='Reachable email address'
                >
                  <AntdInput
                    placeholder='Email Address'
                    type='email'
                    required
                  />
                </Form.Item>

                <Form.Item
                  name='relationName'
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'Parent/Guardian/Representative name is required'
                    }
                  ]}
                  label='Parent/Guardian/Representative name'
                >
                  <AntdInput placeholder='Enter Parent/Guardian/Representative name' />
                </Form.Item>
                <Form.Item
                  name='relationship'
                  rules={[
                    {
                      required: true,
                      message:
                        'Parent/Guardian/Representative (Relationship to Child) is required'
                    }
                  ]}
                  label='Parent/Guardian/Representative (Relationship to Child)'
                >
                  <Select
                    placeholder='Select Parent/Guardian/Representative (Relationship to Child)'
                    options={[
                      { label: 'Parent', value: 'parent' },
                      { label: 'Guardian', value: 'guardian' },
                      { label: 'Representative', value: 'representative' }
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'Please enter phone number'
                    },
                    {
                      validator: (_, value) => {
                        const phoneNumber = value
                          ? value.replace(/\D/g, '')
                          : '';
                        if (phoneNumber?.length && phoneNumber?.length < 10) {
                          return Promise.reject(
                            'Please enter a valid phone number'
                          );
                        }
                        return Promise.resolve();
                      }
                    }
                  ]}
                  name={'phoneNumber'}
                  label='Phone number'
                >
                  <AntdInput type='number' placeholder='Enter phone number' />
                </Form.Item>
                <Form.Item
                  name='password'
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      validator: async (_rule, value) => {
                        if (!value) {
                          return Promise.reject('Password is required');
                        } else if (value.length < 6) {
                          return Promise.reject(
                            'Password must be 6 characters'
                          );
                        }
                      }
                    }
                  ]}
                  label='Password'
                >
                  <AntdPassword required placeholder='Password' />
                </Form.Item>
                <Form.Item
                  name={'terms'}
                  rules={[
                    {
                      required: true,
                      message:
                        'Please agree to the Terms of Service and Privacy Policy'
                    }
                  ]}
                  valuePropName='checked'
                >
                  <div className='setting-checkbox terms-conditions'>
                    <Checkbox
                      onChange={e => {
                        setisChecked(e.target.checked);
                      }}
                    >
                      I’ve read and agree with{' '}
                      <a
                        className='secClr'
                        target='_blank'
                        rel='noreferrer'
                        href={`${baseUrl}/terms-and-conditions`}
                      >
                        Terms and Conditions of Use
                      </a>{' '}
                      and{' '}
                      <a
                        className='secClr'
                        target='_blank'
                        rel='noreferrer'
                        href={`${baseUrl}/privacy-policy`}
                      >
                        Privacy Policy
                      </a>{' '}
                    </Checkbox>
                  </div>
                </Form.Item>
                <div className='btn-center-single login-button'>
                  <ButtonGreen
                    disabled={!isChecked || isLoading}
                    text={'Sign Up'}
                    type='submit'
                  />
                </div>
              </Form>
            </div>
          </div>
        </Spin>
      </AuthLayout>
    </>
  );
};
