import { Table } from 'antd';
import React from 'react';
import { F2FClinic, F2FHome, VideoCam } from '../../assets/vectors';
import { formatDateTime } from '../../helpers/dates';

export const FollowUpSessions = ({ data }) => {
  const frequencyArray = {
    ONE_OFF: 'One-off',
    WEEKLY: 'Weekly',
    FORTNIGHTLY: 'Fortnightly',
    MONTHLY: 'Monthly'
  };
  const columns = [
    {
      title: 'Type',
      render: val => (
        <>
          {data?.schedule?.type === 'video' ? (
            <img src={VideoCam} alt='' />
          ) : (
            ''
          )}
          {data?.schedule?.type === 'home' ? <img src={F2FHome} alt='' /> : ''}
          {data?.schedule?.type === 'clinic' ? (
            <img src={F2FClinic} alt='' />
          ) : (
            ''
          )}
        </>
      )
    },
    {
      title: 'Date & Time',
      dataIndex: 'scheduleId',
      render: val => {
        return formatDateTime(val?.start);
      }
    },
    {
      title: 'Provider',
      render: val => {
        return data?.therapist?.firstName + ' ' + data?.therapist?.lastName;
      }
    },

    {
      title: 'Patient',
      render: val => {
        return data?.booking?.fullName;
      }
    },
    {
      title: 'Status',
      dataIndex: 'scheduleId',
      render: val => {
        const currentDateTime = new Date();
        const scheduleStart = new Date(val?.start);
        currentDateTime.setUTCSeconds(0);
        currentDateTime.setUTCMilliseconds(0);
        if (currentDateTime.getMinutes() < 30) {
          currentDateTime.setMinutes(0);
        } else {
          currentDateTime.setMinutes(30);
        }
        if (val?.status === 'upcoming' && scheduleStart <= currentDateTime) {
          return 'In progress';
        }
        return val?.status || '';
      }
    },
    {
      title: 'Frequency',
      dataIndex: 'scheduleId',
      render: val => {
        return frequencyArray[val?.scheduleFrequency] || '-';
      }
    }
  ];
  return (
    <div>
      {' '}
      <Table
        columns={columns}
        dataSource={data?.sessions}
        scroll={true}
        pagination={{
          pageSize: 10
        }}
      />
    </div>
  );
};
