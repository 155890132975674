import React, { useEffect, useState } from 'react';
import { Appointments, DP } from '../../assets/images';
import { RightOutlined, UserOutlined } from '@ant-design/icons';
import {
  AgeGroupIcon,
  BrainImg,
  CalendarXmark,
  DoctorServiceDeliveryBlack,
  HospitalUserRegular,
  Location
} from '../../assets/vectors';
import { ScheduleCalender } from './ScheduleCalender';
import ButtonGreen from '../../components/UI/Button/ButtonGreen';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

export const SingleDoctorCard = ({
  data,
  setWaitlistActiveThearapist,
  setOpenModal
}) => {
  const navigate = useNavigate();
  const token = useSelector(state => state.authReducer.token);
  const [showItems, setshowItems] = useState(4);
  const [reformattedSchedules, setReformattedSchedules] = useState([]);
  const getMaxLength = () => {
    const filteredItems = reformattedSchedules?.map(item => {
      return item?.schedules?.filter(e => new Date(e?.start) > new Date());
    });
    return filteredItems
      ?.map(obj => obj?.length)
      ?.reduce((max, len) => Math.max(max, len), 0);
  };
  const reformatSchedules = schedules => {
    const now = dayjs();
    const schedulesWithDates = schedules
      ?.map(schedule => ({
        ...schedule,
        start: dayjs(schedule?.start),
        end: dayjs(schedule?.end)
      }))
      ?.filter(
        schedule =>
          schedule?.start?.isAfter(now) && schedule?.status === 'available'
      );
    const groupedSchedules = schedulesWithDates?.reduce((acc, schedule) => {
      const date = schedule?.start?.format('YYYY-MM-DD');
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push({
        ...schedule,
        start: schedule?.start?.toISOString(),
        end: schedule?.end?.toISOString()
      });
      return acc;
    }, {});
    const transformedSchedules = Object.keys(groupedSchedules)?.map(date => ({
      date: date,
      schedules: groupedSchedules[date]
    }));
    transformedSchedules?.forEach(schedule => {
      schedule?.schedules?.sort(
        (a, b) => dayjs(a?.start).valueOf() - dayjs(b?.start).valueOf()
      );
    });
    transformedSchedules.sort(
      (a, b) =>
        dayjs(a?.schedules[0]?.start).valueOf() -
        dayjs(b?.schedules[0]?.start).valueOf()
    );
    setReformattedSchedules(transformedSchedules);
  };
  const isAnyDateInNext7Days = array => {
    const today = new Date();
    const endOfNext7Days = new Date(today);
    endOfNext7Days.setDate(today.getDate() + 6);

    today.setHours(0, 0, 0, 0);
    endOfNext7Days.setHours(0, 0, 0, 0);

    const isInNext7Days = dateString => {
      const date = new Date(dateString);
      date.setHours(
        today.getHours(),
        today.getMinutes(),
        today.getSeconds(),
        today.getMilliseconds()
      );

      return date >= today && date <= endOfNext7Days;
    };

    return array.some(item => isInNext7Days(item.date));
  };
  useEffect(() => {
    reformatSchedules(data?.schedules);
  }, [data]);
  return (
    <div className='card_slot'>
      <div className='cardWrap'>
        <div className='left'>
          <div className='first__div'>
            <div className='imageContainer'>
              {data?.profileImg ? (
                <img src={data?.profileImg} alt='' />
              ) : (
                <DP />
              )}
            </div>
            <div className='name__stars'>
              <h4>{data?.name}</h4>
              <button
                className='customNoBg'
                onClick={() => navigate(`/doctor-information/${data?._id}`)}
              >
                Read More <RightOutlined />
              </button>
            </div>
          </div>
          <div className='third__div'>
            <img src={BrainImg} alt='' className='brainImg' />
            <div className='secound__div__content'>
              <p className='para_heading'>
                {data?.practice?.map(category => category.name)?.join(', ')}
              </p>
              <p className='para_title'>
                {data?.practice
                  ?.flatMap(category =>
                    category.practiceSpecialties.map(
                      subCategory => subCategory.name
                    )
                  )
                  ?.join(', ')}
              </p>
            </div>
          </div>
          <div className='third__div'>
            <img src={Location} alt='' className='location' />
            <div className='secound__div__content'>
              <p className='para_heading'>{data?.address}</p>
            </div>
          </div>

          <>
            {' '}
            <div className='third__div'>
              <img src={AgeGroupIcon} alt='' />
              <div className='secound__div__content'>
                <p className='para_heading'>
                  {token ? (
                    data?.providerProfile?.ageGroup
                      ?.map(group => (group === '0' ? 'Infants' : group))
                      .join(', ') || '-'
                  ) : (
                    <p
                      className='loginToViewText'
                      onClick={() => navigate('/login')}
                    >
                      Login to view
                    </p>
                  )}
                </p>
              </div>
            </div>
            <div className='third__div'>
              <img src={DoctorServiceDeliveryBlack} alt='' />
              <div className='secound__div__content'>
                <p className='para_heading'>
                  {token ? (
                    data?.providerProfile?.serviceDelivery?.join(', ') || '-'
                  ) : (
                    <p
                      className='loginToViewText'
                      onClick={() => navigate('/login')}
                    >
                      Login to view
                    </p>
                  )}
                </p>
              </div>
            </div>
            <div className='third__div'>
              <UserOutlined />
              <div className='secound__div__content'>
                <p className='para_heading'>
                  {data?.personalInfo?.bio &&
                    (data.personalInfo.bio.split(' ').length > 20
                      ? `${data.personalInfo.bio
                          .split(' ')
                          .slice(0, 20)
                          .join(' ')} ...`
                      : data.personalInfo.bio)}
                </p>
              </div>
            </div>
            {data?.clinicId ? (
              <div className='third__div'>
                <img src={HospitalUserRegular} alt='' className='location' />
                <div className='secound__div__content'>
                  <p className='para_heading'>
                    Associated with <b>{data?.clinicInfo?.clinicName}</b>
                  </p>
                </div>
              </div>
            ) : null}
          </>
          {!token ? (
            <p className='loginToViewText' onClick={() => navigate('/login')}>
              Login to view complete profile
            </p>
          ) : null}
        </div>
        <div className='right'>
          {reformattedSchedules?.length ? (
            isAnyDateInNext7Days(reformattedSchedules) ? (
              <ScheduleCalender
                data={data}
                showItems={showItems}
                reformattedSchedules={reformattedSchedules}
              />
            ) : (
              <div className='emptySchedules availabilityExists'>
                <span className='emptyScheduleText'>
                  <Appointments />
                  {/* <img src={Appointments} alt='$$$' /> */}
                  <p>
                    Next appointment available on{' '}
                    {dayjs(reformattedSchedules[0]?.date)?.format('DD/MM/YYYY')}
                    .
                  </p>
                </span>
                <ButtonGreen
                  text='View Details'
                  className='Make_an_appointment'
                  onClick={() => {
                    navigate(
                      `/doctor-information/${data?._id}/?activeDate=${reformattedSchedules[0]?.date}`
                    );
                  }}
                />
              </div>
            )
          ) : (
            <>
              <div className='emptySchedules'>
                <span>
                  <img src={CalendarXmark} alt='$$$' />
                  <p> No bookable appointment available</p>
                </span>
                <ButtonGreen
                  text='Join waitlist'
                  className='Make_an_appointment'
                  onClick={() => {
                    if (token) {
                      setWaitlistActiveThearapist(data?._id);
                      setOpenModal(true);
                    } else {
                      navigate('/login');
                    }
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {reformattedSchedules?.length &&
      isAnyDateInNext7Days(reformattedSchedules) ? (
        <>
          {getMaxLength() > showItems && (
            <p
              className='card_slot_seemore'
              onClick={() => setshowItems(showItems + 4)}
            >
              See more
            </p>
          )}
          <p
            className='card_slot_seemore'
            onClick={() => {
              if (token) {
                setWaitlistActiveThearapist(data?._id);
                setOpenModal(true);
              } else {
                navigate('/login');
              }
            }}
          >
            Couldn’t find suitable time? Join waitlist.
          </p>
        </>
      ) : null}
    </div>
  );
};
