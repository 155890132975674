import { useNavigate } from 'react-router-dom';

import './HeaderLogin.scss';
import { Logo } from '../../../assets/images';
import { useMediaQuery } from 'react-responsive';
import ButtonGreen from '../../UI/Button/ButtonGreen';
const HeaderSignUp = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 580px)' });

  return (
    <div className='header-login'>
      <Logo
        style={{ cursor: 'pointer', zIndex: '1' }}
        onClick={() => navigate('/')}
      />
      <ButtonGreen
        className={'registerProviderButton'}
        text={'Login'}
        onClick={e => {
          e.preventDefault();
          navigate('/login');
        }}
      />
    </div>
  );
};

export default HeaderSignUp;
