import React from 'react';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import './JoinService.scss';
import { ClinicSignUp, SingleTherapistSignUp } from '../../assets/vectors';
export const JoinService = () => {
  const baseURL = window.location.origin;

  return (
    <>
      <Header />
      <div className='joinServiceWrapper'>
        <a className='userJoinDropdown' href={`${baseURL}/therapist`}>
          <b>Are you a solo provider?</b>
          <img src={SingleTherapistSignUp} alt='' />
        </a>
        <a className='userJoinDropdown' href={`${baseURL}/clinic`}>
          <b>Are you a therapy practice? </b>
          <img src={ClinicSignUp} alt='' />
        </a>
      </div>
      {<Footer color={true} />}
    </>
  );
};
