import dayjs from 'dayjs';
import {
  DoctorABN,
  DoctorFunding,
  DoctorLanguage,
  DoctorProfileAgeGroup,
  DoctorProfileCalender,
  DoctorProfileCertificate,
  DoctorProfileInstitute,
  DoctorProfileProfession,
  DoctorProfileRadius,
  DoctorProfileSoftware,
  DoctorProfileSpeciality,
  DoctorRegistrationNumber,
  DoctorServiceDelivery,
  LocationDoctor,
  ProviderBody,
  websiteIcon
} from '../../assets/vectors';

export const generateProviderProfileData = (therapist, isLogin) => {
  return [
    {
      label: 'Profession',

      value:
        therapist?.providerProfile?.practice
          ?.map(category => category.name)
          ?.join(', ') || '--',
      icon: DoctorProfileProfession
    },
    {
      label: 'Special Interest Areas',
      value:
        therapist?.providerProfile?.practice
          ?.flatMap(category =>
            category.practiceSpecialties.map(subCategory => subCategory.name)
          )
          ?.join(', ') || '--',
      icon: DoctorProfileSpeciality
    },
    {
      label: 'Practice Address/Location',
      value: therapist?.personalInfo?.bussinessAddress || '--',
      icon: LocationDoctor
    },

    {
      label: 'Age Group',
      value: isLogin
        ? therapist?.providerProfile?.ageGroup
            ?.map(group => (group === '0' ? 'Infants' : group))
            ?.join(', ') || '--'
        : 'Login to View',
      icon: DoctorProfileAgeGroup
    },
    // therapist?.providerProfile?.website
    //   ? {
    //       label: 'Website',
    //       value: isLogin
    //         ? therapist?.providerProfile?.website
    //         : 'Login to View' || '--',

    //       icon: websiteIcon
    //     }
    //   : null,

    {
      label: 'Language',
      value: isLogin
        ? therapist?.providerProfile?.languages?.join(', ') || '--'
        : 'Login to View',
      icon: DoctorLanguage
    },
    {
      label: 'Funding options',
      value: isLogin
        ? therapist?.providerProfile?.fundingOptions?.join(', ') || '--'
        : 'Login to View',
      icon: DoctorFunding
    },
    {
      label: 'Service delivery',
      value: isLogin
        ? therapist?.providerProfile?.serviceDelivery?.join(', ') || '--'
        : 'Login to View',
      icon: DoctorServiceDelivery
    }
  ].filter(obj => obj !== null);
};
export const generateProviderEducationData = education => {
  return [
    {
      label: 'Institution Name',
      value: education?.institutionName,
      icon: DoctorProfileInstitute
    },
    {
      label: 'Certification Name',
      value: education?.certificationName,
      icon: DoctorProfileCertificate
    },
    {
      label: 'Certification Date',
      value: dayjs(education?.certificationYear).format('DD/MM/YYYY'),
      icon: DoctorProfileCalender
    }
  ].filter(obj => obj !== null);
};
export const generateClinicDetails = (clinic, isLogin) => {
  return [
    {
      label: 'Representative name',
      value: clinic?.representativeDetails?.representativeName,
      icon: DoctorProfileProfession
    },
    {
      label: 'Clinic/Practice Address Location',
      value: clinic?.representativeDetails?.clinicAddress,
      icon: LocationDoctor
    },
    {
      label: 'Profession',
      value:
        clinic?.services?.practice
          ?.map(category => category.name)
          ?.join(', ') || '--',
      icon: DoctorProfileProfession
    },
    {
      label: 'Special Interest Areas',
      value:
        clinic?.services?.practice
          ?.flatMap(category =>
            category.practiceSpecialties.map(subCategory => subCategory.name)
          )
          ?.join(', ') || '--',
      icon: DoctorProfileSpeciality
    },
    {
      label: 'Funding options',
      value: clinic?.clinicDetails?.fundingOptions?.join(', ') || '--',
      icon: DoctorFunding
    },
    {
      label: 'Service delivery',
      value: clinic?.clinicDetails?.serviceDelivery?.join(', ') || '--',
      icon: DoctorServiceDelivery
    },
    {
      label: 'Age Group',
      value: clinic?.clinicDetails?.ageGroup?.join(', ') || '--',
      icon: DoctorProfileAgeGroup
    }
    // {
    //   label: 'Website',
    //   value: isLogin ? clinic?.clinicDetails?.website : 'Login to View' || '--',
    //   icon: websiteIcon
    // }
  ].filter(obj => obj !== null);
};
