import Header from '../../components/header/Header';
import React, { useState } from 'react';
import Footer from '../../components/footer/Footer';
import ButtonGreen from '../../components/UI/Button/ButtonGreen';
import { useNavigate } from 'react-router-dom';
import { OurMissionAbout } from '../../assets/vectors';
import './ourMission.scss';
import { Col, ConfigProvider, Row, Spin } from 'antd';
import { BASE_URL } from '../../constants';
import {
  setFilterValues,
  setSearchedTherapists
} from '../../Redux/Reducers/searchedTherapistsSlice';
import { useDispatch } from 'react-redux';
import { sortTherapists } from '../../helpers/common';
import { postRequest } from '../../api/apiCalls';
import { useMediaQuery } from 'react-responsive';
function OurMission() {
  const baseURL = window.location.origin;
  const isMobile = useMediaQuery({ query: '(max-width: 580px)' });

  const showFooter = true;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);
  const searchHandler = async () => {
    dispatch(
      setFilterValues({
        location: '',
        lat: '',
        lng: '',
        name: '',
        category: {},
        subCategory: {}
      })
    );
    setisLoading(true);
    const dataToSend = {
      categories: '',
      subCategories: [],
      lat: '',
      lon: '',
      therapistName: ''
    };

    const onSuccess = res => {
      const sortedTherapists = sortTherapists(res?.data);

      dispatch(setSearchedTherapists(sortedTherapists || []));
      dispatch(
        setFilterValues({
          location: '',
          lat: '',
          lng: '',
          name: '',
          category: {},
          subCategory: {}
        })
      );
      navigate('/search-therapist/?isHomeSearched=true');
      setisLoading(false);
    };
    const onError = err => {
      setisLoading(false);
    };
    await postRequest(
      dataToSend,
      `customer/get-search`,
      true,
      true,
      onSuccess,
      onError
    );
  };
  return (
    <>
      <Header />
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#809848'
          }
        }}
      >
        <Spin spinning={isLoading}>
          <div className='our-mission-container'>
            <Row
              gutter={[20, 20]}
              style={{ flexDirection: isMobile ? 'column-reverse' : 'initial' }}
            >
              <Col span={isMobile ? 24 : 24}>
                <div className='left-container-content'>
                  <h2>
                    Our mission is to support families to receive the right
                    support, at the right time
                  </h2>
                  <p>
                    I’m Charlotte, an Occupational Therapist and the founder of
                    <span className='green'> Therapy Match</span>.
                  </p>
                  <p>
                    Throughout my career, I’ve cultivated a deep understanding
                    of the challenges faced by families as they seek therapeutic
                    support for their children.
                  </p>
                  <p>
                    I’ve also grown to understand the complexities of working as
                    a therapist across homes, practices, corporations and
                    schools. Driven by a passion for bridging these gaps, I
                    recently launched Therapy Match— a platform designed to
                    address critical issues for both families and providers.{' '}
                  </p>
                  <h4>For Families </h4>
                  <ul>
                    <li>
                      Find a therapist whose skillset aligns with your family’s
                      values and child’s goals
                    </li>
                    <li>Find a therapist with real-time availability</li>
                    <li>
                      Choose from: face-to-face appointment at a clinic,
                      face-to-face appointment home-visit or Telehealth
                      appointment{' '}
                    </li>{' '}
                    <li>
                      View and reserve appointments directly online (stop
                      calling around!)
                    </li>{' '}
                    <li>
                      Join waitlists for preferred providers, easily online
                    </li>{' '}
                    <li>
                      Transparency regarding therapists’ expertise, location,
                      fees, and services
                    </li>{' '}
                    <li>Marketplace for paediatric therapists</li>{' '}
                  </ul>
                  <h4>For Therapists </h4>
                  <ul>
                    <li>
                      Receive new clients as they reserve your available
                      appointments
                    </li>
                    <li>
                      The ability to confirm or decline appointments reserved by
                      families
                    </li>{' '}
                    <li>
                      Once you receive a client, they are yours (no need to keep
                      booking through the platform!)
                    </li>{' '}
                    <li>
                      Offer face-to-face sessions, clinic appointments, home
                      visits and/or telehealth services
                    </li>{' '}
                    <li>Flexible calendar to update your availability</li>{' '}
                    <li>Marketing and prompting of skills and expertise</li>
                    <li>
                      Reduce the administrative burden of managing extensive
                      waitlists, and cancellations
                    </li>
                  </ul>
                  <p>
                    We value and welcome the feedback of families and
                    therapists. We are committed to improvement, to ensure that
                    we grow with our community. Please reach out to{' '}
                    <span className='green'>
                      <a
                        className='green'
                        href='mailto:hello@therapymatch.com.au'
                      >
                        hello@therapymatch.com.au
                      </a>
                    </span>
                  </p>
                  <div className='green-button-container'>
                    <ButtonGreen
                      className={'findTherapist'}
                      text={'Find a Therapist'}
                      onClick={() => searchHandler()}
                    />

                    {/* <a
                      className='userJoinDropdown'
                      href={`${baseURL}/therapist`}
                    >
                      <ButtonGreen
                        className={'provideTherapist'}
                        text={'Become a Therapist'}
                      />
                    </a> */}
                  </div>
                </div>
              </Col>
              {/* <Col span={isMobile ? 24 : 12} className='image-container'>
                <div>
                  <img src={OurMissionAbout} alt='' />
                </div>
              </Col> */}
            </Row>
          </div>
        </Spin>
      </ConfigProvider>
      {showFooter && <Footer color={true} />}
    </>
  );
}

export default OurMission;
