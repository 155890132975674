import React from 'react';
import './search-category.scss';
import { ArrowRightGreen, Chevron } from '../../assets/vectors';
import { searchCards } from './constant';
import { useNavigate } from 'react-router-dom';
import {
  setFilterValues,
  setSearchedTherapists
} from '../../Redux/Reducers/searchedTherapistsSlice';
import { useDispatch } from 'react-redux';
import { postRequest } from '../../api/apiCalls';
import { sortTherapists } from '../../helpers/common';

const SearchCategory = ({ setisLoading, categories }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFinish = async (categorySelected, isTypeSearch, type) => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    });
    dispatch(
      setFilterValues({
        location: '',
        lat: '',
        lng: '',
        name: '',
        category: {},
        subCategory: {}
      })
    );
    setisLoading(true);
    const dataToSend = {
      categories: categorySelected?.value || '',
      subCategories: [],
      lat: '',
      lon: '',
      therapistName: '',
      serviceDelivery: isTypeSearch ? type?.value : ''
    };

    const onSuccess = res => {
      const sortedTherapists = sortTherapists(res?.data);
      dispatch(setSearchedTherapists(sortedTherapists || []));
      dispatch(
        setFilterValues({
          location: '',
          lat: '',
          lng: '',
          name: '',
          category: categorySelected || {},
          subCategory: {}
        })
      );
      if (isTypeSearch) {
        navigate(`/search-therapist/?filterType=${type?.value}`);
      } else {
        navigate('/search-therapist/?isHomeSearched=true');
      }

      setisLoading(false);
    };
    const onError = err => {
      setisLoading(false);
    };
    await postRequest(
      dataToSend,
      `customer/get-search`,
      true,
      true,
      onSuccess,
      onError
    );
  };

  return (
    <>
      <div className='search_category'>
        {/* <h1>Specialities</h1> */}
        <div className='category_content' style={{ marginTop: '40px' }}>
          <div className='boxes'>
            {categories?.slice(0, 9)?.map((el, ind) => (
              <div
                key={ind}
                className='box'
                onClick={() => {
                  onFinish({
                    label: el?.categoryName,
                    value: el?._id
                  });
                }}
              >
                <div className='box_left'>
                  <img src={el?.icon} alt='' style={{ objectFit: 'contain' }} />
                  <h4> {el?.categoryName} </h4>
                </div>
                <img src={Chevron} alt='' />
              </div>
            ))}
          </div>
          {9 < categories?.length && (
            <div
              className='see-more'
              // onClick={handleSeeMore}
              onClick={() => navigate('/all-categories')}
            >
              <p> See More </p>
              <img src={ArrowRightGreen} alt='' />
            </div>
          )}
        </div>
        {/* cards */}
        <div className='cards'>
          {searchCards?.map(el => (
            <div className='card' style={{ minHeight: '200px' }}>
              <div className='left' style={el.imgColor}>
                <img src={el?.img} alt='' />
              </div>
              <div className='right'>
                <h2> {el.title} </h2>
                {el.title2 ? <h2 className='subHead'> {el.title2} </h2> : null}

                <p style={el.style}> {el.desc} </p>
                <div className='buttons_category'>
                  <button
                    className='attend-clinic-btn'
                    style={el.buttonConsultColor}
                    onClick={
                      () =>
                        onFinish(null, true, {
                          label: el?.searchValue,
                          value: el?.searchValue
                        })
                      // navigate(
                      //   `/search-therapist/?filterType=${el?.searchValue}`
                      // )
                    }
                  >
                    {el.buttonConsult}
                  </button>
                  <button
                    style={el.buttonHomeBasedColor}
                    onClick={
                      () =>
                        onFinish(null, true, {
                          label: el?.searchValue,
                          value: el?.searchValue
                        })
                      // navigate(
                      //   `/search-therapist/?filterType=${el?.searchValue}`
                      // )
                    }
                  >
                    {el.buttonHomeBased}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SearchCategory;
