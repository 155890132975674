import React, { useEffect, useState } from 'react';
import './footer.scss';
import { Footer_logo_Dark } from '../../assets/vectors';
import { useNavigate } from 'react-router-dom';
import { getRequest } from '../../api/apiCalls';
import { setFilterValues } from '../../Redux/Reducers/searchedTherapistsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';

const Footer = ({ color }) => {
  const baseURL = window.location.origin;
  const token = useSelector(state => state.authReducer.token);

  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getCategoriesHandler = async () => {
    const onSuccess = res => {
      setCategories(res?.data);
    };
    const onError = () => {};
    await getRequest(
      null,
      'category/get-category',
      true,
      false,
      onSuccess,
      onError
    );
  };
  // const color = true;
  useEffect(() => {
    getCategoriesHandler();
  }, []);

  return (
    <>
      <div className={`footer footer_dark`}>
        <Row>
          <Col lg={6} sm={24} xs={24} className='patientsFooterLinks'>
            <img style={{ maxWidth: '70%' }} src={Footer_logo_Dark} alt='' />
          </Col>
          <Col lg={6} sm={8} xs={24} className='patientsFooterLinks'>
            <h3>For Patients</h3>
            {categories?.slice(0, 10)?.map((el, ind) => (
              <div
                key={ind}
                className='box'
                onClick={() => {
                  const category = {
                    label: el?.categoryName,
                    value: el._id
                  };
                  dispatch(
                    setFilterValues({
                      location: '',
                      lat: '',
                      lng: '',
                      name: '',
                      category: category || {}
                    })
                  );
                  navigate(`./search-therapist/?category=${el?._id}`);
                }}
              >
                <h4>Find a {el?.categoryName} </h4>
              </div>
            ))}
            <h4 onClick={() => navigate(token ? '/account' : '/login')}>
              My Account
            </h4>

            {/* <h4 onClick={() => navigate('/help-center')}> Help Centre </h4> */}
          </Col>
          <Col lg={6} sm={8} xs={24} className='patientsFooterLinks'>
            <h3>For Practitioners</h3>
            <h4>
              <a href={`${baseURL}/therapist`}>Join as Provider</a>
            </h4>

            <h4>
              <a href={`${baseURL}/clinic`}>Join as Clinic</a>
            </h4>
          </Col>
          <Col lg={6} sm={8} xs={24} className='patientsFooterLinks'>
            <h3>Therapy Match</h3>
            {/* <h4 onClick={() => navigate('/about-us')}> About us</h4> */}
            <h4 onClick={() => navigate('/our-mission')}> Our Mission</h4>
            <h4 onClick={() => navigate('/contact-us')}> Contact us</h4>
            <h4 onClick={() => navigate('/terms-and-conditions')}>
              Terms and Conditions of Use
            </h4>
            <h4 onClick={() => navigate('/privacy-policy')}> Privacy Policy</h4>
          </Col>
        </Row>
        <Row className='bottomText'>
          <p> ©2024 Therapist Match, LLC. All rights reserved.</p>
        </Row>
      </div>
    </>
  );
};

export default Footer;
