import React, { useEffect, useRef, useState } from 'react';
import { ConfigProvider, Form, Input, List, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import mapboxgl from 'mapbox-gl';
import { CategoryGreen, category as categoryIcon } from '../../assets/vectors';
import { useLocation, useNavigate } from 'react-router-dom';

import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import AntdInputAutocomplete from '../../helpers/LocationInput';
import { getRequest, postRequest } from '../../api/apiCalls';
import {
  setFilterValues,
  setSearchedTherapists
} from '../../Redux/Reducers/searchedTherapistsSlice';
import { ViewTherapists } from './ViewTherapists';
import { SearchWaitlistForm } from './SearchWaitlistForm';
import { SearchTherapistSubFilters } from './SearchTherapistSubFilters';

import './searchTherapist.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useCallback } from 'react';
import { SearchBarFieldIcon } from '../../assets/images';
import { sortTherapists } from '../../helpers/common';

const SearchTherapist = () => {
  // -------------------------------------Form & Refs-------------------------------------//
  const [form] = Form.useForm();
  const listRef = useRef(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // -------------------------------------Form & Refs-------------------------------------//

  // -------------------------------------Global States-------------------------------------//
  const showFooter = true;
  const access_token =
    'pk.eyJ1IjoiaW5hYW0xIiwiYSI6ImNramtmNmljYzJhMWMycnFwM28zOHE4ZzIifQ.pBIP97q3Us332iKImTP9aQ';
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get('category');
  const filterType = queryParams.get('filterType');

  const latVal = Form.useWatch('lat', form);
  const lngVal = Form.useWatch('lng', form);
  const searchedData = useSelector(
    state => state.searchedTherapists.therapists
  );
  const searchedParems = useSelector(state => state.searchedTherapists);
  // -------------------------------------Global States-------------------------------------//
  // -------------------------------------States-------------------------------------//
  const [openModal, setOpenModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState(searchedData || []);
  const [filteredData, setFilteredData] = useState(searchedData || []);
  const [isLoading, setisLoading] = useState(false);
  const [therapistName, setTherapistName] = useState(
    searchedParems?.name ? searchedParems?.name : ''
  );
  const [categorySelected, setCategorySelected] = useState(
    searchedParems?.category ? searchedParems?.category : null
  );
  const [isFieldLoading, setisFieldLoading] = useState(''); // State for field loading indicator
  const [practiceOptions, setPracticeOptions] = useState([]); // State for  practice options
  const [subPracticeOptions, setSubPracticeOptions] = useState([]);
  const [subCatSelected, setSubCatSelected] = useState(
    searchedParems?.subCategory ? searchedParems?.subCategory : null
  ); // State for selected category

  const [isCategoriesOpen, setIsCategoriesOpen] = useState('');
  const [filteredPracticeOptions, setFilteredPracticeOptions] = useState([]);
  const [waitlistActiveThearapist, setWaitlistActiveThearapist] = useState('');

  const [filteredSubPracticeOptions, setFilteredSubPracticeOptions] = useState(
    []
  );
  const [searchedTherapists, setSearchedTherapistsState] = useState([]); // State for searched therapists
  const [searchedClinics, setSearchedClinics] = useState([]); // State for searched clinics
  const [categories, setCategories] = useState(null);

  // -------------------------------------States-------------------------------------//

  // -------------------------------------Filters States-------------------------------------//
  const [subFilters, setSubFilters] = useState(null);
  // -------------------------------------Filters States-------------------------------------//

  const getCategories = async () => {
    const onSuccess = res => {
      setCategories(res.data);
      const practiceOptions = res.data
        ?.map(data => {
          if (data?.categoryName) {
            return {
              label: data.categoryName,
              value: data._id,
              type: 'Speciality',
              image: data.icon
            };
          }
          return null;
        })
        .filter(Boolean);

      const subSpecialitiesArray = res.data
        ?.flatMap(item =>
          item?.subCategories?.map(subItem => {
            if (subItem?.name) {
              return {
                label: subItem.name,
                value: subItem._id,
                subHeading: item?.categoryName,
                type: 'Sub Speciality'
              };
            }
            return null;
          })
        )
        .filter(Boolean);

      setPracticeOptions(practiceOptions);
      setSubPracticeOptions(subSpecialitiesArray);
      setFilteredPracticeOptions(practiceOptions);
      setFilteredSubPracticeOptions(subSpecialitiesArray);
    };
    await getRequest(null, `category/get-category`, true, true, onSuccess);
  };
  const getTherapists = async dataToSend => {
    setisLoading(true);
    const onSuccess = res => {
      const sortedTherapists = sortTherapists(res?.data);
      setData(sortedTherapists || []);
      setCurrentPage(1);
      setFilteredData(sortedTherapists || []);
      dispatch(setSearchedTherapists(sortedTherapists || []));
      setisLoading(false);
      if (
        subFilters?.avaiability?.value &&
        subFilters?.avaiability?.value !== 'all'
      ) {
        filterAvailability(
          subFilters?.avaiability?.value || 'all',
          sortedTherapists
        );
      }
    };
    const onError = err => {
      setisLoading(false);
    };
    await postRequest(
      dataToSend,
      `customer/get-search`,
      true,
      true,
      onSuccess,
      onError
    );
  };
  //--------------- Map Configuration --------------- //
  const pinCoordinates = data
    .filter(
      item =>
        item?.location?.coordinates[0] !== 0 &&
        item?.location?.coordinates[1] !== 0
    )
    .map(item => ({
      _id: item?._id,
      lng: item?.location?.coordinates[0],
      lat: item?.location?.coordinates[1],
      type: item?.type === 'therapist' ? 'Provider' : 'Clinic',
      name:
        item.type === 'therapist'
          ? item?.personalInfo?.firstName + ' ' + item?.personalInfo?.lastName
          : item?.clinicDetails?.clinicName,
      email:
        item.type === 'therapist'
          ? item?.personalInfo?.email
          : item?.representativeDetails?.representativeEmail,
      address:
        item.type === 'therapist'
          ? item?.personalInfo?.bussinessAddress
          : item?.representativeDetails?.clinicAddress
    }));
  console.log(pinCoordinates);
  //--------------- Map Configuration --------------- //
  const filterPracticeOptions = inputValue => {
    if (inputValue) {
      const filteredOptions = practiceOptions?.filter(option => {
        return option?.label.toLowerCase().includes(inputValue.toLowerCase());
      });
      const filteredSubOptions = subPracticeOptions?.filter(option => {
        return option?.label.toLowerCase().includes(inputValue.toLowerCase());
      });
      setFilteredSubPracticeOptions(filteredSubOptions || []);
      setFilteredPracticeOptions(filteredOptions || []);
    } else {
      setFilteredSubPracticeOptions(subPracticeOptions);
      setFilteredPracticeOptions(practiceOptions);
    }
  };
  const filteredCategory = categorySelected
    ? categories?.find(data => data._id == categorySelected?.value)
    : null;
  const getSearchedTherapists = async nameInput => {
    setisFieldLoading(true);
    const dataToSend = {
      name: nameInput || ''
    };
    const onSuccess = res => {
      const therapistsOptions = res?.data?.therapists?.map(data => ({
        label: `${data?.personalInfo?.firstName} ${data?.personalInfo?.lastName}`,
        value: data?._id,
        type: 'Provider',
        image: data?.personalInfo?.profileImg || SearchBarFieldIcon
      }));
      const clinicOptions = res?.data?.clinics?.map(data => ({
        label: `${data?.clinicDetails?.clinicName}`,
        value: data?._id,
        type: 'Clinic',
        image: data?.clinicDetails?.clinicLogo || SearchBarFieldIcon
      }));
      setSearchedTherapistsState(therapistsOptions);
      setSearchedClinics(clinicOptions);
      setisFieldLoading(false);
    };
    const onError = err => {
      setisFieldLoading(false);
    };
    await postRequest(
      dataToSend,
      `customer/get-search-by-name`,
      true,
      true,
      onSuccess,
      onError
    );
  };
  const inputValueChangeHandler = val => {
    setTherapistName(val);
    filterPracticeOptions(val);
    setCategorySelected(null);
    setSubCatSelected(null);

    if (val.length >= 3) {
      getSearchedTherapists(val);
    } else {
      setSearchedTherapistsState([]);
      setSearchedClinics([]);
    }
  };
  const filterAvailability = (val, data) => {
    if (!val || val === 'all') {
      setFilteredData(data);
      return;
    }

    const today = dayjs()?.startOf('day'); // Start of today (without time)
    let filtered = [];

    if (val === 'today') {
      filtered = data?.filter(item =>
        item?.schedules?.some(schedule =>
          dayjs(schedule?.start)?.startOf('day')?.isSame(today)
        )
      );
    } else if (val === 'tomorrow') {
      const tomorrow = today?.add(1, 'day')?.startOf('day'); // Start of tomorrow (without time)
      filtered = data?.filter(item =>
        item?.schedules?.some(schedule =>
          dayjs(schedule?.start).startOf('day').isSame(tomorrow)
        )
      );
    } else if (val === '7days') {
      const next7Days = [];
      for (let i = 1; i <= 7; i++) {
        next7Days?.push(today?.add(i, 'day')?.startOf('day')); // Start of next 7 days (without time)
      }

      filtered = data?.filter(item =>
        item?.schedules?.some(schedule =>
          next7Days?.some(day =>
            day?.isSame(dayjs(schedule?.start)?.startOf('day'))
          )
        )
      );
    }

    setFilteredData(filtered);
  };
  const searchHandler = async category => {
    const values = form.getFieldsValue();
    dispatch(
      setFilterValues({
        location: '',
        lat: '',
        lng: '',
        name: '',
        category: {},
        subCategory: {}
      })
    );

    const dataToSend = {
      categories:
        category?.type === 'Speciality'
          ? category?.value
          : categorySelected
          ? categorySelected?.value
          : '',
      subCategories:
        category?.type === 'Sub Speciality'
          ? [category?.value].filter(Boolean)
          : [],
      lat: values?.lat || '',
      lon: values?.lng || '',
      therapistName:
        category?.type !== 'name' && category?.value ? '' : therapistName
    };
    // if (category?.type !== 'name' && category?.value) {
    //   setTherapistName('');
    // }
    dispatch(
      setFilterValues({
        location: values?.providerLocationName || '',
        lat: values?.lat || '',
        lng: values?.lng || '',
        name: therapistName,
        category: category?.type === 'Speciality' ? category : {},
        subCategory: category?.type === 'Sub Speciality' ? category : {}
      })
    );
    setSubFilters(null);
    getTherapists(dataToSend);
  };
  const handleSubFilters = useCallback(() => {
    const values = form.getFieldValue();
    const dataToSend = {
      categories: categorySelected?.value || '',
      subCategories: [subCatSelected?.value].filter(Boolean) || [],
      lat: values?.lat || '',
      lon: values?.lng || '',
      therapistName: therapistName || '',
      radius: subFilters?.radius?.value,
      ageGroup: subFilters?.ageGroup?.value,
      frequency: subFilters?.frequency?.value,
      serviceDelivery: subFilters?.serviceDelivery?.value
    };
    getTherapists(dataToSend);
  }, [subFilters]);
  const resetForm = () => {
    setSubFilters(null);
    // setTherapistName('');
    // setCategorySelected(null);
    setSubCatSelected(null);
    filterPracticeOptions('');
    form.resetFields();
  };
  useEffect(() => {
    getCategories();
    const closeList = event => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setIsCategoriesOpen(false);
      }
    };
    document.addEventListener('click', closeList);
    return () => {
      document.removeEventListener('click', closeList);
    };
  }, []);
  useEffect(() => {
    if (subFilters) {
      handleSubFilters();
    }
  }, [subFilters]);
  useEffect(() => {
    const getFilteredDataHandler = async () => {
      setisLoading(true);

      const onSuccess = res => {
        const sortedTherapists = sortTherapists(res?.data);

        setData(sortedTherapists || []);
        setFilteredData(sortedTherapists || []);
        setisLoading(false);
      };
      const onError = () => {
        setisLoading(false);
      };
      await getRequest(
        null,
        `category/search-doctors/${category}`,
        true,
        true,
        onSuccess,
        onError
      );
    };
    if (category) {
      getFilteredDataHandler();
    }
  }, [category]);
  useEffect(() => {
    mapboxgl.accessToken = access_token;
    if (!isLoading && filteredData?.length) {
      const map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [133.7751, -25.2744],
        zoom: 3
      });

      if (pinCoordinates?.length) {
        pinCoordinates.forEach(coord => {
          if (!isNaN(coord?.lng) && !isNaN(coord?.lat)) {
            const popupContent = `
              <div style="max-width: 400px;text-align:left">
                <h3>${coord?.name}</h3>
                <p><strong>${coord?.type}</strong></p>
                <p><strong>Address:</strong> ${coord?.address}</p>
                <div style="display: flex; justify-content:center;  margin-top:10px">
                  <button id="details-button-${coord?._id}" style="cursor:pointer;padding:10px 20px; border-radius: 34px;background-color: #809848;color: white;border:none">Details</button>
                </div>
              </div>`;

            const popup = new mapboxgl.Popup({
              closeButton: true,
              closeOnClick: true
            }).setHTML(popupContent);

            popup.on('open', () => {
              const button = document.getElementById(
                `details-button-${coord?._id}`
              );
              if (button) {
                button.addEventListener('click', () => {
                  if (coord?.type === 'Clinic') {
                    navigate(`/clinic-information/${coord?._id}`);
                  } else {
                    navigate(`/doctor-information/${coord?._id}`);
                  }
                });
              }
            });

            new mapboxgl.Marker()
              .setLngLat([coord?.lng, coord?.lat])
              .setPopup(popup)
              .addTo(map);
          }
        });
      }

      const bounds = new mapboxgl.LngLatBounds();
      const coordinates = pinCoordinates
        ?.filter(
          coord => coord && coord.lng !== undefined && coord.lat !== undefined
        )
        .map(coord => new mapboxgl.LngLat(coord.lng, coord.lat));

      if (coordinates?.length) {
        coordinates.forEach(coord => {
          bounds.extend(coord);
        });

        // Extend bounds to include the extra pin
        if (latVal && lngVal) {
          const extraPinCoord = [lngVal, latVal];
          new mapboxgl.Marker({ color: 'red' })
            .setLngLat(extraPinCoord)
            .addTo(map);
          bounds.extend(extraPinCoord);
        }

        map.fitBounds(bounds, {
          padding: 50,
          maxZoom: 12,
          bearing: 0
        });
      }

      return () => {
        map.remove();
      };
    }
  }, [isLoading, filteredData]);
  return (
    <>
      <Header />
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#809848'
          }
        }}
      >
        <Spin spinning={isLoading}>
          <div className='search__therapist'>
            <div className='search__therapist__bg'>
              <div className='search_hero__content'>
                <Form
                  name='basic'
                  layout='vertical'
                  form={form}
                  autoComplete='off'
                  className='searchTherapistForm '
                >
                  <div className='homeSearch'>
                    <div className='simpleSearch'>
                      <img
                        style={{ width: '20px', marginLeft: '15px' }}
                        src={CategoryGreen}
                        alt=''
                        className='category'
                      />
                      <div className='listWrapper' ref={listRef}>
                        <Input
                          className='therapistInput'
                          placeholder='Speciality or Provider Name'
                          onClick={() => {
                            setIsCategoriesOpen(true);
                          }}
                          onFocus={() => {
                            setIsCategoriesOpen(true);
                          }}
                          onChange={e => {
                            inputValueChangeHandler(e.target.value);
                          }}
                          suffix={<Spin spinning={isFieldLoading} />}
                          value={
                            categorySelected?.label
                              ? categorySelected?.label
                              : subCatSelected?.label
                              ? subCatSelected?.label
                              : therapistName
                          }
                        />
                        {isCategoriesOpen ? (
                          <>
                            <List
                              size='small'
                              bordered
                              className='customSearchList'
                              dataSource={[
                                therapistName && {
                                  label: `Search for ${therapistName}`,
                                  value: 'name',
                                  type: 'name'
                                },
                                ...(filteredPracticeOptions.length > 0
                                  ? [...filteredPracticeOptions]
                                  : []),
                                ...(filteredSubPracticeOptions.length > 0
                                  ? [
                                      {
                                        label: `Sub Specialities`,
                                        value: null,
                                        type: 'heading'
                                      },
                                      ...filteredSubPracticeOptions
                                    ]
                                  : []),
                                ...(searchedTherapists.length > 0
                                  ? [
                                      {
                                        label: `Providers`,
                                        value: null,
                                        type: 'heading'
                                      },
                                      ...searchedTherapists
                                    ]
                                  : []),
                                ...(searchedClinics.length > 0
                                  ? [
                                      {
                                        label: `Clinics`,
                                        value: null,
                                        type: 'heading'
                                      },
                                      ...searchedClinics
                                    ]
                                  : [])
                              ]}
                              renderItem={item =>
                                item ? (
                                  <List.Item
                                    className={
                                      item?.value
                                        ? 'customSearchListItem'
                                        : 'customSearchListItemHead'
                                    }
                                    onClick={() => {
                                      setIsCategoriesOpen(false);
                                      if (
                                        item?.value &&
                                        item?.type === 'Speciality'
                                      ) {
                                        setTherapistName('');
                                        setCategorySelected(item);
                                        searchHandler(item);
                                      } else if (
                                        item?.value &&
                                        item?.type === 'Sub Speciality'
                                      ) {
                                        setTherapistName('');
                                        setSubCatSelected(item);
                                        searchHandler(item);
                                      } else if (
                                        item?.value &&
                                        item?.type === 'name'
                                      ) {
                                        setSubCatSelected(null);
                                        setCategorySelected(null);
                                        searchHandler(item);
                                      } else if (
                                        item?.value &&
                                        item?.type === 'Provider'
                                      ) {
                                        setTherapistName('');

                                        navigate(
                                          `/doctor-information/${item?.value}`
                                        );
                                      } else if (
                                        item?.value &&
                                        item?.type === 'Clinic'
                                      ) {
                                        setTherapistName('');

                                        navigate(
                                          `/clinic-information/${item?.value}`
                                        );
                                      }
                                    }}
                                  >
                                    <div className='listNames'>
                                      {item?.type !== 'heading' ? (
                                        <img
                                          src={
                                            item?.image || SearchBarFieldIcon
                                          }
                                          alt='$$$'
                                        />
                                      ) : null}
                                      <div>
                                        <p>{item?.label}</p>
                                        <p
                                          style={{
                                            color: 'gray',
                                            fontSize: '10px'
                                          }}
                                        >
                                          {item?.subHeading}
                                        </p>
                                      </div>
                                    </div>
                                  </List.Item>
                                ) : null
                              }
                            />
                          </>
                        ) : null}
                      </div>
                      <AntdInputAutocomplete
                        name='providerLocationName'
                        placeholder='Location or postcode'
                        form={form}
                        defaultVal={searchedParems?.location}
                        defaultLat={searchedParems?.lat}
                        defaultLng={searchedParems?.lng}
                        onChangedValues={() => searchHandler(categorySelected)}
                        greenIcon
                      />
                    </div>
                  </div>
                </Form>
              </div>
              <SearchTherapistSubFilters
                setSubFilters={setSubFilters}
                subFilters={subFilters}
                filteredCategory={filteredCategory}
                latVal={latVal}
                getTherapists={getTherapists}
                filterType={filterType}
                resetForm={resetForm}
              />
            </div>

            <ViewTherapists
              filteredData={filteredData}
              setOpenModal={setOpenModal}
              setWaitlistActiveThearapist={setWaitlistActiveThearapist}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </Spin>
      </ConfigProvider>
      {showFooter && <Footer className='footer_color_change' color={true} />}
      <SearchWaitlistForm
        setOpenModal={setOpenModal}
        openModal={openModal}
        waitlistActiveThearapist={waitlistActiveThearapist}
      />
    </>
  );
};

export default SearchTherapist;
