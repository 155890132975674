import React, { useEffect, useState } from 'react';
import './account.scss';
import { Form, message } from 'antd';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import { UploadIcon } from '../../assets/images';
import Button from '../UI/Button/Button';
import AntdDatePicker from '../UI/DatePicker';
import AntdInput from '../UI/Input';
import Select from '../UI/Select';
import { disabledFuturesDates } from '../../helpers/dates';
import { getRequest, putRequest } from '../../api/apiCalls';
import axios from 'axios';
import { BASE_URL } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import SpinLoader from '../UI/Loader';
import { EditOutlined } from '@ant-design/icons';
import { setProfileData } from '../../Redux/Reducers/profileSlice';
import { ImageCropper } from '../UI/ImageCropper';
import ButtonGreen from '../UI/Button/ButtonGreen';
import AntdPassword from '../UI/Password';

const MyAccount = () => {
  const dispatch = useDispatch();
  const [isUploading, setisUploading] = useState(false);
  const [profileData, setprofileData] = useState(null);

  const [relation, setrelation] = useState('');

  const profileImg = useSelector(
    state => state?.profile?.profileData?.profile_image
  );
  const [profileImage, setprofileImage] = useState(profileImg);
  const [isLoading, setisLoading] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const [form] = Form.useForm();
  const intialValue = {
    fullName: '',
    email: '',
    password: '',
    dob: ''
  };
  useEffect(() => {
    setprofileImage(profileImg);
  }, [profileImg]);
  const showFooter = true;
  const getAllData = async () => {
    setisLoading(true);
    const onSuccess = res => {
      setprofileData(res?.detail);
      setrelation(res?.detail?.relationship);
      setisLoading(false);
      dispatch(setProfileData(res?.detail));
    };
    const onError = res => {
      console.log(res, 'erorr res');
      setisLoading(false);
    };
    await getRequest(null, 'auth/me', true, true, onSuccess, onError);
  };
  useEffect(() => {
    getAllData();
  }, []);
  const handleFileChange = fileList => {
    if (fileList.length > 0) {
      const file = fileList[0];
      const maxSize = 5 * 1024 * 1024;
      if (file.size <= maxSize) {
        setisUploading(true);
        setisLoading(true);
        const formData = new FormData();
        formData.append('images', file);
        setprofileImage(URL.createObjectURL(file));
        axios
          .post(`${BASE_URL}/auth/upload_image_s3`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(response => {
            setImageURL(response?.data?.path[0]?.image_file_name);
            setisUploading(false);
            setisLoading(false);
          })
          .catch(error => {
            setprofileImage(profileImg);
            console.error('Upload error', error);
            message.error('Unable to upload image, please try again');
            setisUploading(false);
            setisLoading(false);
          });
      } else {
        message.error(
          'File is too large. Please select a file smaller than 5 MB.'
        );
        setisUploading(false);
        setisLoading(false);
      }
    } else {
      message.error('No files selected');
    }
  };

  useEffect(() => {
    const date = dayjs(profileData?.dob);
    form.setFieldsValue({
      fullName: profileData?.fullName,
      email: profileData?.userId?.email,
      dob: date,
      gender: profileData?.gender,
      relationship: profileData?.relationship,
      relationName: profileData?.relationName,
      phoneNumber: profileData?.phoneNumber
    });
  }, [profileData]);
  const onFinish = async val => {
    setisLoading(true);
    const dataToSend = {
      fullName: val.fullName,
      dob: val.dob,
      gender: val.gender,
      relationship: val.relationship || '',
      relationName: val.relationName,
      phoneNumber: val.phoneNumber
    };
    if (imageURL !== null) {
      dataToSend.profile_image = imageURL;
    }
    const onSuccess = res => {
      getAllData();
      setisLoading(false);
    };
    const onError = err => {
      setisLoading(false);
    };
    await putRequest(
      dataToSend,
      `customer/update_customer`,
      true,
      true,
      onSuccess,
      onError
    );
  };
  const handlePasswordChange = async values => {
    setisLoading(true);
    const dataToSend = {
      new_password: values.new_password,
      confirm_password: values.confirm_password,
      old_password: values.old_password
    };
    const onSuccess = res => {
      setisLoading(false);
    };
    const onError = err => {
      setisLoading(false);
    };
    await putRequest(
      dataToSend,
      `auth/change_password`,
      true,
      true,
      onSuccess,
      onError
    );
  };
  const userRelationData = {
    parent: (
      <>
        <Form.Item
          name='relationName'
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Parent name is required'
            }
          ]}
          label='Parent name'
        >
          <AntdInput placeholder='Enter parent name' />
        </Form.Item>
      </>
    ),
    guardian: (
      <>
        <Form.Item
          name='relationName'
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Guardian name is required'
            }
          ]}
          label='Guardian name'
        >
          <AntdInput placeholder='Enter guardian name' />
        </Form.Item>
      </>
    ),
    representative: (
      <>
        <Form.Item
          name='relationName'
          rules={[
            {
              required: true,
              whitespace: true,
              message: 'Representative name is required'
            }
          ]}
          label='Representative name'
        >
          <AntdInput placeholder='Enter representative name' />
        </Form.Item>
      </>
    )
  };
  return (
    <>
      <Header />
      {isLoading ? (
        <SpinLoader />
      ) : (
        <div className='account'>
          <div className='headbg'></div>
          <div className='head_account'>
            <div className='account-upload-img'>
              <ImageCropper handleFileChange={handleFileChange} />
              <label htmlFor='account-image'>
                <EditOutlined className='updateImageLogo' />
              </label>
              {profileImage ? (
                <img src={profileImage} />
              ) : (
                <>
                  <UploadIcon />
                  <p>Change Image</p>
                </>
              )}
            </div>
            <h2 className='accountname'> {profileData?.fullName} </h2>
          </div>
          <div className='accountForm'>
            <Form
              name='basic'
              layout='vertical'
              style={{ width: '100%' }}
              form={form}
              initialValues={intialValue}
              onFinish={onFinish}
              autoComplete='off'
            >
              <Form.Item
                name='fullName'
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Patient/child name is Required'
                  }
                ]}
                label='Patient/child name'
              >
                <AntdInput placeholder='Enter your patient/child name' />
              </Form.Item>

              <Form.Item
                name={'dob'}
                rules={[
                  {
                    required: true,
                    message: 'Patient DOB is Required'
                  }
                ]}
                label="Patient's Date of birth"
              >
                <AntdDatePicker
                  disabledDate={disabledFuturesDates}
                  required
                  placeholder="Enter patient's date of birth"
                />
              </Form.Item>

              <Form.Item
                name='email'
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Reachable email address is Required'
                  },
                  {
                    pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                    message: 'Please enter a valid email!'
                  }
                ]}
                label='Reachable email address'
              >
                <AntdInput
                  placeholder='Email Address'
                  type='email'
                  required
                  disabled
                />
              </Form.Item>
              <Form.Item
                name='gender'
                rules={[
                  {
                    required: true,
                    message: 'patient/child gender is required'
                  }
                ]}
                label='Patient/child gender'
              >
                <Select
                  placeholder='Select patient/child gender'
                  options={[
                    { label: 'Male', value: 'male' },
                    { label: 'Female', value: 'female' },
                    { label: 'Other', value: 'other' }
                  ]}
                />
              </Form.Item>
              <Form.Item
                name='relationship'
                rules={[
                  {
                    required: true,
                    message: 'Relationship to patient is required'
                  }
                ]}
                label='Relationship to patient'
              >
                <Select
                  placeholder='Select relationship to patient'
                  options={[
                    { label: 'Parent', value: 'parent' },
                    { label: 'Guardian', value: 'guardian' },
                    { label: 'Representative', value: 'representative' }
                  ]}
                  onChange={val => {
                    setrelation(val);
                  }}
                />
              </Form.Item>
              {userRelationData[relation]}
              <Form.Item
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Please enter phone number'
                  },
                  {
                    validator: (_, value) => {
                      const phoneNumber = value ? value.replace(/\D/g, '') : '';
                      if (phoneNumber?.length && phoneNumber?.length < 10) {
                        return Promise.reject(
                          'Please enter a valid phone number'
                        );
                      }
                      return Promise.resolve();
                    }
                  }
                ]}
                name={'phoneNumber'}
                label='Phone number'
              >
                <AntdInput type='number' placeholder='Enter phone number' />
              </Form.Item>
              <div className='btn-center-single login-button'>
                <ButtonGreen
                  disabled={isUploading}
                  text={'Update Profile'}
                  type='submit'
                />
              </div>
            </Form>
          </div>
          <div className='accountForm'>
            <Form
              name='basic'
              layout='vertical'
              style={{ width: '100%' }}
              onFinish={handlePasswordChange}
              autoComplete='off'
            >
              <Form.Item
                label='Old Password'
                name={'old_password'}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    validator: async (_rule, value) => {
                      if (!value) {
                        return Promise.reject('Password is Required');
                      }
                    }
                  }
                ]}
              >
                <AntdPassword placeholder='Choose a password' />
              </Form.Item>
              <Form.Item
                label='New Password'
                name={'new_password'}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    validator: async (_rule, value) => {
                      if (!value) {
                        return Promise.reject('Password is Required');
                      } else if (value.length < 6) {
                        return Promise.reject('Password must be 6 characters');
                      }
                    }
                  }
                ]}
              >
                <AntdPassword placeholder='Choose a password' />
              </Form.Item>
              <Form.Item
                label='Confirm Password'
                name={'confirm_password'}
                dependencies={['new_password']}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Please Confirm your password'
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.resolve();
                      }
                      if (getFieldValue('new_password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        'The new password that you entered does not match!'
                      );
                    }
                  })
                ]}
              >
                <AntdPassword placeholder='Confirm  password' />
              </Form.Item>

              <div className='btn-center-single login-button'>
                <ButtonGreen
                  disabled={isUploading}
                  text={'Update Password'}
                  type='submit'
                />
              </div>
            </Form>
          </div>
        </div>
      )}
      {showFooter && <Footer color={false} />}
    </>
  );
};

export default MyAccount;
