import React, { useEffect } from 'react';
import { Left_Btn, Right_Btn } from '../../assets/vectors';
import { useMediaQuery } from 'react-responsive';
import { SingleClinicCard } from './SingleClinicCard';
import { SingleDoctorCard } from './SingleDoctorCard';

export const ViewTherapists = ({
  filteredData,
  setOpenModal,
  setWaitlistActiveThearapist,
  currentPage,
  setCurrentPage
}) => {
  const totalPages = Math.ceil(filteredData?.length / 10);
  const isMobile = useMediaQuery({ query: '(max-width: 580px)' });
  const startIndex = (currentPage - 1) * 10;
  const endIndex = startIndex + 10;

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    });
  }, [currentPage]);
  return (
    <>
      <h3 className='therapist-count'>
        {filteredData?.length
          ? `${filteredData?.length}  ${
              filteredData?.length <= 1 ? 'result' : 'results'
            } found nearby`
          : `We are excited that you have found us! We are still growing. Please edit your filters if possible or check back in with us soon, as new providers are signing on!`}
      </h3>
      {isMobile ? (
        <div
          id='map'
          style={{
            width: '100%',
            height: '250px',
            marginBottom: '20px'
          }}
        />
      ) : null}
      <div className='searchTimeSlots'>
        <div className='timeSlotsCards'>
          <h3>
            {filteredData?.length
              ? `${filteredData?.length} ${
                  filteredData?.length <= 1 ? 'result' : 'results'
                } found nearby`
              : `We are excited that you have found us! We are still growing. Please edit your filters if possible or check back in with us soon, as new providers are signing on!`}
          </h3>
          {filteredData?.slice(startIndex, endIndex)?.map((data, ind) => {
            return (
              <>
                {data.type === 'therapist' ? (
                  <SingleDoctorCard
                    data={data}
                    setWaitlistActiveThearapist={setWaitlistActiveThearapist}
                    setOpenModal={setOpenModal}
                  />
                ) : (
                  <SingleClinicCard data={data} />
                )}
              </>
            );
          })}

          {filteredData?.length > 10 ? (
            <div className='buttons'>
              <img
                src={Left_Btn}
                onClick={() => {
                  if (currentPage > 1) setCurrentPage(currentPage - 1);
                }}
                alt=''
                className='butn'
              />
              <img
                src={Right_Btn}
                onClick={() => {
                  if (totalPages !== currentPage)
                    setCurrentPage(currentPage + 1);
                }}
                alt=''
                className='butn'
              />
            </div>
          ) : null}
        </div>
        <div
          id='map'
          style={{
            width: '35%',
            height: '400px',
            marginRight: '2rem',
            marginBottom: '40px',
            display: isMobile ? 'none' : 'block'
          }}
        />
      </div>
    </>
  );
};
