import React, { useRef, useState } from 'react';
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import AvatarEditor from 'react-avatar-editor';
import './ImageCropper.scss';
import Button from '../Button/Button';
import ButtonGreen from '../Button/ButtonGreen';
export const ImageCropper = ({ handleFileChange }) => {
  const editor = useRef(null);
  const [showImageEditModal, setShowImageEditModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageEditingData, setImageEditingData] = useState({
    scale: 1.2
  });

  const handleFileChangeAndOpenModal = files => {
    if (files && files.length > 0) {
      const file = files[0];
      setSelectedImage(file);
      setShowImageEditModal(true);
    }
  };

  const handleSubmitImage = async () => {
    const getImageBlob = async () => {
      const dataUrl = editor.current.getImage().toDataURL();
      const res = await fetch(dataUrl);
      const blob = await res.blob();
      return blob;
    };

    const editedImageBlob = await getImageBlob();
    setShowImageEditModal(false);
    handleFileChange([new File([editedImageBlob], 'editedImage.jpg')]);
  };

  return (
    <>
      <input
        type='file'
        accept='image/*'
        id='account-image'
        onChange={e => handleFileChangeAndOpenModal(e.target.files)}
      />

      <Modal
        visible={showImageEditModal}
        // onOk={handleSubmitImage}
        onCancel={() => {
          setSelectedImage(null);
          setShowImageEditModal(false);
        }}
        maskClosable={false}
        className='cropImageModal'
      >
        <div className='image-editor-modal-content-container'>
          <h4 className='heading-editor'>Edit and Upload</h4>
          <AvatarEditor
            ref={editor}
            image={selectedImage}
            width={300}
            height={300}
            border={50}
            // rotate={imageEditingData.rotate}
            scale={imageEditingData.scale}
            style={{
              width: '100%',
              borderRadius: '10px',
              objectFit: 'cover',
              backgroundColor: '#fff'
            }}
            color={[255, 255, 255, 0.7]} // RGBA
            backgroundColor='#fff'
          />
          <div className='image-editor-controls'>
            <button
              className='image-editor-button'
              onClick={() =>
                setImageEditingData(prev => ({
                  ...prev,
                  scale: prev?.scale + 0.2
                }))
              }
            >
              <ZoomInOutlined />
            </button>
            <button
              className='image-editor-button'
              onClick={() =>
                setImageEditingData(prev => ({
                  ...prev,
                  scale: prev?.scale - 0.2 >= 1 ? prev?.scale - 0.2 : 1
                }))
              }
            >
              <ZoomOutOutlined />
            </button>
          </div>
          <div className='imageEditActions'>
            <ButtonGreen onClick={handleSubmitImage} text={'Crop & Save'} />
          </div>
        </div>
      </Modal>
    </>
  );
};
