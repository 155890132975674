import { Carousel, Rate } from 'antd';
import React, { useRef } from 'react';
import { Left_Btn, Right_Btn } from '../../assets/vectors';
import { DP, Quotes } from '../../assets/images';
import './review.scss';
export const DummyReviews = () => {
  const carouselRef = useRef();

  const handleNext = () => {
    carouselRef.current.next();
  };
  const reviews = [
    {
      name: 'Jennifer H',
      rating: 5,
      reviewText:
        'Where has this website been?! I am so thankful to have finally found a site like this for my son. It was so easy to reserve a speech appointment, and the Speech Therapist called me to confirm it the next day. '
    },
    {
      name: 'Rosie M',
      rating: 5,
      reviewText:
        'My daughter received funding two weeks ago and we were told by lots of clinics that we wouldn’t be able to find anyone with availability to help us for months. Through this site we’ve already found a great OT and Speech therapist. '
    },
    {
      name: 'Zach S',
      rating: 5,
      reviewText:
        'We found the bio’s really great to read, to see who was the best fit to support us with our 5 year old. We found it really easy to sign up and use. If we need more support in the future, we’ll definitely use Therapy Match again.'
    }
  ];
  const handlePrev = () => {
    carouselRef.current.prev();
  };
  return (
    <div className='review__section'>
      <h1> Reviews </h1>
      <div style={{ minHeight: '200px', height: 'auto' }}>
        <Carousel
          slidesToShow={3}
          dots={false}
          ref={carouselRef}
          infinite
          autoplay={true}
          className='carousel__review'
          responsive={[
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2
              }
            },
            {
              breakpoint: 540,
              settings: {
                slidesToShow: 1
              }
            }
          ]}
        >
          {reviews &&
            reviews?.map((elem, ind) => {
              return (
                <div className='review_card_container_dummy'>
                  <div className='review_card_dummy'>
                    <div className='top'>
                      <div className='profilePic'>
                        <DP />
                      </div>
                      <div>
                        <div className='top_right'>
                          <h2>{elem?.name}</h2>
                        </div>
                        <Rate
                          value={elem?.rating}
                          disabled
                          style={{ textWrap: 'nowrap' }}
                          color={'#809848'}
                        />
                      </div>
                    </div>
                    <div className='bottom' style={{ padding: '0' }}>
                      <div className='bottom_left'>
                        <p style={{ textAlign: 'left', color: '#000000' }}>
                          {elem?.reviewText}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </Carousel>
        {reviews?.length > 3 ? (
          <div className='buttons'>
            <div onClick={handlePrev}>
              <img src={Left_Btn} alt='Previous' />
            </div>
            <div onClick={handleNext}>
              <img src={Right_Btn} alt='next' />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
