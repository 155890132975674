import { Col, DatePicker, Form, Modal, Row, TimePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import Footer from '../footer/Footer';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useNavigate, useParams } from 'react-router-dom';
import AntdDatePicker from '../UI/DatePicker';
import moment from 'moment';
import { postRequest } from '../../api/apiCalls';
import './TimeSlots.scss';
import { useDispatch, useSelector } from 'react-redux';
import { updateAppointmentField } from '../../Redux/Reducers/apponitmentSlice';
import SpinLoader from '../UI/Loader';
import { disabledPastDates } from '../../helpers/dates';
import { SearchWaitlistForm } from '../../pages/searchTherapist/SearchWaitlistForm';
import ButtonGreen from '../UI/Button/ButtonGreen';
const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);
const TimeSlots = ({ activeStep, setActiveStep }) => {
  const user_address = useSelector(state => state.appointment.appointment_type);
  const selected_slot = useSelector(state => state.appointment.selected_slot);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  dayjs.extend(customParseFormat);
  const { id } = useParams();
  const currentURL = window.location.href;

  const urlParams = new URLSearchParams(new URL(currentURL).search);

  const [selectedSlot, setselectedSlot] = useState(selected_slot?._id);
  const customDateParam = urlParams.get('date');

  const [date, setdate] = useState(
    customDateParam
      ? dayjs(customDateParam)
      : selected_slot
      ? dayjs(selected_slot?.start)
      : dayjs()
  );
  const [isLoading, setisLoading] = useState(false);
  const [errorMsg, seterrorMsg] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const appointment_type = useSelector(
    state => state.appointment.appointment_type
  );
  const [schedules, setSchedules] = useState([]);
  const frequencyArray = {
    ONE_OFF: 'One-off',
    WEEKLY: 'Weekly',
    FORTNIGHTLY: 'Fortnightly',
    MONTHLY: 'Monthly'
  };
  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    });
  }, []);

  const handleSlotChange = slot => {
    seterrorMsg(false);
    dispatch(
      updateAppointmentField({
        field: 'selected_slot',
        value: slot
      })
    );
    setselectedSlot(slot?._id);
  };
  const nextStepHandler = () => {
    if (!selected_slot) {
      seterrorMsg(true);
    } else {
      seterrorMsg(false);
      setActiveStep(activeStep + 1);
    }
  };
  useEffect(() => {
    const getCategoriesHandler = async () => {
      setisLoading(true);
      const onSuccess = res => {
        setisLoading(false);
        setSchedules(res?.data);
      };
      const onError = () => {
        setisLoading(false);
      };
      const dateFormatted = dayjs(date).millisecond(0).utc();
      const dataToSend = {
        time: dateFormatted,
        type: user_address
      };
      await postRequest(
        dataToSend,
        `therapist/get-schedule-user/${id}`,
        true,
        true,
        onSuccess,
        onError
      );
    };
    getCategoriesHandler();
  }, [date, id]);

  const onFinish = async values => {
    setisLoading(true);

    const startDate = dayjs(values?.startDate);
    const startTime = dayjs(values?.startTime);
    const endTime = dayjs(values?.endTime);
    const formattedStartDateTime = `${startDate.format(
      'YYYY-MM-DD'
    )}T${startTime.format('HH:mm:ss.SSSZ')}`;
    const formattedEndDateTime = `${startDate.format(
      'YYYY-MM-DD'
    )}T${endTime.format('HH:mm:ss.SSSZ')}`;

    const startDateFormatted = dayjs(formattedStartDateTime).toISOString();
    const endDateFormatted = dayjs(formattedEndDateTime).toISOString();

    const dataToSend = {
      therapistId: id,
      start: startDateFormatted,
      end: endDateFormatted,
      type: appointment_type,
      clientStart: formattedStartDateTime.toString(),
      clientEnd: formattedEndDateTime.toString()
    };
    const onSuccess = res => {
      setisLoading(false);
      navigate('/');
    };
    const onError = () => {
      setisLoading(false);
    };
    await postRequest(
      dataToSend,
      `customer/create-waitlist`,
      true,
      true,
      onSuccess,
      onError
    );
  };
  return (
    <>
      <div className='timeSlots'>
        <div className='table-slots table-slots-datepicker'>
          <AntdDatePicker
            placeholder='Select Date'
            onChange={value => {
              setdate(value);
            }}
            value={date}
            disabledDate={disabledPastDates}
          />
          {isLoading ? (
            <SpinLoader height='50vh' />
          ) : (
            <Row className='allSlots' gutter={10}>
              {schedules.length ? (
                schedules?.map((slot, ind) => {
                  const start = moment(slot?.Schedule?.start);
                  const startTime = start.format('hh:mm A');
                  const end = moment(slot?.Schedule?.end);
                  const endTime = end.format('hh:mm A');
                  return (
                    <Col md={8} sm={12} xs={24} key={ind}>
                      <button
                        className={`buttonTab ${
                          selectedSlot === slot?.Schedule?._id ? 'active' : ''
                        }`}
                        onClick={() => handleSlotChange(slot?.Schedule)}
                      >
                        {frequencyArray[slot.Schedule.scheduleFrequency] ? (
                          <>
                            {frequencyArray[slot.Schedule.scheduleFrequency]}{' '}
                            {' | '}
                          </>
                        ) : (
                          ''
                        )}
                        {startTime} - {endTime}
                      </button>
                    </Col>
                  );
                })
              ) : (
                <p style={{ textAlign: 'center', width: '100%' }}>
                  No Record Found
                </p>
              )}
            </Row>
          )}
        </div>
      </div>
      <div className='waitlistPopupButton'>
        <p onClick={() => setOpenModal(true)}>
          Couldn’t find suitable time? Join waitlist.
        </p>
      </div>
      <div className='timeSlotsBtn'>
        <p className='error_msg'>{errorMsg ? 'Please select slot.' : ''}</p>{' '}
        <ButtonGreen
          onClick={() => {
            setActiveStep(prev => prev - 1);
          }}
          text='Back'
          style={{ margin: '10px 10px' }}
          disabled={isLoading}
        />
        <ButtonGreen
          onClick={() => nextStepHandler()}
          text='Next'
          disabled={isLoading}
          style={{ margin: '10px 10px' }}
        />
        {/* <button className='stepper_btn' style={{ marginRight: '10px' }}>
          Back
        </button>
        <button className='stepper_btn' onClick={() => nextStepHandler()}>
          Next
        </button> */}
      </div>
      <Footer />
      <SearchWaitlistForm
        openModal={openModal}
        setOpenModal={setOpenModal}
        waitlistActiveThearapist={id}
        activeType={appointment_type}
      />
    </>
  );
};

export default TimeSlots;
