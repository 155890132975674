import { Dropdown, Slider } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import {
  AgeGroupIcon,
  CalendarRegular,
  DoctorServiceDeliveryBlack,
  FreqSearchIcon,
  HeadSideHeartRegular
} from '../../assets/vectors';
import { AimOutlined, CloseOutlined } from '@ant-design/icons';
import {
  ageGroupOptionsData,
  availabilityOptionsData,
  frequencyOptionsData,
  radiusOptionsData,
  serviceDeliveryData
} from './constant';
import { resetFilterValues } from '../../Redux/Reducers/searchedTherapistsSlice';
import { useDispatch } from 'react-redux';

export const SearchTherapistSubFilters = ({
  subFilters,
  setSubFilters,
  filteredCategory,
  latVal,
  getTherapists,
  resetForm,
  filterType
}) => {
  const radiusRef = useRef(null);
  const dispatch = useDispatch();

  const [radius, setRadius] = useState('30');
  const [isRadiusOpen, setisRadiusOpen] = useState(false);
  const handleClickOutside = event => {
    if (radiusRef.current && !radiusRef.current.contains(event.target)) {
      setisRadiusOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return (
    <div className='popular_tabs_search'>
      <button
        className='highlighted_tab_search'
        onClick={() => {
          // dispatch(resetFilterValues());
          setSubFilters('isReset');
          // resetForm();
          // getTherapists(null);
          setRadius('30');
          setisRadiusOpen(false);
        }}
      >
        <CloseOutlined />
        <p>Reset</p>
      </button>
      <Dropdown
        menu={{
          items: availabilityOptionsData
            ? availabilityOptionsData?.map((data, index) => ({
                label: (
                  <>
                    <button
                      className='customDropdownButtons'
                      onClick={() => {
                        setSubFilters({
                          ...subFilters,
                          avaiability: {
                            label: data?.label,
                            value: data?.value
                          }
                        });
                      }}
                    >
                      {data?.label}
                    </button>
                  </>
                ),
                key: data?.value?.toString()
              }))
            : [],
          selectable: true,
          selectedKeys: [subFilters?.avaiability?.value]
        }}
        trigger={['click']}
      >
        <a onClick={e => e.preventDefault()}>
          <div className='highlighted_tab_search'>
            <img src={CalendarRegular} alt='' />
            <p>
              {subFilters?.avaiability?.label ? (
                <>
                  {`${subFilters?.avaiability?.label}`}
                  <span
                    className='resetFilters'
                    onClick={e => {
                      e.stopPropagation();
                      setSubFilters({
                        ...subFilters,
                        avaiability: null
                      });
                    }}
                  >
                    <CloseOutlined />
                  </span>
                </>
              ) : (
                'Availability'
              )}
            </p>
          </div>
        </a>
      </Dropdown>
      <Dropdown
        menu={{
          items: frequencyOptionsData
            ? frequencyOptionsData?.map((data, index) => ({
                label: (
                  <>
                    <button
                      className='customDropdownButtons'
                      onClick={() => {
                        setSubFilters({
                          ...subFilters,
                          frequency: {
                            label: data?.label,
                            value: data?.value
                          }
                        });
                      }}
                    >
                      {data?.label}
                    </button>
                  </>
                ),
                key: data?.value?.toString()
              }))
            : [],
          selectable: true,
          selectedKeys: [subFilters?.frequency?.value]
        }}
        trigger={['click']}
      >
        <a onClick={e => e.preventDefault()}>
          <div className='highlighted_tab_search'>
            <img src={FreqSearchIcon} alt='' />
            <p>
              {subFilters?.frequency?.label ? (
                <>
                  {`${subFilters?.frequency?.label}`}
                  <span
                    className='resetFilters'
                    onClick={e => {
                      e.stopPropagation();
                      setSubFilters({
                        ...subFilters,
                        frequency: null
                      });
                    }}
                  >
                    <CloseOutlined />
                  </span>
                </>
              ) : (
                'Frequency'
              )}
            </p>
          </div>
        </a>
      </Dropdown>
      <Dropdown
        menu={{
          items: ageGroupOptionsData
            ? ageGroupOptionsData?.map((data, index) => ({
                label: (
                  <>
                    <button
                      className='customDropdownButtons'
                      onClick={() => {
                        setSubFilters({
                          ...subFilters,
                          ageGroup: {
                            label: data?.label,
                            value: data?.value
                          }
                        });
                      }}
                    >
                      {data?.label}
                    </button>
                  </>
                ),
                key: data?.value?.toString()
              }))
            : [],
          selectable: true,
          selectedKeys: [subFilters?.ageGroup?.value]
        }}
        trigger={['click']}
      >
        <a onClick={e => e.preventDefault()}>
          <div className='highlighted_tab_search'>
            <img src={AgeGroupIcon} alt='' />
            <p>
              {subFilters?.ageGroup?.label ? (
                <>
                  {`${subFilters?.ageGroup?.label}`}
                  <span
                    className='resetFilters'
                    onClick={e => {
                      e.stopPropagation();
                      setSubFilters({
                        ...subFilters,
                        ageGroup: null
                      });
                    }}
                  >
                    <CloseOutlined />
                  </span>
                </>
              ) : (
                'Age Group'
              )}
            </p>
          </div>
        </a>
      </Dropdown>

      <Dropdown
        menu={{
          items: serviceDeliveryData
            ? serviceDeliveryData?.map((data, index) => ({
                label: (
                  <>
                    <button
                      className='customDropdownButtons'
                      onClick={() => {
                        setSubFilters({
                          ...subFilters,
                          serviceDelivery: {
                            label: data?.label,
                            value: data?.value
                          }
                        });
                      }}
                    >
                      {data?.label}
                    </button>
                  </>
                ),
                key: data?.value?.toString()
              }))
            : [],
          selectable: true,
          selectedKeys: [subFilters?.serviceDelivery?.value || filterType]
        }}
        trigger={['click']}
      >
        <a onClick={e => e.preventDefault()}>
          <div className='highlighted_tab_search'>
            <img src={DoctorServiceDeliveryBlack} alt='' />
            <p>
              {subFilters?.serviceDelivery?.label ? (
                <>
                  {`${subFilters?.serviceDelivery?.label}`}
                  <span
                    className='resetFilters'
                    onClick={e => {
                      e.stopPropagation();
                      setSubFilters({
                        ...subFilters,
                        serviceDelivery: null
                      });
                    }}
                  >
                    <CloseOutlined />
                  </span>
                </>
              ) : filterType ? (
                filterType
              ) : (
                'Service delivery'
              )}
            </p>
          </div>
        </a>
      </Dropdown>

      {/* {filteredCategory?.subCategories?.length ? (
        <Dropdown
          menu={{
            items: filteredCategory?.subCategories
              ? filteredCategory?.subCategories?.map((data, index) => ({
                  label: (
                    <>
                      <button
                        className='customDropdownButtons'
                        onClick={() => {
                          setSubFilters({
                            ...subFilters,
                            subSpeciality: {
                              label: data?.name,
                              value: data?._id
                            }
                          });
                        }}
                      >
                        {data?.name}
                      </button>
                    </>
                  ),
                  key: data?._id?.toString(),
                  value: data?._id?.toString()
                }))
              : [],
            selectable: true,
            selectedKeys: [subFilters?.subSpeciality?.value]
          }}
          trigger={['click']}
        >
          <a onClick={e => e.preventDefault()}>
            <div className='highlighted_tab_search'>
              <img src={HeadSideHeartRegular} alt='' />
              <p>
                {subFilters?.subSpeciality?.label ? (
                  <>
                    {`${subFilters?.subSpeciality?.label}`}
                    <span
                      className='resetFilters'
                      onClick={e => {
                        e.stopPropagation();
                        setSubFilters({
                          ...subFilters,
                          subSpeciality: null
                        });
                      }}
                    >
                      <CloseOutlined />
                    </span>
                  </>
                ) : (
                  'Provider specialty'
                )}
              </p>
            </div>
          </a>
        </Dropdown>
      ) : null} */}
      {latVal ? (
        <Dropdown
          menu={{
            items: [
              {
                label: (
                  <>
                    <Slider
                      onChange={val => {
                        setRadius(val);
                      }}
                      style={{ width: '250px' }}
                      value={radius}
                      min={1}
                      max={50}
                    />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '0 10px'
                      }}
                    >
                      Radius: {radius}Km
                      <button
                        className='customNoBg'
                        onClick={() => {
                          setSubFilters({
                            ...subFilters,
                            radius: {
                              label: radius,
                              value: radius * 1000
                            }
                          });
                          setisRadiusOpen(false);
                        }}
                      >
                        Apply
                      </button>
                    </div>
                  </>
                ),
                key: '1'
              }
            ],
            selectable: true,
            selectedKeys: [subFilters?.radius?.value]
          }}
          trigger={['click']}
          onClick={() => setisRadiusOpen(!isRadiusOpen)}
          overlayClassName='customNoHoverDropDownElement'
          open={isRadiusOpen}
        >
          <a onClick={e => e.preventDefault()}>
            <div className='highlighted_tab_search'>
              <AimOutlined />
              <p>
                {subFilters?.radius?.label ? (
                  <>
                    {`${subFilters?.radius?.label}Km`}
                    <span
                      className='resetFilters'
                      onClick={e => {
                        e.stopPropagation();
                        setSubFilters({
                          ...subFilters,
                          radius: null
                        });
                        setRadius('30');
                        setisRadiusOpen(false);
                      }}
                    >
                      <CloseOutlined />
                    </span>
                  </>
                ) : (
                  'Radius'
                )}
              </p>
            </div>
          </a>
        </Dropdown>
      ) : null}
    </div>
  );
};
