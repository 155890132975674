import { useNavigate } from 'react-router-dom';
import { Logo } from '../../../assets/images';
import './footer.scss';

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className='footer-main-container'>
      <div className='footer-content'>
        <Logo
          style={{ cursor: 'pointer', zIndex: '1' }}
          onClick={() => navigate('/')}
        />

        <p>
          ©2023<span> Therapy Match</span>, LLC. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
