import React, { useEffect, useState } from 'react';
import { getRequest } from '../../api/apiCalls';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import { Col, Row } from 'antd';
import './SingleProfile.scss';
import {
  CalendarXmark,
  DoctorProfileProfession,
  Left_Btn
} from '../../assets/vectors';

import { DoctorsCalender } from '../../components/Profiles/DoctorsCalender';
import { SearchWaitlistForm } from '../searchTherapist/SearchWaitlistForm';
import { DP } from '../../assets/images';

import SpinLoader from '../../components/UI/Loader';
import ButtonGreen from '../../components/UI/Button/ButtonGreen';
import { generateProviderProfileData } from './constants';
import dayjs from 'dayjs';
export const SingleDoctorProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isTab = useMediaQuery({ query: '(max-width: 1024px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 540px)' });
  const token = useSelector(state => state.authReducer.token);
  const [therapist, setTherapist] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [showItems, setshowItems] = useState(4);
  const [reformattedSchedules, setReformattedSchedules] = useState([]);
  const [waitlistActiveThearapist, setWaitlistActiveThearapist] = useState('');

  const getAllData = async () => {
    setIsLoading(true);
    const onSuccess = res => {
      setTherapist(res?.data);
      reformatSchedules(res?.data?.Schedule);
      setIsLoading(false);
    };
    const onError = res => {
      console.log(res, 'erorr res');
      setIsLoading(false);
    };
    await getRequest(
      null,
      `therapist/single-therapist/${id}`,
      true,
      true,
      onSuccess,
      onError
    );
  };
  const reformatSchedules = schedules => {
    const now = dayjs();
    const schedulesWithDates = schedules
      ?.map(schedule => ({
        ...schedule,
        start: dayjs(schedule?.start),
        end: dayjs(schedule?.end)
      }))
      ?.filter(schedule => schedule?.start?.isAfter(now));
    const groupedSchedules = schedulesWithDates?.reduce((acc, schedule) => {
      const date = schedule?.start?.format('YYYY-MM-DD');
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push({
        ...schedule,
        start: schedule?.start?.toISOString(),
        end: schedule?.end?.toISOString()
      });
      return acc;
    }, {});
    const transformedSchedules = Object.keys(groupedSchedules)?.map(date => ({
      date: date,
      schedules: groupedSchedules[date]
    }));
    transformedSchedules?.forEach(schedule => {
      schedule?.schedules?.sort(
        (a, b) => dayjs(a?.start).valueOf() - dayjs(b?.start).valueOf()
      );
    });
    transformedSchedules.sort(
      (a, b) =>
        dayjs(a?.schedules[0]?.start).valueOf() -
        dayjs(b?.schedules[0]?.start).valueOf()
    );
    setReformattedSchedules(transformedSchedules);
  };
  const getMaxLength = () => {
    const filteredItems = reformattedSchedules?.map(item => {
      return item?.schedules?.filter(e => new Date(e?.start) > new Date());
    });
    return filteredItems
      ?.map(obj => obj?.length)
      ?.reduce((max, len) => Math.max(max, len), 0);
  };
  const providerProfileData = generateProviderProfileData(
    therapist,
    token ? true : false
  );
  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    });
    getAllData();
  }, []);

  return (
    <>
      <Header />
      {isLoading ? (
        <SpinLoader />
      ) : (
        <>
          <div className='doctorDetailsWrapper'>
            <Row>
              <Col span={isTab ? 24 : 14} className='detailsWrapper'>
                <div className='doctorBackButton' onClick={() => navigate(-1)}>
                  <img src={Left_Btn} alt='Previous' />
                  <p> Back </p>
                </div>
                <div className='headDetails'>
                  <div className='profileImg'>
                    {therapist?.personalInfo?.profileImg ? (
                      <img
                        style={{ objectFit: 'contain' }}
                        src={therapist?.personalInfo?.profileImg}
                        alt=''
                      />
                    ) : (
                      <DP />
                    )}
                  </div>
                  <div className='doctorName'>
                    <h3>
                      {therapist?.personalInfo?.firstName +
                        ' ' +
                        therapist?.personalInfo?.lastName}{' '}
                    </h3>

                    <h4>{therapist?.providerProfile?.practice?.name}</h4>
                    {/* <div className='headTags'>
                      {therapist?.clinicId &&
                      therapist?.clinicData?.clinicDetails
                        ?.emailNotificationBehaviour === 'clinic' ? (
                        <a
                          className='singleTag'
                          href={
                            token
                              ? `tel:${therapist?.clinicData?.representativeDetails?.representativePhoneNumber}`
                              : '#'
                          }
                        >
                          <img src={PhoneIcon} alt='' />

                          {token ? (
                            therapist?.clinicData?.representativeDetails
                              ?.representativePhoneNumber
                          ) : (
                            <Tooltip
                              arrow={false}
                              title='Please login to view Phone number'
                            >
                              XXXX XXX XXX
                            </Tooltip>
                          )}
                        </a>
                      ) : (
                        <a
                          className='singleTag'
                          href={
                            token
                              ? `tel:${therapist?.providerProfile?.phoneNumber}`
                              : '#'
                          }
                        >
                          <img src={PhoneIcon} alt='' />

                          {token ? (
                            therapist?.providerProfile?.phoneNumber
                          ) : (
                            <Tooltip
                              arrow={false}
                              title='Please login to view Phone number'
                            >
                              XXXX XXX XXX
                            </Tooltip>
                          )}
                        </a>
                      )}
                      {therapist?.clinicId &&
                      therapist?.clinicData?.clinicDetails
                        ?.emailNotificationBehaviour === 'clinic' ? (
                        <a
                          className='singleTag'
                          href={
                            token
                              ? `mailto:${therapist?.clinicData?.representativeDetails?.representativeEmail}`
                              : '#'
                          }
                        >
                          <img src={EmailIcon} alt='' />

                          {token ? (
                            therapist?.clinicData?.representativeDetails
                              ?.representativeEmail
                          ) : (
                            <Tooltip
                              arrow={false}
                              title='Please login to view Email'
                            >
                              ******@******.com
                            </Tooltip>
                          )}
                        </a>
                      ) : (
                        <a
                          className='singleTag'
                          href={
                            token
                              ? `mailto:${therapist?.personalInfo?.email}`
                              : '#'
                          }
                        >
                          <img src={EmailIcon} alt='' />
                          {token ? (
                            therapist?.personalInfo?.email
                          ) : (
                            <Tooltip
                              arrow={false}
                              title='Please login to view Email'
                            >
                              ******@******.com
                            </Tooltip>
                          )}
                        </a>
                      )}
                    </div> */}
                  </div>
                </div>
                <div className='siteMemberAchievements'>
                  <div className='singleAchievement fullWidth'>
                    <img src={DoctorProfileProfession} alt='$$' />
                    <div className='content'>
                      <h4>
                        {' '}
                        About{' '}
                        {therapist?.personalInfo?.firstName +
                          ' ' +
                          therapist?.personalInfo?.lastName}
                      </h4>
                      <p>{therapist?.personalInfo?.bio}</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={isTab ? 24 : 10}>
                <div className='slotsWrapper'>
                  {reformattedSchedules?.some(
                    item => item?.schedules?.length > 0
                  ) ? (
                    <>
                      <DoctorsCalender
                        data={reformattedSchedules || []}
                        id={id}
                        doctorData={therapist}
                        showItems={showItems}
                      />
                      <>
                        {getMaxLength() > showItems && (
                          <p
                            className='card_slot_seemore'
                            onClick={() => setshowItems(showItems + 4)}
                          >
                            See more
                          </p>
                        )}
                        <p
                          className='card_slot_seemore'
                          onClick={() => {
                            if (token) {
                              setWaitlistActiveThearapist(id);
                              setOpenModal(true);
                            } else {
                              navigate('/login');
                            }
                          }}
                        >
                          Couldn’t find suitable time? Join waitlist.
                        </p>
                      </>
                    </>
                  ) : (
                    <div className='noSlotWrapper'>
                      <span>
                        <img src={CalendarXmark} alt='$$$' />
                        <p> No bookable appointment available</p>
                      </span>
                      <ButtonGreen
                        text='Join waitlist'
                        className='Make_an_appointment'
                        onClick={() => {
                          if (token) {
                            setWaitlistActiveThearapist(id);
                            setOpenModal(true);
                          } else {
                            navigate('/login');
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>

          <div className='doctorDetailsWrapper'>
            <h2 className='doctorDetailHeading'>Provider Profile</h2>
            <Row gutter={16} style={{ marginTop: '20px' }}>
              {providerProfileData?.filter(Boolean)?.map((profileInfo, ind) => {
                return (
                  <Col
                    style={{ marginBottom: '20px ' }}
                    span={isMobile ? 24 : isTab ? 12 : 8}
                  >
                    <div className='memberDetailCard'>
                      <div className='singleDetailWrapper'>
                        <h4 className='customSingleViewHeading'>
                          <img src={profileInfo?.icon} alt='$$' />
                          <span> {profileInfo?.label}</span>
                        </h4>
                        <p className='customSingleViewParagraph'>
                          {profileInfo?.value}{' '}
                        </p>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
            {/* {!token ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ButtonGreen
                  text='View More'
                  className='Make_an_appointment'
                  onClick={() => {
                    navigate('/login');
                  }}
                />
              </div>
            ) : null} */}
          </div>
        </>
      )}

      <Footer color={true} />
      <SearchWaitlistForm
        setOpenModal={setOpenModal}
        openModal={openModal}
        waitlistActiveThearapist={waitlistActiveThearapist}
      />
    </>
  );
};
