import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateAppointmentField } from '../../Redux/Reducers/apponitmentSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { getRequest, postRequest } from '../../api/apiCalls';
import dayjs from 'dayjs';
import ButtonGreen from '../UI/Button/ButtonGreen';

const AppointmentSummery = ({ setActiveStep, activeStep }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [therapist, setTherapist] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const profileData = useSelector(state => state.profile.profileData);
  const patient_details = useSelector(
    state => state.appointment.patient_details
  );
  const user_address = useSelector(state => state.appointment.user_address);
  const selected_slot = useSelector(state => state.appointment.selected_slot);
  const appointment_type = useSelector(
    state => state.appointment.appointment_type
  );
  console.log(therapist);
  console.log(appointment_type);
  const onFinish = async () => {
    setisLoading(true);
    const dataToSend = {
      fullName: patient_details?.fullName,
      gender: patient_details?.gender,
      age: patient_details?.age,
      isGuardian: patient_details?.relationship === 'guardian' ? true : false,
      type: appointment_type || '',
      address: user_address || '',
      scheduleId: selected_slot?._id || ''
    };
    const onSuccess = res => {
      setisLoading(false);
      navigate('/consultations-success');
      dispatch(
        updateAppointmentField({
          field: 'selected_slot',
          value: null
        })
      );
      dispatch(
        updateAppointmentField({
          field: 'user_address',
          value: ''
        })
      );
      dispatch(
        updateAppointmentField({
          field: 'appointment_type',
          value: ''
        })
      );
      dispatch(
        updateAppointmentField({
          field: 'selected_therapist',
          value: ''
        })
      );
    };
    const onError = () => {
      setisLoading(false);
    };
    await postRequest(
      dataToSend,
      `customer/create-booking/${id}`,
      true,
      true,
      onSuccess,
      onError
    );
  };
  useEffect(() => {
    const getDoctorData = async () => {
      setisLoading(true);
      const onSuccess = res => {
        setTherapist(res?.data);
        setisLoading(false);
      };
      const onError = res => {
        console.log(res, 'erorr res');
        setisLoading(false);
      };
      await getRequest(
        null,
        `therapist/single-therapist/${id}`,
        true,
        true,
        onSuccess,
        onError
      );
    };
    getDoctorData();
  }, []);
  return (
    <div className='patientInfo' style={{ marginTop: '10px' }}>
      <h2 style={{ textAlign: 'center', margin: '20px 0' }}>
        Are these details Correct?
      </h2>
      <h3 className='singleSummeryHeading'>Patient</h3>
      <div className='singleSummeryDetail'>
        <p className='detailHead'>Patient Name</p>
        <p className='detailValue'>{patient_details?.fullName}</p>
      </div>
      <div className='singleSummeryDetail'>
        <p className='detailHead'>Patient Gender</p>
        <p className='detailValue'>{patient_details?.gender}</p>
      </div>
      <div className='singleSummeryDetail'>
        <p className='detailHead'>Patient Age</p>
        <p className='detailValue'>{patient_details?.age}</p>
      </div>

      <h3 className='singleSummeryHeading'> Parent/Guardian</h3>
      <div className='singleSummeryDetail'>
        <p className='detailHead'>Parent/Guardian/Representative name</p>
        <p className='detailValue'>{profileData?.relationName}</p>
      </div>
      <div className='singleSummeryDetail'>
        <p className='detailHead'>
          Parent/Guardian/Representative (Relationship to Child)
        </p>
        <p className='detailValue'>
          {patient_details?.isGuardian ? 'Guardian' : 'Parent'}
        </p>
      </div>
      <h3 className='singleSummeryHeading'>Appointment</h3>

      <div className='singleSummeryDetail'>
        <p className='detailHead'>Appointment Type</p>
        <p className='detailValue'>
          {appointment_type === 'clinic'
            ? `Clinic Appointment (${therapist?.personalInfo?.bussinessAddress})`
            : appointment_type === 'video'
            ? `Telehealth Appointment (Online)`
            : appointment_type === 'home'
            ? `Home-visit appointment(${user_address})`
            : '--'}
        </p>
      </div>

      <div className='singleSummeryDetail'>
        <p className='detailHead'>Start Time</p>
        <p className='detailValue'>
          {dayjs(selected_slot?.start).format('MMM DD, YYYY h:mm A')}
        </p>
      </div>
      <div className='singleSummeryDetail'>
        <p className='detailHead'>End Time</p>
        <p className='detailValue'>
          {dayjs(selected_slot?.end).format('MMM DD, YYYY h:mm A')}
        </p>
      </div>
      <div className='singleSummeryDetail'>
        <p className='detailHead'>Fee</p>
        <p className='detailValue'>${selected_slot?.fee}</p>
      </div>
      <h3 className='singleSummeryHeading'>Provider</h3>

      <div className='singleSummeryDetail'>
        <p className='detailHead'>Provider Name</p>
        <p className='detailValue'>
          {therapist?.personalInfo?.firstName +
            ' ' +
            therapist?.personalInfo?.lastName}
        </p>
      </div>

      <div className='timeSlotsBtn'>
        <ButtonGreen
          onClick={() => {
            setActiveStep(prev => prev - 1);
          }}
          text='Back'
          style={{ margin: '10px' }}
          disabled={isLoading}
        />
        <ButtonGreen
          text='Submit request'
          onClick={() => onFinish()}
          style={{ margin: '10px' }}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};

export default AppointmentSummery;
