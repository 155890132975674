import React, { useEffect, useState } from 'react';
import Header from '../../components/header/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { getRequest } from '../../api/apiCalls';
import SpinLoader from '../../components/UI/Loader';
import {
  EmailIcon,
  Left_Btn,
  LocationDoctor,
  PhoneIcon,
  SingleConsultationInPerson,
  SingleConsultationVideo
} from '../../assets/vectors';
import './SingleConsultation.scss';
import { Col, Divider, Row } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { DP } from '../../assets/images';
import dayjs from 'dayjs';
import Footer from '../../components/footer/Footer';
import { FollowUpSessions } from '../../components/consultations/FollowUpSessions';
export const SingleConsultation = () => {
  const navigate = useNavigate();
  const { bookingId } = useParams();
  const [singleRequest, setSingleRequest] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isTab = useMediaQuery({ query: '(max-width: 1024px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 540px)' });
  const getSingleRequest = async () => {
    setIsLoading(true);
    const onSuccess = res => {
      setSingleRequest(res.data);
      setIsLoading(false);
    };
    const onError = () => {
      setIsLoading(false);
    };
    await getRequest(
      null,
      `therapist/get-single-request/${bookingId}`,
      true,
      true,
      onSuccess,
      onError
    );
  };
  useEffect(() => {
    getSingleRequest();
  }, [bookingId]);
  const getStatus = val => {
    const currentDateTime = new Date();
    const scheduleStart = new Date(val?.start);
    currentDateTime.setUTCSeconds(0);
    currentDateTime.setUTCMilliseconds(0);
    if (currentDateTime.getMinutes() < 30) {
      currentDateTime.setMinutes(0);
    } else {
      currentDateTime.setMinutes(30);
    }
    if (val?.status === 'upcoming' && scheduleStart <= currentDateTime) {
      return 'In progress';
    }
    return val?.status || '';
  };
  console.log(singleRequest);
  return (
    <>
      <Header />
      {isLoading ? (
        <SpinLoader />
      ) : (
        <>
          <div className='singleConsultation'>
            <div className='doctorDetailsWrapper'>
              <Row>
                <Col span={isTab ? 24 : 16} className='detailsWrapper'>
                  <div
                    className='doctorBackButton'
                    onClick={() => navigate(-1)}
                  >
                    <img src={Left_Btn} alt='Previous' />
                    <p> Back </p>
                  </div>
                  <div className='headDetails'>
                    <div className='profileImg'>
                      {singleRequest?.therapist?.profileImg ? (
                        <img
                          src={singleRequest?.therapist?.profileImg}
                          alt=''
                        />
                      ) : (
                        <DP />
                      )}
                    </div>
                    <div className='doctorName'>
                      <h3>
                        {singleRequest?.therapist?.firstName +
                          ' ' +
                          singleRequest?.therapist?.lastName}{' '}
                      </h3>

                      <h4>
                        {
                          singleRequest?.therapist?.providerProfile?.practice
                            ?.name
                        }
                      </h4>
                      {singleRequest?.schedule?.status === 'pending' ? (
                        <div className='headTags'>
                          <p className='singleTag error'>
                            Your consultation is pending. You will be able to
                            contact the therapist as soon as they accept the
                            timeslot you have reserved.
                          </p>
                        </div>
                      ) : (
                        <div className='headTags'>
                          <a
                            className='singleTag'
                            href={`tel:${singleRequest?.therapist?.providerProfile?.phoneNumber}`}
                          >
                            <img src={PhoneIcon} alt='' />

                            {/* <PhoneOutlined /> */}
                            {
                              singleRequest?.therapist?.providerProfile
                                ?.phoneNumber
                            }
                          </a>
                          <a
                            className='singleTag'
                            href={`mailto:${singleRequest?.therapist?.email}`}
                          >
                            <img src={EmailIcon} alt='' />

                            {/* <MailOutlined /> */}
                            {singleRequest?.therapist?.email}
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='siteMemberAchievements'>
                    <div className='singleAchievement'>
                      {/* <img src={Achievement} alt='$$' />{' '} */}
                      <img
                        src={
                          singleRequest?.schedule?.type === 'video'
                            ? SingleConsultationVideo
                            : singleRequest?.schedule?.type === 'home'
                            ? SingleConsultationInPerson
                            : singleRequest?.schedule?.type === 'clinic'
                            ? SingleConsultationInPerson
                            : SingleConsultationVideo
                        }
                        alt='$$'
                      />
                      <div className='content'>
                        <h4>Consultation Type</h4>
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              singleRequest?.schedule?.type === 'video'
                                ? 'Video Consultation'
                                : singleRequest?.schedule?.type === 'clinic'
                                ? 'Face to Face <strong>At Clinic</strong>'
                                : 'Face to Face <strong>At Home</strong>'
                          }}
                        />
                      </div>
                    </div>

                    <div className='singleAchievement'>
                      {' '}
                      <img src={LocationDoctor} alt='$$' />
                      <div className='content'>
                        <h4>
                          {' '}
                          {singleRequest?.schedule?.type === 'home'
                            ? 'Home Address'
                            : 'Clinic Address'}
                        </h4>
                        <p>
                          {' '}
                          {singleRequest?.schedule?.type === 'home'
                            ? singleRequest?.booking?.address
                            : singleRequest?.therapist?.bussinessAddress}
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col span={isTab ? 24 : 8}>
                  <div className='appointmentDetailsWrap'>
                    <h2>Appointment Details</h2>
                    <div className='singleappointemtHeadDetail'>
                      <div className='title'>Status</div>
                      <div
                        className='content'
                        style={{ textTransform: 'capitalize' }}
                      >
                        {getStatus(singleRequest?.schedule)}
                      </div>
                    </div>
                    <Divider />
                    <div className='singleappointemtHeadDetail'>
                      <div className='title'>Date</div>
                      <div className='content'>
                        {dayjs(singleRequest?.schedule?.start).format(
                          'YYYY/MM/DD'
                        )}
                      </div>
                    </div>
                    <Divider />
                    <div className='singleappointemtHeadDetail'>
                      <div className='title'>Start Time</div>
                      <div className='content'>
                        {' '}
                        {dayjs(singleRequest?.schedule?.start).format('h:mm A')}
                      </div>
                    </div>
                    <Divider />
                    <div className='singleappointemtHeadDetail'>
                      <div className='title'>End Time</div>
                      <div className='content'>
                        {dayjs(singleRequest?.schedule?.end).format('h:mm A')}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className='doctorDetailsWrapper'>
              <h2 className='doctorDetailHeading'>Patient Details</h2>
              <Row gutter={16} style={{ marginTop: '20px' }}>
                <Col
                  style={{ marginBottom: '20px ' }}
                  span={isMobile ? 24 : isTab ? 12 : 8}
                >
                  <div className='memberDetailCard'>
                    <div className='singleDetailWrapper'>
                      <h4 className='customSingleViewHeading'>
                        <span> Name</span>
                      </h4>
                      <p className='customSingleViewParagraph'>
                        {singleRequest?.booking?.fullName}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col
                  style={{ marginBottom: '20px ' }}
                  span={isMobile ? 24 : isTab ? 12 : 8}
                >
                  <div className='memberDetailCard'>
                    <div className='singleDetailWrapper'>
                      <h4 className='customSingleViewHeading'>
                        <span> Age</span>
                      </h4>
                      <p className='customSingleViewParagraph'>
                        {singleRequest?.booking?.age} Years{' '}
                      </p>
                    </div>
                  </div>
                </Col>{' '}
                <Col
                  style={{ marginBottom: '20px ' }}
                  span={isMobile ? 24 : isTab ? 12 : 8}
                >
                  <div className='memberDetailCard'>
                    <div className='singleDetailWrapper'>
                      <h4 className='customSingleViewHeading'>
                        <span> Gender</span>
                      </h4>
                      <p className='customSingleViewParagraph'>
                        {singleRequest?.booking?.gender}
                      </p>
                    </div>
                  </div>
                </Col>{' '}
                <Col
                  style={{ marginBottom: '20px ' }}
                  span={isMobile ? 24 : isTab ? 12 : 8}
                >
                  <div className='memberDetailCard'>
                    <div className='singleDetailWrapper'>
                      <h4 className='customSingleViewHeading'>
                        <span> Reachable Email Address</span>
                      </h4>
                      <p className='customSingleViewParagraph'>
                        {singleRequest?.customer?.userId?.email}
                      </p>
                    </div>
                  </div>
                </Col>{' '}
                <Col
                  style={{ marginBottom: '20px ' }}
                  span={isMobile ? 24 : isTab ? 12 : 8}
                >
                  <div className='memberDetailCard'>
                    <div className='singleDetailWrapper'>
                      <h4 className='customSingleViewHeading'>
                        <span> Parent/ Guardian/ Representative name</span>
                      </h4>
                      <p className='customSingleViewParagraph'>
                        {singleRequest?.customer?.relationName}
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            {singleRequest?.sessions?.length ? (
              <div className='sessions'>
                <h2>Sessions</h2>
                <FollowUpSessions data={singleRequest} />
              </div>
            ) : null}
          </div>
        </>
      )}
      <Footer color={true} />
    </>
  );
};
