import React, { useState } from 'react';
import './rateExperience.scss';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import { Form, Rate } from 'antd';
import { postRequest } from '../../api/apiCalls';
import SpinLoader from '../UI/Loader';
import ButtonGreen from '../UI/Button/ButtonGreen';

const RateExperience = () => {
  const [isLoading, setisLoading] = useState(false);
  const { bookingId } = useParams();

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const showFooter = true;
  const onFinish = async values => {
    const onSuccess = res => {
      setisLoading(false);
      navigate('/consultations');
    };
    const onError = err => {
      setisLoading(false);
    };
    const dataToSend = {
      reviewText: values?.therapistFeedback,
      ratingValue: values?.therapistRating
    };
    await postRequest(
      dataToSend,
      `customer/give-review/${bookingId}`,
      true,
      true,
      onSuccess,
      onError
    );
  };
  return (
    <>
      <Header />
      {isLoading ? (
        <SpinLoader />
      ) : (
        <div className='rate_experience'>
          <div className='head'>
            <h1> Rate your experience </h1>
          </div>
          <div className='ratings'>
            <Form
              name='basic'
              layout='vertical'
              form={form}
              onFinish={onFinish}
              autoComplete='off'
              className='searchForm'
            >
              <div className='homeSearch'>
                <div className='simpleSearch'>
                  <p>Give rating</p>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Please Provide Rating'
                      }
                    ]}
                    name='therapistRating'
                  >
                    <Rate className='therapistRating' allowClear={false} />
                  </Form.Item>
                  <p>Your feedback</p>
                  <Form.Item name='therapistFeedback'>
                    <textarea
                      rows={6}
                      placeholder='Type feedback...'
                    ></textarea>
                  </Form.Item>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <ButtonGreen type='submit' text={'Submit review'} />
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      )}

      {showFooter && <Footer />}
    </>
  );
};

export default RateExperience;
