import React, { useEffect, useRef, useState } from 'react';
import './heroSection.scss';
import { Arrow_Left, CategoryGreen } from '../../assets/vectors';
import { ConfigProvider, Form, Input, List, Spin } from 'antd';
import AntdInputAutocomplete from '../../helpers/LocationInput';
import { getRequest, postRequest } from '../../api/apiCalls';
import {
  setFilterValues,
  setSearchedTherapists
} from '../../Redux/Reducers/searchedTherapistsSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { SearchBarFieldIcon } from '../../assets/images';
import { sortTherapists } from '../../helpers/common';
const HeroContent = ({ setisLoading }) => {
  // React hooks and utilities
  const dispatch = useDispatch(); // Redux dispatch function
  const navigate = useNavigate(); // React Router navigation function
  const [form] = Form.useForm(); // Ant Design Form hook
  const listRef = useRef(null); // React useRef for storing a reference to a DOM element
  const isMobile = useMediaQuery({ query: '(max-width: 580px)' }); // Media query hook for detecting mobile view

  // State variables
  const [categories, setCategories] = useState(null); // State for categories data
  const [therapistName, setTherapistName] = useState(''); // State for therapist name
  const [categorySelected, setCategorySelected] = useState(null); // State for selected category
  const [subCatSelected, setSubCatSelected] = useState(null); // State for selected category
  const [isCategoriesOpen, setIsCategoriesOpen] = useState(''); // State for category dropdown visibility
  const [isFieldLoading, setisFieldLoading] = useState(''); // State for field loading indicator
  const [practiceOptions, setPracticeOptions] = useState([]); // State for  practice options
  const [subPracticeOptions, setSubPracticeOptions] = useState([]); // State for sub practice options
  const [filteredPracticeOptions, setFilteredPracticeOptions] = useState([]); // State for filtered practice options
  const [filteredSubPracticeOptions, setFilteredSubPracticeOptions] = useState(
    []
  ); // State for filtered sub-practice options
  const [searchedTherapists, setSearchedTherapistsState] = useState([]); // State for searched therapists
  const [searchedClinics, setSearchedClinics] = useState([]); // State for searched clinics
  const [searchDisabled, setSearchDisabled] = useState(false); // State for search input disabled indicator

  const getCategories = async () => {
    const onSuccess = res => {
      setCategories(res.data);
      const practiceOptions = res.data
        ?.map(data => {
          if (data?.categoryName) {
            return {
              label: data.categoryName,
              value: data._id,
              type: 'Speciality',
              image: data.icon
            };
          }
          return null;
        })
        .filter(Boolean);
      const subSpecialitiesArray = res.data
        ?.flatMap(item =>
          item?.subCategories?.map(subItem => {
            if (subItem?.name) {
              return {
                label: subItem.name,
                value: subItem._id,
                subHeading: item?.categoryName,
                type: 'Sub Speciality'
              };
            }
            return null;
          })
        )
        .filter(Boolean);
      setPracticeOptions(practiceOptions);
      setSubPracticeOptions(subSpecialitiesArray);
      setFilteredPracticeOptions(practiceOptions);
      setFilteredSubPracticeOptions(subSpecialitiesArray);
    };
    await getRequest(null, `category/get-category`, true, true, onSuccess);
  };
  const getSearchedTherapists = async nameInput => {
    setisFieldLoading(true);
    const dataToSend = {
      name: nameInput || ''
    };
    const onSuccess = res => {
      const therapistsOptions = res?.data?.therapists?.map(data => ({
        label: `${data?.personalInfo?.firstName} ${data?.personalInfo?.lastName}`,
        value: data?._id,
        type: 'Provider',
        image: data?.personalInfo?.profileImg || SearchBarFieldIcon
      }));
      const clinicOptions = res?.data?.clinics?.map(data => ({
        label: `${data?.clinicDetails?.clinicName}`,
        value: data?._id,
        type: 'Clinic',
        image: data?.clinicDetails?.clinicLogo || SearchBarFieldIcon
      }));
      setSearchedTherapistsState(therapistsOptions);
      setSearchedClinics(clinicOptions);
      setisFieldLoading(false);
    };
    const onError = err => {
      setisFieldLoading(false);
    };
    await postRequest(
      dataToSend,
      `customer/get-search-by-name`,
      true,
      true,
      onSuccess,
      onError
    );
  };
  const filterPracticeOptions = inputValue => {
    if (inputValue) {
      const filteredOptions = practiceOptions?.filter(option => {
        return option?.label.toLowerCase().includes(inputValue.toLowerCase());
      });
      const filteredSubOptions = subPracticeOptions?.filter(option => {
        return option?.label.toLowerCase().includes(inputValue.toLowerCase());
      });
      setFilteredSubPracticeOptions(filteredSubOptions || []);
      setFilteredPracticeOptions(filteredOptions || []);
    } else {
      setFilteredSubPracticeOptions(subPracticeOptions);
      setFilteredPracticeOptions(practiceOptions);
    }
  };
  const onFinish = async values => {
    dispatch(
      setFilterValues({
        location: '',
        lat: '',
        lng: '',
        name: '',
        category: {},
        subCategory: {}
      })
    );
    setisLoading(true);
    const dataToSend = {
      categories: categorySelected?.value || '',
      subCategories: [subCatSelected?.value].filter(Boolean) || [],
      lat: values?.lat || '',
      lon: values?.lng || '',
      therapistName: therapistName
    };

    const onSuccess = res => {
      const sortedTherapists = sortTherapists(res?.data);

      dispatch(setSearchedTherapists(sortedTherapists || []));
      dispatch(
        setFilterValues({
          location: values?.providerLocationName || '',
          lat: values?.lat || '',
          lng: values?.lng || '',
          name: therapistName,
          category: categorySelected || {},
          subCategory: subCatSelected || {}
        })
      );
      navigate('/search-therapist/?isHomeSearched=true');
      setisLoading(false);
    };
    const onError = err => {
      setisLoading(false);
    };
    await postRequest(
      dataToSend,
      `customer/get-search`,
      true,
      true,
      onSuccess,
      onError
    );
  };
  const inputValueChangeHandler = val => {
    setTherapistName(val);
    filterPracticeOptions(val);
    setCategorySelected(null);
    setSubCatSelected(null);
    if (val.length >= 3) {
      getSearchedTherapists(val);
    } else {
      setSearchedTherapistsState([]);
      setSearchedClinics([]);
    }
  };
  const providerLocationNameValue = Form.useWatch('providerLocationName', form);
  const latVal = Form.useWatch('lat', form);
  const lngVal = Form.useWatch('lng', form);
  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    });
    getCategories();

    const closeList = event => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setIsCategoriesOpen(false);
      }
    };
    document.addEventListener('click', closeList);
    return () => {
      document.removeEventListener('click', closeList);
    };
  }, []);
  useEffect(() => {
    if (providerLocationNameValue && !latVal && !lngVal) {
      setSearchDisabled(true);
    } else {
      setSearchDisabled(false);
    }
  }, [providerLocationNameValue, latVal, lngVal]);

  return (
    <>
      <div className='hero_section'>
        <div className='hero__content'>
          <div className='heroHeadings'>
            <h2 style={{ paddingRight: '20px' }}>
              Book an appointment
              {!isMobile ? <br /> : null} for your child
            </h2>
            <p>
              Get the support you need for your child. Book an appointment now!
            </p>
          </div>
          <Form
            name='basic'
            layout='vertical'
            form={form}
            onFinish={onFinish}
            autoComplete='off'
            className='searchForm homeSearchForm'
          >
            <div className='homeSearch'>
              <div className='simpleSearch'>
                <img
                  style={{ width: '16px', marginLeft: '15px' }}
                  src={CategoryGreen}
                  alt=''
                  className='category'
                />
                <div className='listWrapper' ref={listRef}>
                  <Input
                    className='therapistInput'
                    placeholder='Speciality or Provider Name'
                    onClick={() => {
                      setIsCategoriesOpen(true);
                    }}
                    onFocus={() => {
                      setIsCategoriesOpen(true);
                    }}
                    on
                    onChange={e => inputValueChangeHandler(e.target.value)}
                    value={
                      categorySelected
                        ? categorySelected?.label
                        : subCatSelected
                        ? subCatSelected?.label
                        : therapistName
                    }
                    suffix={
                      <ConfigProvider
                        theme={{
                          token: {
                            colorPrimary: '#809848'
                          }
                        }}
                      >
                        <Spin spinning={isFieldLoading} />
                      </ConfigProvider>
                    }
                  />
                  {isCategoriesOpen ? (
                    <List
                      size='small'
                      bordered
                      className='customSearchList'
                      dataSource={[
                        ...(filteredPracticeOptions.length > 0
                          ? [...filteredPracticeOptions]
                          : []),
                        ...(filteredSubPracticeOptions.length > 0
                          ? [
                              {
                                label: `Sub Specialities`,
                                value: null,
                                type: 'heading'
                              },
                              ...filteredSubPracticeOptions
                            ]
                          : []),
                        ...(searchedTherapists.length > 0
                          ? [
                              {
                                label: `Providers`,
                                value: null,
                                type: 'heading'
                              },
                              ...searchedTherapists
                            ]
                          : []),
                        ...(searchedClinics.length > 0
                          ? [
                              {
                                label: `Clinics`,
                                value: null,
                                type: 'heading'
                              },
                              ...searchedClinics
                            ]
                          : [])
                      ]}
                      renderItem={item => (
                        <List.Item
                          style={{ cursor: 'pointer' }}
                          className={
                            item?.value
                              ? 'customSearchListItem'
                              : 'customSearchListItemHead'
                          }
                          onClick={() => {
                            setIsCategoriesOpen(false);
                            if (item?.value && item?.type === 'Speciality') {
                              setCategorySelected(item);
                              setTherapistName('');
                            } else if (
                              item?.value &&
                              item?.type === 'Sub Speciality'
                            ) {
                              setSubCatSelected(item);
                              setTherapistName('');
                            } else if (
                              item?.value &&
                              item?.type === 'Provider'
                            ) {
                              navigate(`/doctor-information/${item?.value}`);
                            } else if (item?.value && item?.type === 'Clinic') {
                              navigate(`/clinic-information/${item?.value}`);
                            }
                          }}
                        >
                          <div className='listNames'>
                            <div className='listImg'>
                              {item?.type !== 'heading' ? (
                                <img
                                  src={item?.image || SearchBarFieldIcon}
                                  alt='$$$'
                                />
                              ) : null}
                            </div>
                            <div>
                              <p>{item?.label}</p>
                              <p style={{ color: 'gray', fontSize: '10px' }}>
                                {item?.subHeading}
                              </p>
                            </div>
                          </div>
                        </List.Item>
                      )}
                    />
                  ) : null}
                </div>
                <AntdInputAutocomplete
                  name='providerLocationName'
                  placeholder='Location or postcode'
                  form={form}
                  greenIcon
                />
                <button
                  type='submit'
                  style={{ opacity: searchDisabled ? 0.6 : 1 }}
                  disabled={searchDisabled}
                  className='homeSearchButton'
                >
                  <img src={Arrow_Left} alt='' className='arrow' />
                </button>
              </div>
            </div>
          </Form>
        </div>
        <div className='popular_tabs'>
          {categories?.length ? <h3> Popular </h3> : null}
          <div className='popular_tabs_content'>
            {categories?.slice(0, 6)?.map((el, ind) => (
              <div className='highlighted_tab' key={ind}>
                <p
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    const category = {
                      label: el?.categoryName,
                      value: el._id
                    };
                    dispatch(
                      setFilterValues({
                        location: '',
                        lat: '',
                        lng: '',
                        name: '',
                        category: category || {},
                        subCategory: {}
                      })
                    );
                    navigate(`./search-therapist/?category=${el?._id}`);
                  }}
                >
                  {el.categoryName}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className='what_we_value'>
          <h1>
            Find. Connect. <br /> Thrive.
          </h1>
        </div>
      </div>
    </>
  );
};

export default HeroContent;
