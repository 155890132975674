import React, { useEffect } from 'react';
import './header.scss';
import { Logo } from '../../assets/vectors';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Dropdown } from 'antd';
import Sticky from 'react-stickynode';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../Redux/Reducers/authSlice';
import Button from '../UI/Button/Button';
import { DP, WarningSign } from '../../assets/images';
import Swal from 'sweetalert2';
import { setProfileData } from '../../Redux/Reducers/profileSlice';
import { getRequest } from '../../api/apiCalls';
import { useMediaQuery } from 'react-responsive';
import ButtonGreen from '../UI/Button/ButtonGreen';

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const token = useSelector(state => state.authReducer.token);
  const isMobile = useMediaQuery({ query: '(max-width: 580px)' });

  const profileImg = useSelector(
    state => state?.profile?.profileData?.profile_image
  );

  const handleLogout = () => {
    Swal.fire({
      title: 'Are you sure you want to log out?',
      iconHtml: `<img src="${WarningSign}" alt="Warning Sign" />`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Logout'
    }).then(result => {
      if (result.isConfirmed) {
        localStorage.removeItem('token');
        dispatch(logout());
        navigate('/login');
      }
    });
  };
  const getAllData = async () => {
    const onSuccess = res => {
      dispatch(setProfileData(res?.detail));
    };
    const onError = res => {
      console.log(res, 'erorr res');
      dispatch(setProfileData(null));
    };
    await getRequest(null, 'auth/me', true, true, onSuccess, onError);
  };
  const items = [
    {
      label: (
        <Link to='/account'>
          <p className='customDropdownButtons'>My account</p>
        </Link>
      ),
      key: '0'
    },
    {
      label: (
        <Link to='/consultations'>
          <p className='customDropdownButtons'>My consultations</p>
        </Link>
      ),
      key: '1'
    },
    {
      label: (
        <Link to='/notification'>
          <p className='customDropdownButtons'>Notifications</p>
        </Link>
      ),
      key: '2'
    },
    {
      label: (
        <p
          className='customDropdownButtons logout-button'
          onClick={handleLogout}
        >
          Logout
        </p>
      ),
      key: '3'
    }
  ];
  useEffect(() => {
    if (token) {
      getAllData();
    }
  }, []);

  return (
    <>
      <Sticky top={0} innerZ={100}>
        <div className='header'>
          <div className='logo' onClick={() => navigate('/')}>
            <img src={Logo} alt='' onClick={() => navigate('/')} />
          </div>
          <div className='header__left '>
            {token ? (
              <div className='profilePic'>
                <Dropdown
                  menu={{
                    items
                  }}
                  trigger={['click']}
                  className='dropdown customHeaderDrop'
                >
                  {profileImg ? (
                    <img
                      className='headerUserProfileImage'
                      src={profileImg}
                      alt=''
                    />
                  ) : (
                    <div className='headerProfileDummyImg'>
                      <DP />
                    </div>
                  )}
                </Dropdown>
              </div>
            ) : (
              <div className='profilePic'>
                {location.pathname !== '/join-service' ? (
                  <ButtonGreen
                    style={{
                      width: 'auto',
                      padding: '0 10px',
                      marginRight: isMobile ? '0' : '10px'
                    }}
                    onClick={() => {
                      navigate('/join-service');
                    }}
                    text={'Provider Sign up / Login'}
                  ></ButtonGreen>
                ) : null}
                <ButtonGreen
                  onClick={() => {
                    navigate('/login');
                  }}
                  text={'Patient Sign up / Login'}
                ></ButtonGreen>
              </div>
            )}
          </div>
        </div>
      </Sticky>
    </>
  );
};

export default Header;
