import React, { useEffect, useState } from 'react';
import { Form, Radio } from 'antd';
import Footer from '../footer/Footer';
import Input from '../UI/Input';
import Select from '../UI/Select';
import { useDispatch, useSelector } from 'react-redux';
import { postRequest } from '../../api/apiCalls';
import { useNavigate, useParams } from 'react-router-dom';
import { updateAppointmentField } from '../../Redux/Reducers/apponitmentSlice';
import ButtonGreen from '../UI/Button/ButtonGreen';

const PatientInfo = ({ activeStep, setActiveStep }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const profileData = useSelector(state => state.profile.profileData);
  const user_address = useSelector(state => state.appointment.user_address);
  const selected_slot = useSelector(state => state.appointment.selected_slot);
  const appointment_type = useSelector(
    state => state.appointment.appointment_type
  );
  const [isLoading, setisLoading] = useState(false);
  const [form] = Form.useForm();
  const onFinish = async values => {
    const patient = {
      fullName: values?.fullName,
      gender: values?.gender,
      age: values?.age,
      isGuardian: values?.relationship === 'guardian' ? true : false
    };
    dispatch(
      updateAppointmentField({
        field: 'patient_details',
        value: patient
      })
    );
    setActiveStep(activeStep + 1);
  };
  const calculateAge = val => {
    const birthdateDate = new Date(val);
    const currentDate = new Date();
    const ageInMilliseconds = currentDate - birthdateDate;
    const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
    const age = Math.floor(ageInYears);

    return age;
  };
  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    });
  }, []);
  useEffect(() => {
    if (profileData) {
      const emailValue = profileData?.userId?.email || '';
      const age = calculateAge(profileData?.dob);
      form.setFieldsValue({ email: emailValue });
      form.setFieldsValue({ fullName: profileData?.fullName });
      form.setFieldsValue({ age: age });
      form.setFieldsValue({ gender: profileData?.gender });
      form.setFieldsValue({ relationship: profileData?.relationship });
    }
  }, [profileData, form]);

  return (
    <>
      <div className='patientInfo'>
        <Form
          name='basic'
          layout='vertical'
          style={{ width: '100%' }}
          form={form}
          onFinish={onFinish}
          autoComplete='off'
        >
          <div className='input_groups'>
            <Form.Item
              name='fullName'
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Patient/Child’s Full Name is Required'
                }
              ]}
              label='Patient/Child’s Full Name'
            >
              <Input placeholder='Enter your Patient/Child’s Full Name.' />
            </Form.Item>
          </div>
          <div className='input_groups'>
            <Form.Item
              name='age'
              rules={[{ required: true, message: 'Age is Required' }]}
              label='Age'
            >
              <Input type='number' placeholder='Enter your age.' />
            </Form.Item>
          </div>
          <div className='input_groups'>
            <Form.Item
              name={'gender'}
              label='Gender'
              rules={[
                {
                  required: true,
                  message: 'Please select gender'
                }
              ]}
            >
              <Select
                placeholder='Choose gender'
                options={[
                  {
                    value: 'male',
                    label: 'Male'
                  },
                  {
                    value: 'female',
                    label: 'Female'
                  },
                  {
                    value: 'other',
                    label: 'Other'
                  }
                ]}
                required
              />
            </Form.Item>
          </div>
          <div className='input_groups'>
            <Form.Item
              name={'relationship'}
              label='Relationship to Patient'
              rules={[
                {
                  required: true,
                  message: 'Please select relationship to patient'
                }
              ]}
            >
              <Radio.Group className='three__fields' defaultValue={'parent'}>
                <div className='box_one'>
                  <Radio
                    value={'parent'}
                    className='customAppointmentRadio customBookingParent'
                  >
                    <div className='box_one_left'>
                      <p> Parent</p>
                    </div>
                  </Radio>
                </div>
                <div className='box_one'>
                  <Radio
                    value={'guardian'}
                    className='customAppointmentRadio customBookingParent'
                  >
                    <div className='box_one_left'>
                      <p> Guardian</p>
                    </div>
                  </Radio>
                </div>
              </Radio.Group>
            </Form.Item>
          </div>
          <div className='input_groups'>
            <Form.Item
              name='email'
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Email is required'
                },
                {
                  pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/,
                  message: 'Please enter a valid email!'
                }
              ]}
              label='Email address'
            >
              <Input placeholder='Email Address' type='email' disabled />
            </Form.Item>
          </div>
          <div className='timeSlotsBtn'>
            <ButtonGreen
              onClick={() => {
                setActiveStep(prev => prev - 1);
              }}
              text='Back'
              style={{ margin: '10px' }}
              disabled={isLoading}
            />
            <ButtonGreen
              text='Next'
              type='submit'
              style={{ margin: '10px' }}
              disabled={isLoading}
            />
          </div>
        </Form>
      </div>
      <Footer />
    </>
  );
};

export default PatientInfo;
