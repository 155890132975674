import { Carousel, Modal } from 'antd';
import moment from 'moment/moment';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateAppointmentField } from '../../Redux/Reducers/apponitmentSlice';
import { useNavigate } from 'react-router-dom';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import {
  ClinicCalender,
  DP,
  HomeCalender,
  VideoCalender
} from '../../assets/images';
import dayjs from 'dayjs';
import ButtonGreen from '../../components/UI/Button/ButtonGreen';
export const ScheduleCalender = ({ data, showItems, reformattedSchedules }) => {
  const token = useSelector(state => state.authReducer.token);
  const [appointmentModal, setAppointmentModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeSelectedSlot, setActiveSelectedSlot] = useState(null);
  const frequencyArray = {
    ONE_OFF: 'One-off',
    WEEKLY: 'Weekly',
    FORTNIGHTLY: 'Fortnightly',
    MONTHLY: 'Monthly'
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const carouselRef = useRef();
  const today = new Date();
  const days = [];
  for (let i = 0; i < 42; i++) {
    const date = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + i
    );
    const formatedDate = moment(date).format('YYYY-MM-DD');
    days.push(formatedDate);
  }

  const handleCardSlotClick = slot => {
    dispatch(
      updateAppointmentField({
        field: 'selected_slot',
        value: slot
      })
    );
    dispatch(
      updateAppointmentField({
        field: 'appointment_type',
        value: slot?.type
      })
    );
    dispatch(
      updateAppointmentField({
        field: 'selected_therapist',
        value: data?._id
      })
    );
    if (token) {
      navigate(`/make-appointment/${data?._id}`);
    } else {
      navigate('/login');
    }
  };

  const handleNextClick = () => {
    const newIndex = currentIndex + 7;
    carouselRef.current.goTo(newIndex, true);
  };

  const handlePrevClick = () => {
    const newIndex = currentIndex - 7;
    carouselRef.current.goTo(newIndex, true);
  };

  const handleAfterChange = index => {
    setCurrentIndex(index);
  };

  return (
    <>
      <LeftOutlined className='customCaroselIcons' onClick={handlePrevClick} />
      <Carousel
        slidesToShow={7}
        dots={false}
        ref={carouselRef}
        className='carousel_calender'
        infinite={false}
        afterChange={handleAfterChange}
      >
        {days?.map((sche, index) => {
          const foundElement = reformattedSchedules?.find(
            item => item.date === sche
          );
          const foundSlots = foundElement?.schedules?.filter(
            elem =>
              elem?.status === 'available' &&
              moment(elem.start).isAfter(moment())
          );
          const date = foundElement
            ? new Date(foundElement?.date)
            : new Date(sche);
          return (
            <>
              <div className='right__one'>
                <div className='dates__day'>
                  <p className='day'>
                    {date?.toLocaleDateString('en-US', {
                      weekday: 'short'
                    })}
                  </p>
                  <p>
                    {date?.toLocaleDateString('en-US', {
                      month: 'short'
                    })}
                    {date?.getDate()}
                  </p>
                </div>
                <div className='time__slots'>
                  {[...Array(showItems)].map((_, ind) => {
                    const item = foundSlots?.[ind];
                    if (item && !item.bookingId) {
                      return (
                        <div
                          onClick={() => {
                            setActiveSelectedSlot(item);
                            setAppointmentModal(true);
                          }}
                          style={{ fontSize: '10px' }}
                          className={
                            item?.type === 'clinic'
                              ? 'timeView timeViewClinic'
                              : item?.type === 'home'
                              ? 'timeView timeViewHome'
                              : 'timeView timeViewVideo'
                          }
                          key={ind}
                        >
                          {item?.type === 'clinic' ? (
                            <ClinicCalender className='calenderIcon' />
                          ) : item?.type === 'home' ? (
                            <HomeCalender className='calenderIcon' />
                          ) : (
                            <VideoCalender className='calenderIcon' />
                          )}
                          <p>{dayjs(item?.start).format('hh:mm A')}</p>
                        </div>
                      );
                    } else {
                      return (
                        <p className='emptySlots' key={ind}>
                          -
                        </p>
                      );
                    }
                  })}
                </div>
              </div>
            </>
          );
        })}
      </Carousel>
      <RightOutlined className='customCaroselIcons' onClick={handleNextClick} />
      <Modal
        centered
        open={appointmentModal}
        onCancel={() => {
          setAppointmentModal(false);
          setActiveSelectedSlot(null);
        }}
        closeIcon={true}
      >
        <div className='singleSlotModal'>
          <h1> Available slot </h1>
          <div className='detailWrap'>
            <div className='doctorDetailsWrap'>
              <div className='doctorDetail'>
                {data?.profileImg ? (
                  <img src={data?.profileImg} alt='' />
                ) : (
                  <DP />
                )}
                <div className='detailWrapInner'>
                  <p className='name'>{data?.name}</p>
                  <p className='appointmentType'>
                    {activeSelectedSlot?.type === 'home'
                      ? 'Mobile'
                      : activeSelectedSlot?.type}{' '}
                    appointment
                  </p>
                </div>
              </div>
              <div className='feeDetail'>
                ${activeSelectedSlot?.fee || ' --'} <span>fee</span>
              </div>
            </div>
          </div>
          <div className='slotDetailWrapper'>
            <div className='detailWrap'>
              <div className='slotDetail'>
                <h5>
                  {dayjs(activeSelectedSlot?.start)?.format('MMM DD, YYYY')}
                </h5>
                <p>Available on</p>
              </div>
            </div>
            <div className='detailWrap'>
              <div className='slotDetail'>
                <h5> {dayjs(activeSelectedSlot?.start)?.format('h:mm A')}</h5>
                <h5 style={{ lineHeight: '9px' }}>-</h5>
                <h5> {dayjs(activeSelectedSlot?.end)?.format('h:mm A')}</h5>
                <p>
                  {(() => {
                    const start = dayjs(activeSelectedSlot?.start);
                    const end = dayjs(activeSelectedSlot?.end);
                    const diffMinutes = end.diff(start, 'minutes');
                    const hours = Math.floor(diffMinutes / 60);
                    const minutes = diffMinutes % 60;

                    if (hours > 0) {
                      return `${hours} hour${hours > 1 ? 's' : ''}${
                        minutes && minutes > 0
                          ? ` and ${minutes} minute${minutes > 1 ? 's' : ''}`
                          : ''
                      } slot time`;
                    } else {
                      return `${minutes} minute${
                        minutes > 1 ? 's' : ''
                      } slot time`;
                    }
                  })()}
                </p>
              </div>
            </div>
          </div>
          <div className='detailWrap'>
            <div className='frequencyWrap'>
              <p className='frequecyHead'>Frequency</p>
              <p className='frequecyType'>
                {frequencyArray[activeSelectedSlot?.scheduleFrequency]}
              </p>
            </div>
          </div>
          <div className='detailWrap'>
            <div className='appointmentNoteWrap'>
              <p className='appointmentNoteHead'>Appointment Note</p>
              <p className='appointmentNoteType'>
                {activeSelectedSlot?.scheduleNote}
              </p>
            </div>
          </div>
          <div className='makeAppointmentWrap'>
            <ButtonGreen
              text={'Reserve an appointment'}
              onClick={() => {
                handleCardSlotClick(activeSelectedSlot);
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
