import React, { useState } from 'react';
import { Form } from 'antd';
import Checkbox from '../../components/UI/Checkbox';
import { setEmail, updateAuthField } from '../../Redux/Reducers/authSlice';
import { useDispatch } from 'react-redux';
import { postRequest } from '../../api/apiCalls';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import ButtonGreen from '../../components/UI/Button/ButtonGreen';

export const Disclaimer = ({ data, setModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [isChecked, setisChecked] = useState(false);
  const onFinish = async values => {
    setisLoading(true);
    const onSuccess = res => {
      setisLoading(false);
      dispatch(setEmail(data.email));
      dispatch(updateAuthField({ field: 'isProfileFilled', value: false }));
      navigate('/signup/verify');
    };
    const onError = () => {
      setisLoading(false);
      setModal(false);
    };
    const dataToSend = {
      fullName: data?.fullName,
      dob: dayjs(data?.dob),
      password: data?.password,
      email: data?.email,
      relationship: data?.relationship,
      relationName: data?.relationName,
      gender: data?.gender
    };
    await postRequest(
      dataToSend,
      'customer/signup',
      true,
      true,
      onSuccess,
      onError
    );
  };
  return (
    <>
      <div className='signup-main-container'>
        <div className='login-main-content customModalDisclaimer'>
          <h3>Disclaimer</h3>
          <p
            style={{ fontSize: '13px', lineHeight: '21px', marginTop: '30px' }}
          >
            I (Legal representative of the applicant and as the representative
            parental guardian if the applicant is a minor and more than one
            guardian exists) hereby give consent to receive medical care at
            Therapy Match. I also authorize physicians, hospitals, and Therapy
            Match to release medical information and medical records to other
            parties when deemed necessary. I consent to receive treatment with
            imported medicine and dosage variations. I consent for medical
            photography and the use of the material to be used in teaching,
            education, research, and publication. I have read and I understand
            Therapy Match financial policies and I accept responsibility for the
            payment of any fees associated with my care. I consent to
            communicate via internet and I will not hold the Therapy Match
            responsible for unforseen incidents that may happen during the
            communications. I herebyconfirm that I have fully understood the
            risks and benfits of all of the above. My signature also authorizes
            the release of medical information necessary to pay my claim. Any
            dispute between arising out of this medical encounter set forth
            above, the Tokyo District Court of the Tokyo Summary Court shall
            have the exclusive original jurisdiction. A photocopy or scanned
            document of this form shall be considered as effective and valid as
            the original. I hereby certify that the information above is
            complete and true to the best of my knowledge.
          </p>
          {/* <div className='loginImageSide'>
            <LoginSideImage />
          </div> */}

          <Form
            name='basic'
            style={{ width: '100%' }}
            onFinish={onFinish}
            autoComplete='off'
            layout='vertical'
          >
            <Form.Item name={`terms`}>
              <Checkbox
                onChange={e => {
                  setisChecked(e.target.checked);
                }}
              >
                I’ve read and agree with the disclaimer.
              </Checkbox>
            </Form.Item>
            <div className='btn-center-single login-button'>
              <ButtonGreen
                text={'Submit'}
                type='submit'
                disabled={!isChecked || isLoading}
              />
            </div>
          </Form>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};
